import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

let { create } = defaultResourceSchema

const masterCommandCentersResource = resourceBuilder.build('/users/me/master_command_centers', {
  create,
})

export default masterCommandCentersResource
