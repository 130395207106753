import React, { useState, useEffect } from 'react'
import ContentHeader from 'components/general/content_header'
import jobsResource from 'resources/jobs'
import * as routes from 'constants/routes'
import { SvgBackArrow } from 'components/general/icons'
import classnames from 'classnames'
import styles from '../create/styles.module.css'
import useAttribute from 'utils/useAttribute'
import { toast } from 'react-toastify';
import Button from 'components/general/button'
import * as ExpertInviteStep from '../create/expert_invite_step'
import ideaStyles from '../idea/styles.module.css'
import Config from 'utils/config'
import autoTypeCast from 'auto-type-cast'
import BudgetModal from '../create/budget_modal'

export default function InviteMore ({ job, refreshJob, history, match, location }) {
  const [inviteModal, setInviteModal] = useState(false)
  const handleClose = () => setInviteModal(false)
  const [expertBudgetsById, setExpertBudgetsById] = useState({})
  const [expertBudgetsByEmail, setExpertBudgetsByEmail] = useState({})

  const [saving, setSaving] = useState(false)
  const navigateToJob = (job) => {
    if(Config.jobBuilderV3) {
      history.push({
        pathname: routes.JOB_BUILDER_STEP_3_BRIEFING,
        state: {
          job: job,
        }
      })
    } else {
      history.push(routes.JOB(job.slug))
    }
  }
  const [_, forceRender] = useState(undefined)
  const [invitedTeam, setInvitedTeam] = useState([])
  const [needsFreelancerBudgets, _setNeedsFreelancerBudgets] = useState(false)
  const setNeedsFreelancerBudgets = (value) => {
    // do not allow setting budget if user chose Option #1 previously
    if(job.per_expert_budget_in_cents === 0) {
      _setNeedsFreelancerBudgets(value)
    }
  }

  const attributes = {}
  attributes.myTeamIds = useAttribute('expert_ids', job.experts.map(e => e.id), forceRender)
  attributes.myOwnFreelancerEmails = useAttribute('invite_freelancer_emails', [], forceRender, {
    focus: (value) => value.join(', '),
    blur: (rawValue) => rawValue.split(/\s*,\s*/).filter((email) => email)
  })
  attributes.myOwnEmployeeEmails = useAttribute('invite_employee_emails', [], forceRender, {
    focus: (value) => value.join(', '),
    blur: (rawValue) => rawValue.split(/\s*,\s*/).filter((email) => email)
  })

  useEffect(() => {
    if(job) autoTypeCast(job)
  }, [job])

  const submit = async () => {
    const jobData = Object.values(attributes).reduce((buffer, attribute) => Object.assign(buffer, {[attribute.label]: attribute.value}), {})
    if(needsFreelancerBudgets) {
      jobData.expert_budgets_by_id = {}
      Object.keys(expertBudgetsById).forEach((expertId) => {
        jobData.expert_budgets_by_id[expertId] = parseInt(expertBudgetsById[expertId].replace(/[^\d]/g,'')) * 100
      })
      jobData.expert_budgets_by_email = {}
      Object.keys(expertBudgetsByEmail).forEach((email) => {
        jobData.expert_budgets_by_email[email] = parseInt(expertBudgetsByEmail[email].replace(/[^\d]/g,'')) * 100
      })
    }
    try {
      setSaving(true)
      const response = await jobsResource.inviteMore({ data: { job: jobData }, params: { id:  job.id }})
      toast.success("Congrats! Your invitations are on their way to your Freelancers.")
      if(refreshJob) refreshJob()
      navigateToJob(response.data)
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to invite more Freelancers. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  const handleSubmitButton = () => {
    if (!needsFreelancerBudgets) {
      submit()
    } else {
      setInviteModal(true)
    }
  }

  const isSetBudgetAllowed = ((invitedTeam?.length || 0) + ExpertInviteStep.getValidEmails(attributes.myOwnEmployeeEmails).length + ExpertInviteStep.getValidEmails(attributes.myOwnFreelancerEmails).length) > 0

  return (
    <>
      {!Config.jobBuilderV3 && <div className={ideaStyles.back} onClick={() => navigateToJob(job)}>
        <SvgBackArrow /> <span>Back to {job.title}</span>
      </div>}
      <ContentHeader text="Invite More Freelancers" noBorder={Config.jobBuilderV3}>
      </ContentHeader>

      <ExpertInviteStep.component
        attributes={attributes}
        setInvitedTeam={setInvitedTeam}
        setNeedsFreelancerBudgets={setNeedsFreelancerBudgets}
        suppressHeader={true}
        suppressBudget={true}
        alreadyInvitedExperts={job.experts}
      />

      <div className={classnames(styles.buttons, styles.column)}>
        <p><small>Note that these newly invited Freelancers will be proposed the same financial amount as the others.</small></p>
        <Button type="primary" disabled={saving || !isSetBudgetAllowed} onClick={handleSubmitButton}>
          {needsFreelancerBudgets ? 'Set Budget' : 'Invite Team'}
        </Button>
      </div>
      {inviteModal &&
      <>
        <BudgetModal
          show={inviteModal}
          onClose={handleClose}
          invitedTeam={invitedTeam}
          attributes={attributes}
          expertBudgetsById={expertBudgetsById}
          expertBudgetsByEmail={expertBudgetsByEmail}
          onSubmitIndividual={submit}
        />
      </>
      }
    </>
  )
}
