import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Store from 'store';
import { Container, Col } from 'react-bootstrap';

import * as routes from 'constants/routes';
import { HistoryProvider } from 'components/app/history';
import { pathAfterSignIn } from 'utils/local_storage';
import CustomerDashboard from './CustomerDashboard';
import ExpertDashboard from './ExpertDashboard';

export default function Dashboard(props) {
  const { dispatch, state } = useContext(Store);

  if (state && HistoryProvider.history && (!state.currentUser || !state.currentUser.id)) {
    pathAfterSignIn.set();
    HistoryProvider.history.push({ pathname: routes.SIGN_IN });
  }

  return (
    <Container>
      <Col className="px-0">
        <Helmet>
          <title>Dashboard | Ideasicle X</title>
        </Helmet>
        {state.currentUser.role === 'customer' && <CustomerDashboard {...props} />}
        {state.currentUser.role === 'expert' && <ExpertDashboard {...props} />}
        {!state.currentUser.role && (
          <>
            <h2>Your account is inactive</h2>
            <p>If you believe this to be in error, please contact your company administrator or Ideasicle X support.</p>
          </>
        )}
      </Col>
    </Container>
  );
}
