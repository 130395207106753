import React, {useState} from 'react'
import { SvgReturnToTopIcon } from '../../general//icons';

import styles from './styles.module.css';
import classnames from 'classnames'

export default function ReturnToTop (props) {

  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 600){
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 600){
      setShowScroll(false);
    }
  };
  window.addEventListener('scroll', checkScrollTop)

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const cnames = classnames({
    [styles.returnToTop]: true,
    [styles.showBtn]: showScroll,
    [styles.hideBtn]: !showScroll,
  })

  return (
    <SvgReturnToTopIcon
      className={cnames}
      onClick={scrollTop}
    />
  )
}
