const BASE_URL = "https://connect.stripe.com/express/oauth/authorize"

export function stripeConnectUrl(formData, purpose) {
  const url = new URL(BASE_URL)
  const queryString = new URLSearchParams({
    response_type: "code",
    client_id: window.stripe_connect_client_id,
    scope: "read_write",
    state: JSON.stringify({
      "purpose": purpose,
      "email": formData.email,
      "csrf-token": document.querySelector('meta[name="csrf-token"]').content,
    }),
    redirect_uri: `${window.location.origin}/api/v1/stripe_connect`,
    "suggested_capabilities[]": 'transfers'
  });
  ['first_name', 'last_name', 'email'].forEach((attr) => {
    if(formData[attr]) {
      queryString.append(`stripe_user[${attr}]`, formData[attr])
    }
  })
  if(formData.linkedinUrl) {
    queryString.append(`stripe_user[url]`, formData.linkedinUrl)
  } else {
    queryString.append(`stripe_user[product_description]`, "Expert creative and idea generation services")
  }
  url.search = queryString.toString()
  return url
}
