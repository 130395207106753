import React, { useContext } from 'react';
import Store from 'store';
import * as routes from 'constants/routes'
import { toast } from 'react-toastify'
import useAuthenticationRequired from './useAuthenticationRequired'

const useRoleRequired = (role) => {
  useAuthenticationRequired()

  const { state } = useContext(Store)
  const { currentUser } = state

  if(currentUser.role != role) {
    window.location = "https://www.ideasiclex.com/"
  }
}

export default useRoleRequired
