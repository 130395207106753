import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const organizationsResource = resourceBuilder.build('/organizations', {
  showCurrent: {
    method: 'get',
    url: '/me'
  },
  addUsers: {
    method: 'post',
    url: '/add_users'
  },
  removeUser: {
    method: 'delete',
    url: '/remove_user/{id}'
  },
  updatePaymentMethod: {
    method: 'put',
    url: '/me/update_payment_method'
  },
  idp: { url: '/{idp}/idp' }
})

export default organizationsResource
