import React, { memo, useRef } from 'react'
import styles from './styles.module.css'
import classnames from 'classnames'

const btnClassnames = (classname) => classnames(styles.button, styles[classname])

const ButtonLink = function ({ children, to, style = {}, ...props }) {
  const link = useRef(null)

  const handleClick = () => {
    window.location = link.current.href
  }

  return (
    <button style={style} onClick={handleClick} {...props} className={btnClassnames(props.className)}>
      <a href={to} ref={link} style={style}>{children}</a>
    </button>
  )
}

const SubmitButton = ({ children, style = {}, ...props }) => {
  return <button style={style} {...props} className={btnClassnames(props.className)} >{children}</button>
}

const Button = (props) => (
  props.to ? <ButtonLink {...props} /> : <SubmitButton {...props} />
)

export default memo(Button)
