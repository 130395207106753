import React, { useState, useContext, useEffect, useRef } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import ideasResource from 'resources/ideas'
import * as routes from 'constants/routes'
import styles from './styles.module.css'
import { SvgBackArrow } from 'components/general/icons'
import classnames from 'classnames'
import jobCreateStyles from '../create/styles.module.css'
import useAttribute from 'utils/useAttribute'
import Editor from '../../editor/index.jsx'
import { toast } from 'react-toastify';


export default function CreateIdea ({ job, refreshJob, history, match, location }) {
  const [_, forceRender] = useState()
  const [saving, setSaving] = useState(false)
  const [editorInteracting, setEditorInteracting] = useState(false)
  const editorRef = useRef()
  const attributes = {}
  attributes.title = useAttribute('title', '', forceRender)

  const navigateToJob = () => history.push(routes.JOB(job.slug))

  const submit = async () => {
    if(validate()) {
      try {
        setSaving(true)
        const editorHtmlOutput = editorRef.current.getHtml()
        const editorState = editorRef.current.getSerializedEditorState()
        const response = await ideasResource.create({ data: {
          idea: {
            title: attributes.title.value,
            content: editorHtmlOutput,
            editor_state: editorState,
          },
          job_id: job.id,
        }})
        refreshJob()
        navigateToJob()
      } catch (error) {
        console.error(error)
        toast.warn("Uh oh! We weren't able to post your idea. Please try again shortly")
      } finally {
        setSaving(false)
      }
    }
  }

  const validate = () => {
    let valid = true
    const { title } = attributes
    if (!title.value) title.error.set("Job title cannot be empty.")

    return valid
  }

  return (
    <>
      <div className={styles.back} onClick={navigateToJob}>
        <SvgBackArrow /> <span>Back to {job.title}</span>
      </div>
      <ContentHeader text={job.title} noBorder={true}>
      </ContentHeader>
      <Row>
        <Col lg={{span: 6, order: 1}} md={{span: 9, order: 1}} sm={{span: 12, order: 2}} xs={{span: 12, order: 2}}>
            <input
                id="idea_attributes."
                className={classnames([jobCreateStyles.hero_input])}
                placeholder="Give your idea a title..."
                value={attributes.title.value}
                tabIndex={1}
                {...attributes.title.eventHandlers}
                />
        </Col>
        <Col lg={{span: 6, order: 2}} md={{ span: 3, order: 2}} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }} style={{ textAlign: 'right'}}>
          <button className="btn btn-primary btn-slim idea-save" type="button" disabled={saving || job.status === "closed" || editorInteracting} onClick={submit}>
            Post Idea
          </button>
        </Col>
      </Row>

      <Editor ref={editorRef} onInteracting={setEditorInteracting} />
      <button style={{marginTop: 44, marginBottom: 100}} className="btn btn-primary btn-slim" type="button" disabled={saving || job.status === "closed" || editorInteracting} onClick={submit}>
        Post Idea
      </button>
    </>
  )
}
