import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Loading from 'components/general/loading'
import moment from 'moment'
import { SvgChevronRightIcon } from 'components/general/icons'

const Notification = ({ data }) => {
  const date = moment(data.created_at)
  const includeYear = date.year() !== moment().year()
  return (
    <a href={data.url} style={{textDecoration: 'none', color: 'black'}}>
      {data.url && <SvgChevronRightIcon style={{float: 'right'}}/>}
      <div style={{fontSize: 11, opacity: 0.6, fontStyle: 'italic'}}>
        {includeYear ? date.format('MMM Mo , YYYY') : date.format('MMM Mo')}
      </div>
      <div style={{fontSize: 12, fontWeight: 'bold', paddingBottom: 5, borderBottom: '1px solid #979797', marginBottom: 15}}>
        {data.title}
      </div>
    </a>
  )
}
const NoNotifications = () => (
  <p style={{fontStyle: 'italic'}}>
    You have no unread notifications.
  </p>
)

const NotificationList = ({ notifications }) => (
  <div style={{overflowY: 'scroll'}}>
    {notifications.map((notification) => <Notification key={notification.id} data={notification} />)}
  </div>
)

export default ({ notifications, loading }) => (
  <div style={{width: '100%', marginBottom: 20, height: 210, paddingTop: 17, paddingLeft: 20, paddingRight: 20, border: '1px solid black', display: 'flex', flexDirection: 'column'}}>
    <div style={{fontSize: 22, fontWeight: 'bold', paddingBottom: 2, borderBottom: '2px solid black', marginBottom: 12}}>
      Notifications {!loading && notifications && <>({notifications.length})</>}
    </div>
    { loading && <Loading /> }
    { !loading && notifications && <>{notifications.length ? <NotificationList notifications={notifications} /> : <NoNotifications />} </>}
  </div>
)
