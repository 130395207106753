/* An important thing to keep in mind is that we don’t want the cursor
  to lose focus on the text editor when selecting different font sizes.
  As explained in my previous article, we can keep this from happening
  by using an onMouseDown event and calling preventDefault in its handler.

  For this reason, we cannot use a select element for the dropdown because
  preventDefault will prevent the menu from opening. Instead we’ll have to
  create a custom dropdown menu.

  https://levelup.gitconnected.com/do-you-even-draft-pumping-up-font-sizes-in-draft-js-9582c421bc55

  ------------------------------------------------------------------------

  Also https://github.com/facebook/draft-js/issues/912

  ------------------------------------------------------------------------

  The core problem is that controls like these often want to control focus
  themselves, leading one to enter into a tug-of-war for focus between formatting
  inputs (i.e. a font size dropdown) and the Draft.js text editor itself.

  To circumvent this problem, our team decided to replace React-Select within
  the text styles toolbar with a custom select component which operates wholly
  via mouse events. This way our team was able to prevent a loss of focus within
  the editor, eliminating the need to restore focus in the editor after a style
  selection is made.

  This simplification allowed us to remove several redundant functions necessary
  for managing inline style overrides due to focus bouncing around.

  https://sendgrid.com/blog/how-we-use-draft-js-at-sendgrid/
*/

import React, { useState } from 'react'
import { styles } from './customStyles'
import css from './styles.module.css'
import { SvgFontSizePicker } from '../general/icons'
import { Dropdown } from 'react-bootstrap';
import onClickOutside from "react-onclickoutside";

export const fontSizes = [12, 14, 16, 18, 20, 24, 28, 32, 38, 46, 54, 62]

const FontSizeSelect = ({ editorState, setEditorState, onSetShowingMenu }) => {
  // Declare state for conditional rendering of font size menu.
  const [ isShowingFontSizeMenu, _setIsShowingFontSizeMenu ] = useState(false)

  const setIsShowingFontSizeMenu = (value) => {
    _setIsShowingFontSizeMenu(value)
    if(onSetShowingMenu) onSetShowingMenu(value)
  }


  FontSizeSelect.handleClickOutside = () => setIsShowingFontSizeMenu(false)

  const setFontSize = (e, value) => {
    // Keep cursor focus inside Editor.
    e.preventDefault()

    // Remove current font size at selection.
    const newEditorState = styles.fontSize.remove(editorState)

    // Set editorState to display new font size.
    setEditorState(styles.fontSize.add(newEditorState, value))

    // Close dropdown menu.
    setIsShowingFontSizeMenu(false)
  }

  // Map array of integers to display options for dropdown.
  const fontSizeOptions = fontSizes.map(fontSize => (
    <div
      key={`font-size-${fontSize}`}
      className={css.fontSizeOption}
      // Declare event for setting font size.
      onMouseDown={e => setFontSize(e, `${fontSize}px`)}
    >{fontSize}</div>
  ))

  // Show dropdown menu when button is pressed,
  // Keeping cursor focused inside Editor.
  const onMouseDown = (event) => {
    event.preventDefault()
    setIsShowingFontSizeMenu(!isShowingFontSizeMenu)
  }

  const showFontSizeMenuIfActive = () => {
    if (isShowingFontSizeMenu) {
      return (
        <div className={css.fontSizeMenu}>
          {fontSizeOptions}
        </div>
      )
    }
  }

  return (
    <div className={css.fontSizeDropdown}>
      <button onMouseDown={onMouseDown} className={css.menuButton}>
        <SvgFontSizePicker />
      </button>

      {showFontSizeMenuIfActive()}
    </div>
  )
}

export default onClickOutside(FontSizeSelect, { handleClickOutside: () => FontSizeSelect.handleClickOutside })
