import React, { useState, useCallback, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'components/general/button'
import organizationsResource from 'resources/organizations'
import { toast } from 'react-toastify'

function ArchiveCommandCenterModal({ organization, user, onCompleted, show, onClose, setIsSubmitting}) {
  const [submitting, _setSubmitting] = useState(false)
  const setSubmitting = (value) => {
    _setSubmitting(value)
    setIsSubmitting(value)
  }

  const removeTeamMember = useCallback(async () => {
    setSubmitting(true)
    try {
      const response = await organizationsResource.removeUser({ params: { id: user.id }})
      onCompleted({ organization: response.data.organization })
      toast.success(`${user.fullName}'s Command Center has been archived.`)
    } catch (e) {
      toast.warn("Uh oh! We weren't able to archive that Command Center. Please try again soon.")
    } finally {
      onClose()
      setSubmitting(false)
    }
  }, [organization, user, onCompleted, onClose])

  if(!user || !organization) {
    return null
  }

  return (
    <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={onClose}
    >
        <Modal.Header closeButton >
        <Modal.Title>Archive Command Center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h2>Are you sure you'd like to remove {user.fullName}?</h2>
            <p>If you remove {user.fullName} from {organization.name}, their Command Center will be revoked. All of their projects will be moved to your Command Center.</p>
        </Modal.Body>
        <Modal.Footer>
        <Button disabled={submitting}  onClick={onClose}>
            Cancel
        </Button>
        <Button disabled={submitting} className="danger" onClick={removeTeamMember}>
            Remove
        </Button>
        </Modal.Footer>
    </Modal>
  )
}

export const useArchiveCommandCenterModal = ({
  organization,
  setIsSubmitting,
  onCompleted = ({ organization }) => {},
}) => {
  const [user, setUser] = useState()
  const [showModal, setShowModal] = useState(false)

  const closeModal = () => setShowModal(false)
  const openModal = (user) => {
    setUser(user)
    setShowModal(true)
  }

  const ModalComponent = useCallback(() => {
    return <ArchiveCommandCenterModal
      show={showModal}
      onClose={closeModal}
      onCompleted={onCompleted}
      user={user}
      organization={organization}
      setIsSubmitting={setIsSubmitting}
    />
  }, [showModal])

  return { openModal, ModalComponent }
}

export default ArchiveCommandCenterModal
