import React from 'react';
import { Modal } from 'react-bootstrap';

import styles from './styles.module.css'

const SetCNAMEModal = ({ onClose, domain, cname }) => {
  return (
    <Modal backdrop="static" keyboard={false} show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>DNS Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>Your custom domain: {domain}, has been created.</h2>
        <p>
          To finish connecting {domain} to Ideasicle X, supply this to your IT team or DNS provider as the destination
          of a CNAME record.
          <table className={styles.cNameTable}>
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Record Type</th>
                <th style={{ width: '25%' }}>Domain Name</th>
                <th>DNS Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }}>CNAME</td>
                <td className='px-1'>{domain}</td>
                <td className='px-1'>{cname}</td>
              </tr>
            </tbody>
          </table>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetCNAMEModal;
