import React, {} from 'react'

export default function Loading (props) {
  const style = props.style || { margin: 20 }
  style.textAlign = 'center'
  const cssClass = props.className || 'spinner-lg'
  return (
    <>
      <div style={style}>
        <div className={`spin-grow ${cssClass}`}></div>
        {props.children && <div style={{ marginTop: 10 }}>{props.children}</div>}
      </div>
    </>
  )
}
