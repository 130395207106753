import BaseModel from './base'
import pluralize from 'pluralize'
import { createSubscriptionHandlingFees } from './fees'

export default class User extends BaseModel {
  get fullName() {
    const fullName = [this.first_name, this.last_name].join(' ')
    return fullName.length > 1 ? fullName : this.email
  }

  newJobPrivilege(existingJobCount) {
    if(this.role !== 'customer') {
      return { allowed: false }
    }

    const allowedProjectCount = this.subscription_metadata?.NUMBER_OF_PROJECTS && parseInt(this.subscription_metadata?.NUMBER_OF_PROJECTS)

    if(this.subscription_metadata?.NUMBER_OF_PROJECTS && allowedProjectCount <= existingJobCount) {
      if(this.subscription_metadata?.isTrial()) {
        return {
          allowed: false,
          reason: `With a Trial you are allowed ${allowedProjectCount} ${pluralize('project', allowedProjectCount)} over a 30 day period. Subscribe from your Account Status page for unlimited idea projects.`
        }
      }

      return {
        allowed: false,
        reason: `You are allowed ${allowedProjectCount} ${pluralize('project', allowedProjectCount)}.  Subscribe from your Account Status page for unlimited idea projects.`
      }
    }

    // todo: subscription expired

    return { allowed: true }
  }

  billingFeaturesAllowed() {
    return this.role == 'customer' && !this.subscription_metadata?.isEnterpriseBilling();
  }


  additionalFeePct() {
    if(this.subscription_metadata) {
      return this.subscription_metadata.additionalFeePct()
    }

    return createSubscriptionHandlingFees()
  }
}
