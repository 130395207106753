const SubscriptionMetadata = {
  isTrial() {
    // Use this flag as a proxy to indicate trial
    // We may need a real flag in subscription metadata later
    return this.CANCEL_SUBSCRIPTION_AFTER_PERIOD?.toLowerCase() === 'true'
  }
}


export default SubscriptionMetadata
