import React, { useMemo, useContext } from 'react'
import styles from './tiles.module.css'
import classnames from 'classnames'
import * as routes from 'constants/routes'
import { Container, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap'
import Store from 'store'

export default function JobTiles ({jobs, allJobs, history}) {
  const metricsNumber = (count) => {
    const classList = [styles.metricNumber]
    if(count > 9) {
      // account for larger widrth
      classList.push(styles.mini)
    }
    if(count > 99) {
      count = 99
    }
    // console.log(classList, count)
    return <div className={classnames(classList)}>{count}</div>
  }

  const { dispatch, state } = useContext(Store)

  const newJobPrivilege = useMemo(() =>
    state.currentUser && allJobs && state.currentUser.newJobPrivilege(allJobs.length)
  , [state.currentUser, allJobs])

  return (
    <Row>
      {!jobs.length && <Col lg={3} md={4} xs={6}>
        <div className={classnames(styles.tile, styles.empty_tile)}>
          <span></span>
          Let's get started!
          { !(newJobPrivilege?.allowed) && <OverlayTrigger trigger={['hover','focus']} overlay={<Popover>
              <Popover.Content>{newJobPrivilege?.reason}</Popover.Content>
            </Popover>}
          >
            <button className="btn btn-primary btn-slim disabled" type="button" onClick={(e) => e.preventDefault()}>
              Start New Job
            </button>
          </OverlayTrigger>}
          {newJobPrivilege?.allowed && <button className="btn btn-primary btn-slim" type="button" onClick={() => history.push(routes.CREATE_JOB)}>
            Start New Job
          </button>}
        </div>
      </Col>}
      {jobs.length > 0 && jobs.map((job) => {
        return (<Col key={job.id} lg={3} md={4} sm={6} xs={12}>
          <div key={job.id} className={classnames(styles.tile)} style={{cursor: 'pointer'}} onClick={() => history.push(routes.JOB(job.slug))}>
            <div className={styles.job_header}>{job.title}</div>
            <div className={styles.job_mission}>{job.mission}</div>
            <div className={styles.footer}>
              { job.status === 'active' && <div className={styles.metric}>
                {metricsNumber(job.idea_count)}
                <div className={styles.metricDescription}>Ideas</div>
              </div>}
              { job.status === 'active' && <div className={styles.metric}>
                {metricsNumber(job.experts_active)}
                <div className={styles.metricDescription}>Freelancers<br />Active</div>
              </div>}
              { job.status === 'created' && <div className={styles.metric}>
                {metricsNumber(job.experts_invited)}
                <div className={styles.metricDescription}>Freelancers<br />Invited</div>
              </div>}
              { job.status === 'created' && <div className={styles.metric}>
                {metricsNumber(job.experts_accepted)}
                <div className={styles.metricDescription}>Freelancers<br />Accepted</div>
              </div>}
            </div>
          </div>
        </Col>)
      })}
    </Row>
  )
}
