import React from 'react'
import { css } from 'glamor'
import ReactSVG from 'react-svg'

import avatar_empty from './avatar_empty'
import ic_arrow_drop_down from './ic_arrow_drop_down'
import ic_chevron_right from './ic_chevron_right'
import ic_close from './ic_close'
import ic_control_point from './ic_control_point'
import ic_control_point_2 from './ic_control_point_2'
import ic_info from './ic_info'
import ic_menu from './ic_menu'
import ic_warning from './ic_warning'
import ic_add_large_gray from './ic_add_large_gray'
import comment from './comment'
import flagged from './flagged-active'
import flag from './flag'
import back_arrow from './back_arrow'
import pencil from './pencil'
import time_bar_indicator from './time_bar_indicator'
import message from './message'
import video from './video'
import pdf from './pdf'
import confetti from './confetti'
import bank from './bank'
import font_size_picker from './font_size_picker'
import font_bkg_color_picker from './font_bkg_color_picker'
import color_grabber from './color_grabber'
import youtube from './youtube'
import book from './book'
import image from './image'
import star_filled from './star_filled'
import star_empty from './star_empty'
import star_half from './star_half'
import return_to_top from './return_to_top';
import trash from './trash';

const appendStyle = (svg, value) => {
  const styleAttr = svg.getAttribute('style') || ''
  svg.setAttribute('style', styleAttr.length ? styleAttr + '; ' + value : value)
}

const SvgWrapper = (props) => {
  return <ReactSVG {...props}
    beforeInjection={svg => {
      if(props.style && props.style.width) {
        appendStyle(svg, 'width: ' + props.style.width + 'px')
      }
      if(props.style && props.style.height) {
        appendStyle(svg, 'height: ' + props.style.height + 'px')
      }
    }} />
}

export const SvgEmptyAvatarIcon = (props) => {
  return (
    <SvgWrapper src={avatar_empty} {...props} />
  )
}

export const SvgBackArrow = (props) => {
  return (
    <SvgWrapper src={back_arrow} {...props} />
  )
}

export const SvgBankIcon = (props) => {
  return (
    <SvgWrapper src={bank} {...props} />
  )
}
export const SvgBookIcon = (props) => {
  return (
    <SvgWrapper src={book} {...props} />
  )
}

export const SvgChevronRightIcon = (props) => {
  return (
    <SvgWrapper src={ic_chevron_right} {...props} />
  )
}

export const SvgColorGrabberIcon = (props) => {
  return (
    <SvgWrapper src={color_grabber} {...props} />
  )
}

export const SvgCommentIcon = (props) => {
  return (
    <SvgWrapper src={comment} {...props} />
  )
}

export const SvgConfettiIcon = (props) => {
  return (
    <SvgWrapper src={confetti} {...props} />
  )
}

export const SvgFlagIcon = (props) => {
  return (
    <SvgWrapper src={flag} {...props} />
  )
}

export const SvgFlagActiveIcon = (props) => {
  return (
    <SvgWrapper src={flagged} {...props} />
  )
}

export const SvgFontSizePicker = (props) => {
  return (
    <SvgWrapper src={font_size_picker} {...props} />
  )
}

export const SvgFontBackgrounColorPicker = (props) => {
  return (
    <SvgWrapper src={font_bkg_color_picker} {...props} />
  )
}

export const SvgImageIcon = (props) => {
  return (
    <SvgWrapper src={image} {...props} />
  )
}
export const SvgInfoIcon = (props) => {
  return (
    <SvgWrapper src={ic_info} {...props} />
  )
}

export const SvgMenuIcon = ({ mode }) => {
  const style = css({ ' use#Black': {
      fill: mode === 'light' ? 'black' : 'white'
    }
  })
  return (
    <SvgWrapper src={ic_menu} {...style}/>
  )
}

export const SvgMessageIcon = (props) => {
  return (
    <SvgWrapper src={message} {...props} />
  )
}

export const SvgPencilIcon = (props) => {
  return (
    <SvgWrapper src={pencil} {...props} />
  )
}

export const SvgPdfIcon = (props) => {
  return (
    <SvgWrapper src={pdf} {...props} />
  )
}

export const SvgRemoveIcon = (props) => {
  return (
    <SvgWrapper src={ic_close} {...props} />
  )
}

export const SvgPlusIcon = (props) => {
  return (
    <SvgWrapper src={ic_control_point} {...props} />
  )
}

export const SvgPlusIconMedium = (props) => {
  return (
    <SvgWrapper src={ic_control_point_2} {...props} />
  )
}

export const SvgPlusIconLarge = (props) => {
  return (
    <SvgWrapper src={ic_add_large_gray} {...props} />
  )
}

export const SvgTimeBarIndicator = (props) => {
  return (
    <SvgWrapper src={time_bar_indicator} {...props} />
  )
}
export const SvgStarEmptyIndicator = (props) => {
  return (
    <SvgWrapper src={star_filled} {...props} />
  )
}
export const SvgStarFilledIndicator = (props) => {
  return (
    <SvgWrapper src={star_empty} {...props} />
  )
}
export const SvgStarHalfIndicator = (props) => {
  return (
    <SvgWrapper src={star_half} {...props} />
  )
}

export const SvgWarningIcon = (props) => {
  return (
    <SvgWrapper src={ic_warning} {...props} />
  )
}

export const SvgVideoIcon = (props) => {
  return (
    <SvgWrapper src={video} {...props} />
  )
}

export const SvgYoutubeIcon = (props) => {
  return (
    <SvgWrapper src={youtube} {...props} />
  )
}

export const SvgReturnToTopIcon = (props) => {
  return (
    <SvgWrapper src={return_to_top} {...props} />
  )
}

export const SvgTrashIcon = (props) => {
  return (
    <SvgWrapper src={trash} {...props} />
  )
}
