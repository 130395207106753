import React, { useState } from 'react'
import styles from '../styles.module.css'
import { SvgPlusIconMedium, SvgMessageIcon } from 'components/general/icons'
import Config from 'utils/config'
import AddDirectAccessUserModal from './AddDirectAccessUserModal'

const AddDirectAccessUser = ({ job, setJob }) => {
  const [show, setShow] = useState(false)
  const onClose = () => { setShow(false) }

  return (<>
    <div className={styles.clientTeamMemberRow} style={{cursor: "pointer"}} onClick={() => { setShow(true) }}>
      <div className={styles.organizerLeftCol}>
        <SvgPlusIconMedium />
      </div>
      <div className={styles.organizerRightCol}>
        {Config.messaging && <div style={{float: 'right', cursor: 'pointer'}} >
          <SvgMessageIcon />
        </div>}
        <div className={styles.organizerDesignation}>Add an Observer and/or Idea Director</div>
        <div className={styles.organizerName}>
          Invite Observers (view only) or Idea Directors (full permissions) to this Idea Stream. Note only one Idea Director may be invited at a time.
        </div>
      </div>
    </div>
    <AddDirectAccessUserModal job={job} setJob={setJob} show={show} onClose={onClose} />
  </>)
}

export default AddDirectAccessUser