import React from 'react'
import { SvgEmptyAvatarIcon } from 'components/general/icons'
import classnames from 'classnames'

export default function Avatar({record, hideIfNoAvatar, ...props}) {
  let { style, ...imgProps } = props
  style = style || {}
  if(record.__type == 'User') {
    style.borderRadius = '50%'
    style.objectFit = 'cover'
  }

  if (!record.avatar_url && hideIfNoAvatar) {
    return (<></>)
  } else {
    return(
      <>
        { record.avatar_url && <img src={record.avatar_url} style={style} {...imgProps} /> }
        { !record.avatar_url && <SvgEmptyAvatarIcon style={style} {...imgProps} />}
      </>
    )
  }
}
