import ColorPicker from './Picker'

const addColor = (style, setEditorState, getEditorState) => (color) => {
  return setEditorState(style.add(getEditorState(), color))
}

const removeColor = (setEditorState, getEditorState) => () => {
  return setEditorState(style.remove(getEditorState()))
}

const currentColor = (style, getEditorState) => () => style.current(getEditorState())

export const colorPickerPlugin = (style, setEditorState, getEditorState) => ({
  addColor: addColor(style, setEditorState, getEditorState),
  removeColor: removeColor(style, setEditorState, getEditorState),
  currentColor: currentColor(style, getEditorState),
})

export default ColorPicker
