import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import expertsResource from 'resources/experts';
import styles from './styles.module.scss';

const validationSchema = object().shape({
  employees: array().of(string().email('Invalid email address')),
});

const EmployeeForm = ({ history }) => {
  const navigateToDashboard = () => history.push('/');

  const handleinviteAll = async (values, { setSubmitting }) => {
    try {
      const experts = [];
      values.employees.forEach((email) => !!email && experts.push({ email, profile_type: 'employee' }));
      if (experts.length) {
        await expertsResource.create({ data: { experts } });
        navigateToDashboard();
        toast.success('Your invitation was sent.');
      } else {
        toast.error('Please add email addresses');
      }
    } catch (err) {
      toast.error('Failed to send all invitations');
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: { employees: [] },
    validationSchema,
    onSubmit: handleinviteAll,
  });

  const handleChangeEmail = (index) => (event) => {
    const employees = [...values.employees];
    employees[index] = event.target.value;
    setFieldValue('employees', employees);
  };

  const handleAdd = () => {
    setFieldValue('employees', [...values.employees, '']);
  };

  return (
    <>
      <Row>
        <Col xs={12} className={styles.talentPoolHeader}>
          <h3 className="primaryLabel">Employees</h3>
          <p>Will not be asked to submit a W9, as they are already employees.</p>
        </Col>
        {values.employees.map((employee, index) => (
          <Col className={styles.talentInput} key={`employee-${index}`} md={4} sm={6} xs={12}>
            <input
              type="email"
              className={styles.talentEmail}
              value={employee}
              placeholder="john.doe@agency.com"
              onChange={handleChangeEmail(index)}
            />
            {Boolean(touched.employees?.[index] && errors.employees?.[index]) && (
              <span className={styles.error}>{errors.employees?.[index]}</span>
            )}
          </Col>
        ))}
        <Col className={styles.talentInput} md={4} sm={6} xs={12}>
          <button className="btn btn-primary btn-slim" onClick={handleAdd}>
            Add New
          </button>
        </Col>
      </Row>
      <button
        className={classnames('btn', styles.inviteBtn)}
        disabled={!values.employees.length}
        onClick={handleSubmit}
      >
        Invite Employees
      </button>
    </>
  );
};

export default EmployeeForm;
