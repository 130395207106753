import React, {} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './styles.module.css'
import classnames from 'classnames'

export default function MiniHeader (props) {
  const style = props.style || {}
  style.position = style.position || 'relative'
  return (
    <div className={classnames(styles.inner_wrapper, {[styles.bottomBorder]: !props.noBorder})} style={style} {...props}>
      {props.text && <span className={styles.text}>{props.text}</span>}
      {props.subtext && <span className={styles.subtext} style={{ paddingTop: 6}}>{props.subtext}</span>}
      {props.children}
    </div>
  )
}
