import React, { useState, useContext, useEffect } from 'react'
import Store from 'store'
import { Container, Row, Col, Table } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import jobsResource from 'resources/jobs'
import ideasResource from 'resources/ideas'
import Loading from 'components/general/loading'
import * as routes from 'constants/routes'
import styles from './styles.module.css'
import MiniHeader from 'components/general/mini_header'
import moment from 'moment'
import { SvgTimeBarIndicator, SvgMessageIcon, SvgPdfIcon, SvgVideoIcon } from 'components/general/icons/index.jsx'
import { ExpertGrid, ExpertCard } from '../create/expert_invite_step.jsx'
import { useDrag, useDrop } from 'react-dnd'
import { toast } from 'react-toastify';
import Avatar from 'components/general/avatar'
import Uploader from './uploader'
import Briefing from './briefing'
import axios from 'axios'
import Config from 'utils/config'
import queryString from 'query-string'
import RichContent from '../idea/rich_content.jsx'

export default function PrintJob ({ history, match, location }) {
  const { state } = useContext(Store)
  const [job, setJob] = useState(location.state && location.state.job)
  const [loading, setLoading] = useState(!job)

  const parsedQuery = queryString.parse(location.search)

  const fetchRedirectedImages = async () => {
    const images = document.querySelectorAll('img').forEach((img) => {
      // follow redirects and re-set the src on the image
      try {
        fetch(img.src).then(r => {
          img.src = r.url
        })
      } catch(e) {
        // youtube etc will land here, thats OK
      }
    })
    setTimeout(() => { window.status = 'ix-ready'}, 1000)
  }

  const fetchJob = async () => {
    try {
      const response = await jobsResource.show({ params: { id: match.params.jobId, print: true, print_token: parsedQuery.token }})
      setJob(response.data)
      setTimeout(fetchRedirectedImages, 300)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchJob()
  }, [])

  let experts = job && job.expertTeam
  // up to 5 experts fit on this page in print mode
  experts = experts && experts.slice(0, 5)
  const timeOutOfRange = job && (moment() < moment(job.start_date) || moment(job.end_date) < moment())
  const totalHours = job && !timeOutOfRange && moment.duration(moment(job.end_date).diff(moment(job.start_date))).asHours()
  const hoursElapsed = job && !timeOutOfRange && moment.duration(moment().diff(moment(job.start_date))).asHours()
  const percentageElapsed = Math.min(hoursElapsed * 100.0 / totalHours, 97.0).toFixed(1)


  const [{ isDragging, isDraggingOnTeamTarget }, teamDrop] = useDrop({
    accept: 'expert',
    drop: () => ({ team: true }),
    collect: monitor => ({
      isDraggingOnTeamTarget: monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
  })

  return (
      <>
      { loading && !job && <Loading /> }
      { !loading && <div>

      <Table style={{ width: '100%'}}>
        <thead>
          <tr>
            <th>
              <ContentHeader className={styles.heroWrapper} text={<><span id="project-name" className="print-only" style={{fontWeight: 'bold'}}>Project Name: </span>{ job && job.title}</>} noBorder={true} style={{marginBottom: 12, display: 'flex'}}>
              </ContentHeader>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className={`${styles.headerCenter}`}>Idea Stream Summary</div>
            </td>
          </tr>
        </tbody>
      </Table>

      <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <Table style={{width: '50%'}}>
        <tbody>
          <tr>
            <td>
              <div className={styles.rightColBox}>
                <MiniHeader text="Organizer" style={{marginBottom: 17 }}/>
                <div className={styles.organizerLeftCol}>
                  <Avatar className={styles.organizerAvatar} record={ job && job.creator} />
                </div>
                <div className={styles.organizerRightCol}>
                  {Config.messaging && <div style={{float: 'right', cursor: 'pointer'}} >
                    <SvgMessageIcon />
                  </div>}
                  <div className={styles.organizerAuthor}>{job && job.creator.fullName}</div>
                  <div className={styles.organizerOrganization}>{job && job.organization.name}</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="1">
              <div className={styles.rightColBox}>
                <MiniHeader text="Vitals" />
                <div className={styles.boxInnerPadding}>
                  <div style={{fontWeight: 'bold', fontSize: 13, float: 'right'}}>Finish</div>
                  <div style={{fontWeight: 'bold', fontSize: 13}}>Start</div>
                  <div className={styles.gradientCell} style={{position: 'relative'}}>
                    {!timeOutOfRange && <SvgTimeBarIndicator style={{position: 'absolute', top: -6, left: `${percentageElapsed}%`}} />}
                    <div className={styles.timebarLabel} style={{left: 12}}>{moment(job && job.start_date).format("MM.DD.YY")}</div>
                    <div className={styles.timebarLabel} style={{right: 12}}>{moment(job && job.end_date).format("MM.DD.YY")}</div>
                    <div  className={styles.gradient} />
                  </div>
                  { job && job.payment_type !== 'none' && <>
                    <div style={{fontWeight: 'bold', fontSize: 13, float: 'left', marginTop: 33}}>Spoils</div>
                    <div style={{fontSize: 10, float: 'right', paddingLeft: 5, marginTop: 24}}>Per<br />Freelancer</div>

                    <div style={{fontWeight: 'bold', fontSize: 28, marginTop: 19, textAlign: 'right'}}>
                      { job && job.payment_type === 'equal' && job.per_expert_budget }
                      { job && job.payment_type === 'custom' && job.customized_budget }
                    </div>
                  </>}
                  <div className={styles.missionTile} style={{marginTop: 5}}>
                    <div style={{fontWeight: 'bold', fontSize: 18, marginBottom: 8}}>Mission</div>
                    <div className={styles.missionText}>
                      {job && job.mission}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        </Table>
        <div className={styles.rightColBox} style={{ margin: '3px 3px 15px 3px', flex: 1, display: 'flex', flexDirection: 'column'}}>
          <MiniHeader text="Team and Ratings" style={{marginBottom: 17 }}/>
          <ExpertGrid experts={experts}
            dragType="none"
            borderFn={() => '1px solid black'}
            allowDrag={() => false} direction={'column'}
            showStars={true}
            wrapperStyles={{ height: '100%', justifyContent: 'space-around'}}>
            {(expert) => <></>}
          </ExpertGrid>
        </div>
      </div>
      <Table style={{ width: '100%', pageBreakAfter: 'always'}}>
        <tr>
          <td>
            <div className={styles.rightColBox}>
              <MiniHeader text="Stats" style={{marginBottom: 17 }}/>
              <table className={styles.stat}>
                <tr>
                  <td className={styles.statLabel}>
                    Number of Ideas over session:
                  </td>
                  <td>{job && job.total_ideas}</td>
                </tr>
                <tr>
                  <td className={styles.statLabel}>
                    Number of Builds/Riffs over session:
                  </td>
                  <td>{ job && job.total_comments}</td>
                </tr>
                <tr>
                  <td className={styles.statLabel}>
                    Number of ideas exported to this package:
                  </td>
                  <td>{job && job.print_ideas.length}</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
        </Table>
      </div>}
      </>

  )
}
