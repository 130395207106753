import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const subscriptionsResource = resourceBuilder.build('/subscriptions', {
  upgrade: {
    method: 'put'
  },
})

export default subscriptionsResource
