import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import Store from 'store';

import * as routes from 'constants/routes';
import Avatar from 'components/general/avatar.jsx';
import Loading from 'components/general/loading';
import notificationsResource from 'resources/notifications';
import jobsResource from 'resources/jobs';
import { ExistingExpertShortProfile } from '../jobs/create/expert_invite_step';
import NotificationsTile from './notifications';
import expertStyles from './expert.module.css';

const fetchNotifications = async (setNotifications, setLoading) => {
  try {
    setLoading(true);
    const response = await notificationsResource.index();
    setNotifications(response.data);
  } catch (e) {
    toast.warn("Uh oh! We weren't able to fetch your notifications. Please try again soon.");
  } finally {
    setLoading(false);
  }
};

const JobListTile = (props) => (
  <>
    <div
      style={{
        width: '100%',
        marginBottom: 20,
        height: 210,
        paddingTop: 17,
        paddingLeft: 20,
        paddingRight: 20,
        border: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          fontSize: 22,
          fontWeight: 'bold',
          paddingBottom: 2,
          borderBottom: '2px solid black',
          marginBottom: 12,
        }}
      >
        {props.title}
      </div>
      {!props.jobs.length && <p style={{ fontStyle: 'italic' }}>You don't have any {props.title.toLowerCase()}.</p>}
      {props.jobs.length > 0 && (
        <>
          <div style={{ textAlign: 'right', fontSize: 11, opacity: 0.6, fontStyle: 'italic' }}>
            {props.launchDate ? 'Launch Date' : 'Days Left'}
          </div>
          <div style={{ overflowY: 'scroll ' }}>
            {props.jobs.map((job) => {
              const daysLeft = Math.max(moment(job.end_date).diff(moment(), 'days'), 0);
              const confirmed = (job.expert_job.chosen && job.expert_job.expert_accepted) || props.showAsConfirmed;
              const launchDate = moment(job.start_date).format('MM/DD/YY');

              return (
                <a
                  {...(props.title !== 'Pending Jobs' ? { href: routes.JOB(job.slug) } : {})}
                  key={job.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 16,
                    textDecoration: 'none',
                    color: 'black',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      top: 11,
                      width: 6,
                      height: 6,
                      minHeight: 6,
                      minWidth: 6,
                      borderRadius: '50%',
                      marginRight: 5,
                      backgroundColor: confirmed ? '#009245' : '#ED9B2F',
                    }}
                  />
                  <Avatar record={job.organization} hideIfNoAvatar={true} className={expertStyles.organizationAvatar} />
                  <div style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '28px', width: '100%' }}>{job.title}</div>
                  <div style={{ fontSize: 22, fontWeight: 'bold', lineHeight: '28px' }}>
                    {props.launchDate ? launchDate : daysLeft}
                  </div>
                </a>
              );
            })}
          </div>
        </>
      )}
    </div>
  </>
);

function ExpertDashboard({ history }) {
  const { dispatch, state } = useContext(Store);
  const [jobs, setJobs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notificationsLoading, setNotificationsLoading] = useState(true);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await jobsResource.index();
        setJobs(response.data);
      } finally {
        setLoading(false);
      }
    };
    fetchJobs();
    fetchNotifications(setNotifications, setNotificationsLoading);
  }, []);

  const activeJobs = jobs && jobs.filter((j) => j.status === 'active' && j.expert_job.chosen);
  const pendingJobs =
    jobs && jobs.filter((j) => j.status === 'created' && j.expert_job.expert_accepted && j.expert_job.chosen !== false);

  return (
    <>
      <div className={expertStyles.headerContainer}>
        <Avatar record={state.currentUser} className={expertStyles.avatar} />
        <div className={expertStyles.headerUserInfo}>
          <div style={{ fontSize: 32, fontWeight: 'bold' }}>{state.currentUser.fullName}</div>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>{state.currentUser.email}</div>
          {state.currentUser.expert_type !== 'employee' && (
            <div style={{ fontSize: 14, marginTop: 12 }}>
              <strong>Bank:</strong>
              {state.currentUser.stripe_account_id && (
                <>
                  <div
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#009245',
                      width: 10,
                      height: 10,
                      marginLeft: 12,
                      marginRight: 5,
                      borderRadius: 5,
                    }}
                  />
                  <span style={{ color: '#009245' }}>Connected</span>
                </>
              )}
              {!state.currentUser.stripe_account_id && (
                <>
                  <div
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#E95656',
                      width: 10,
                      height: 10,
                      marginLeft: 12,
                      marginRight: 5,
                      borderRadius: 5,
                    }}
                  />
                  <span style={{ color: '#E95656', fontStyle: 'italic' }}>Not Connected</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {loading && <Loading />}
      {!loading && (
        <Row>
          <Col lg={4} md={6} xs={12}>
            <JobListTile title="Pending Jobs" jobs={pendingJobs} launchDate={true} />
          </Col>
          <Col lg={4} md={6} xs={12}>
            <JobListTile title="Active Jobs" jobs={activeJobs} showAsConfirmed={true} />
          </Col>
          <Col lg={4} xs={12}>
            <NotificationsTile loading={notificationsLoading} notifications={notifications} />
          </Col>
          <Col xs={12} style={{ marginBottom: 200 }}>
            <ExistingExpertShortProfile expert={state.currentUser} layout="wide" />
          </Col>
        </Row>
      )}
    </>
  );
}

export default ExpertDashboard;
