import React, { useState, useContext, useEffect } from 'react'
import Store from 'store'
import { Container, Row, Col, Table } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import jobsResource from 'resources/jobs'
import ideasResource from 'resources/ideas'
import Loading from 'components/general/loading'
import * as routes from 'constants/routes'
import styles from './styles.module.css'
import MiniHeader from 'components/general/mini_header'
import moment from 'moment'
import { SvgTimeBarIndicator, SvgMessageIcon, SvgPdfIcon, SvgVideoIcon } from 'components/general/icons/index.jsx'
import { ExpertGrid, ExpertCard } from '../create/expert_invite_step.jsx'
import { toast } from 'react-toastify';
import Avatar from 'components/general/avatar'
import Uploader from './uploader'
import Briefing from './briefing'
import axios from 'axios'
import Config from 'utils/config'
import queryString from 'query-string'
import RichContent from '../idea/rich_content.jsx'
import Subheader from 'components/general/subheader'
import SignedInBar from 'components/general/signed_in_bar'
import Comment from 'components/jobs/idea/comment'

export default function PrintIdea ({ history, match, location }) {
  const { state } = useContext(Store)
  const [job, setJob] = useState(location.state && location.state.job)
  const [loading, setLoading] = useState(!job)
  const [idea, setIdea] = useState(false)
  const ideaNumber = match.params.index && (parseInt(match.params.index) + 1)

  const parsedQuery = queryString.parse(location.search)

  const fetchRedirectedImages = async () => {
    const images = document.querySelectorAll('img').forEach((img) => {
      // follow redirects and re-set the src on the image
      try {
        fetch(img.src).then(r => {
          img.src = r.url
        })
      } catch(e) {
        // youtube etc will land here, thats OK
      }
    })
    setTimeout(() => { window.status = 'ix-ready'}, 1000)
  }

  const fetchJob = async () => {
    try {
      const response = await jobsResource.show({ params: { id: match.params.jobId, print: true, print_token: parsedQuery.token }})
      setJob(response.data)
      setIdea(response.data.print_ideas.find(i => i.id === match.params.ideaId))
      setTimeout(fetchRedirectedImages, 300)
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchJob()
  }, [])

  return (
      <>
      { loading && !idea && <Loading /> }
      { !loading && <div>
      <style>
      {`
        table.report-container {
            page-break-after:always;
            width: 100%;
        }
        thead.report-header {
            display:table-header-group;
        }
        tfoot.report-footer {
            display:table-footer-group;
        }
        .report-content img {
          max-width: 500px;
        }
      `}
      </style>
      <table class="report-container">
         <thead class="report-header">
           <tr>
              <th class="report-header-cell">
                 <div class="header-info">
                   <SignedInBar mbClass={'mb-3'} />
                   <div className={`${styles.headerCenter} print-only`}>Idea #{ideaNumber}</div>
                 </div>
               </th>
            </tr>
          </thead>
          {false && <tfoot class="report-footer">
            <tr>
               <td class="report-footer-cell">
                 <div class="footer-info">
                 ...
                 </div>
                </td>
            </tr>
          </tfoot>}
          <tbody class="report-content">
            <tr>
               <td class="report-content-cell">
                  <div class="main">
                    <div style={{ position: 'fixed', top: 55, marginLeft: 5, marginRight: 5, border: '1px solid black', width: 'calc(100% - 16px)', height: 'calc(100vh - 55px)'}}></div>
                    <Table style={{ width: '100%'}}>
                      { idea && <tr>
                        <td>
                          <div style={{display: 'table', width: '100%', padding: 15}}>

                              <div style={{fontSize: 24, fontWeight: 'bold', paddingBottom: 15, borderBottom: '3px solid black', marginBottom: 15}}>
                                Original Idea Post
                              </div>
                              <div style={{marginLeft: -10, fontSize: 14}}>
                                <ExpertCard expert={idea.author} dragType="none" />
                              </div>

                              <Subheader text={idea.title.toUpperCase()} subtext={idea.createdAtFormatted} />

                              <RichContent content={idea.content} videoAsImage={true} />
                          {idea.comments.length > 0 && <>
                            <div style={{fontSize: 20, fontWeight: 'bold', paddingTop: 20, paddingBottom: 15, borderBottom: '2px solid black', display: 'table', width: '100%'}}>
                              Builds ({idea.comments.length})
                            </div>
                            {idea.comments.map(comment => <>
                              <Comment key={comment.id} job={job} comment={comment} disableActions={true} richContentProps={{videoAsImage: true}} />
                            </>)}
                          </>}
                        </div>
                        </td>
                        </tr>}
                    </Table>
                  </div>
                </td>
             </tr>
           </tbody>
      </table>
      </div>}
      </>

  )
}
