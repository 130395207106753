import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import TagEditor from 'react-tageditor'
import 'react-tageditor/dist/style/default.css'
import './tags.css'

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, ' '];

function ExpertTags(props, ref) {
  const tagEditor = useRef(null)

  const handler = (text, output, action) => {
    if (action === 'add' && text[0] !== '#') {
      tagEditor.current.remove(text)
      tagEditor.current.add('#' + text)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      props.tags.forEach(t => tagEditor.current.add(t))
    }, 1000)
  }, [])

  useImperativeHandle(ref, () => ({
    output: () => tagEditor.current.output()
  }))

  return (
    <>
      <TagEditor ref={tagEditor} tags={[]} delimiters={delimiters} onChange={handler} />
      <div style={{display: 'inline', lineHeight: '26px'}}>
        <div onClick={() => document.querySelector('.tag-editor').click()}
          style={{display: 'inline-block', height: 30, padding: '1px 1em 1px 1em', cursor: 'pointer', fontSize: 12, textAlign: 'center', lineHeight: '26px', fontWeight: 'bold', borderRadius: 15, border: '2px dashed black'}}>
          Add Tag
        </div>
      </div>
    </>
  )
}

export default forwardRef(ExpertTags)
