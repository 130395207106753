import resourceBuilder from './resource_builder';
import defaultResourceSchema from './default_resource_schema';

const { create } = defaultResourceSchema;

const achMailResource = resourceBuilder.build('/send_ach_mail', {
  create,
});

export default achMailResource;
