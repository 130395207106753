import BaseModel from './base'
import moment from 'moment'

export default class Idea extends BaseModel {

  get createdAtFormatted() {
    return moment(this.created_at).format("MM.DD.YY")
  }

  get updatedAtFormatted() {
    return moment(this.updated_at).format("MM.DD.YY")
  }
}
