import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../general/button'
import jobsResource from '../../../resources/jobs'
import { Container, Row, Col, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import { toast } from 'react-toastify';
import * as routes from 'constants/routes'
import {Helmet} from 'react-helmet'
import { expertBorder } from 'components/jobs/show'
import ideaStyles from '../idea/styles.module.css'
import { SvgBackArrow } from '../../../components/general/icons'
import  { ExpertShortProfile, ExpertGrid, ExpertDropTarget } from './expert_invite_step.jsx'
import { useDrop } from 'react-dnd'
import classnames from 'classnames'
import { isBrowser } from "react-device-detect";
import { SvgPlusIcon, SvgRemoveIcon } from 'components/general/icons'
import autoTypeCast from 'auto-type-cast'
import useRoleRequired from 'utils/useRoleRequired'

export default function JobBuilderStep4 ({ history, location }) {
  useRoleRequired('customer')

  const job = location.state && location.state.job

  useEffect(() => {
    autoTypeCast(job)
  }, [job])

  const experts = job.experts

  const [ selectedExpert, setSelectedExpert ] = useState()
  const [{ isDragging, isDraggingOnTeamTarget }, teamDrop] = useDrop({
    accept: 'expert',
    drop: () => ({ team: true }),
    collect: monitor => ({
      isDraggingOnTeamTarget: monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
  })

  const [team, setTeam] = useState([])
  const addToTeam = (expert) => {
    if(teamIncludes(expert)) {
      return
    }
    setTeam(team.concat([expert]))
  }
  const removeFromTeam = (expert) => setTeam(team.filter((member) => member.id !== expert.id))
  const teamIncludes = (expert) => !!team.find((member) => member.id === expert.id)

  useEffect(() => {
    if(!job) {
      history.push('/')
      toast.warn("Uh oh! We couldn't find the job you were working on. Please try again")
    }
    if(job.status !== 'created') {
      history.push({
        pathname: routes.JOB(job.slug),
        state: {
          job: job,
        }
      })
    }
  }, [])


  const handleBackButton = () => {
    history.push({
      pathname: routes.JOB_BUILDER_STEP_3_BRIEFING,
      state: {
        job: job,
        preventRedirect: true
      }
    })
  }

  const refreshJob = async () => {
    const response = await jobsResource.show({ params: { id: job.id }})
    history.replace({
      pathname: location.pathname,
      state: {
        job: response.data
      }
    })
  }

  useEffect(() => {
    refreshJob()
    const interval = setInterval(refreshJob, 30000);
    return () => { clearInterval(interval) }
  }, [])

  const [saving, setSaving] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSaving(true)

    try {
      const finalizeResponse = await jobsResource.finalizeTeam({ data: {
        expert_ids: team.map((expert) => expert.id),
      }, params: { id:  job.id }})
    } catch (error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to lock in your team. Please try again shortly")
      setSaving(false)
      return
    }

    try {
      const startResponse = await jobsResource.start({ params: { id:  job.id }})
      history.replace({
        pathname: location.pathname,
        state: {
          job: startResponse.data
        }
      })
      toast.success("Your Job has begun. Look for new Ideas soon!")
      history.push({
        pathname: routes.JOB(startResponse.data.slug),
        state: {
          job: startResponse.data,
        }
      })
    } catch (error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to start the Job. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  const handleInviteMore = () => {
    history.push({
      pathname: routes.INVITE_MORE(job.id),
      state: {
        job: job,
      }
    })
  }

  if(!job) {
    // useEffect will redirect in a moment, but dont want to throw an error below
    return
  }

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              <div className={ideaStyles.back} onClick={handleBackButton}>
                <SvgBackArrow /> <span>Back to Step 3: Post Inspiring Briefing Materials</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Helmet>
              <title>Start a Job | Ideasicle X</title>
            </Helmet>
            <Col>
              <h1>Step 4: Monitor Your Invited Freelancers</h1>
              <p>Below are the Freelancers you've invited and their status. Once you've got 4 who have accepted the job that you think are the perfect mix, drag each into the “Final Team” box and click the “Launch Job” button. Doing so will automatically send the team a link to the Idea Stream. Note: the “Launch Job” button will not work until you have at least three team members in the Final Team box.</p>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div style={{ border: '1px solid black', padding: 15, paddingTop: 15, paddingBottom: 30 }}>
                <Row>
                <Col lg={{ span: 7, order: 1}} xs={{ span: 12, order: 1}}>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col xl={6} lg={12} md={6} sm={12} xs={12}>
                          <ExpertShortProfile expert={selectedExpert} noDataText={'Click on any invited Freelancer to see their "baseball card" here.'}>
                            {
                              !selectedExpert || teamIncludes(selectedExpert)
                                ?
                              null
                                :
                                selectedExpert.expert_accepted &&
                                <button type="button" className="btn btn-primary btn-mini" onClick={() => addToTeam(selectedExpert)}>
                                Add
                              </button>
                            }
                            </ExpertShortProfile>
                            <div className={styles.buttons}>
                              <Button onClick={handleInviteMore}>Invite More</Button>
                            </div>
                        </Col>

                        <Col xl={6} lg={12} md={6} sm={12} xs={12}>
                          <div className={styles.expert_box}>
                            <div style={{minWidth: 'calc(100% - 70px)'}}>
                              <h3 style={{marginTop: 0, marginBottom: 2, fontSize: 24}}>Invited Freelancers</h3>
                            </div>

                            <div style={{marginBottom: 7, fontSize: 11}}>
                              Only freelancers who have accepted may be added to your Final Team
                            </div>
                            <div style={{ fontSize: 12, display: 'flex', alignItems: "center", marginBottom: 7 }}>
                              <div className="mr-2 p-1" style={{ border: expertBorder({})}}>
                                No response
                              </div>
                              <div className="mr-2 p-1" style={{ border: expertBorder({ expert_accepted: true })}}>
                                Accepted
                              </div>
                              <div className="mr-2 p-1" style={{ border: expertBorder({ expert_accepted: false })}}>
                                Rejected
                              </div>
                            </div>

                            <div ref={teamDrop} className={classnames(styles.dropZone, {[styles.activeDrag]: isDragging && !isDraggingOnTeamTarget, [styles.activeHover]: isDraggingOnTeamTarget})}>
                              <ExpertGrid
                                addToTeam={addToTeam}
                                experts={job.experts}
                                dragType="expert"
                                borderFn={expertBorder}
                                allowDrag={(expert) => expert.expert_accepted}
                                setSelectedExpert={setSelectedExpert}
                                selectedExpert={selectedExpert}
                                wrapperStyles={{ height: 461 }}
                                showTalent
                                showExpert
                              >
                                {(expert) => (
                                  teamIncludes(expert)
                                    ?
                                  null
                                    :
                                    <div style={{position: 'absolute', bottom: 1, right: 5, cursor: 'pointer'}} onClick={() => addToTeam(expert)} >
                                      { expert.expert_accepted && <SvgPlusIcon style={{height: 14, width: 14}} />}
                                    </div>
                                )}
                              </ExpertGrid>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col lg={{ span: 5, order: 2}} xs={{ span: 12, order: 1}} className="mb-3">
                  <Row>
                    <Col xs={12}>
                      <div style={{border: '1px solid black', padding: 10 }}>
                        <div style={{minWidth: 'calc(100% - 70px)'}}>
                          <h3 style={{marginTop: 0, marginBottom: 7, fontSize: 24}}>Final Team</h3>
                        </div>

                        <ExpertDropTarget
                          drop={teamDrop}
                          team={team}
                          isDragging={isDragging} isDraggingOnTeamTarget={isDraggingOnTeamTarget}
                          hint={isBrowser ? <>Drag and drop from the invited list<br />to finalize your team!</> : <>Add Freelancers from the invited list<br />to finalize your team!</>}
                          expertGridProps={{
                            dragType: "team",
                            selectedExpert: selectedExpert,
                            setSelectedExpert: setSelectedExpert,
                            removeFromTeam: removeFromTeam,
                            borderFn: expertBorder,
                            showTalent: true,
                            showExpert: true,
                          }}
                          expertGridChildRender={(expert) => (
                            <div style={{position: 'absolute', bottom: 2, right: 6, cursor: 'pointer'}} onClick={() => removeFromTeam(expert)} >
                              {<SvgRemoveIcon style={{height: 12, width: 12}} />}
                            </div>
                          )}
                        />
                        {team.length > 0 && <small>Click the x to remove a freelancer from your team</small>}

                      </div>
                    </Col>
                  </Row>
                </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className={styles.buttons}>
            {!job.briefing_document_url && (
              <div className={classnames(styles.skip, styles.goBackStep)} onClick={handleBackButton}>
                <SvgBackArrow style={{ paddingRight: 8, paddingTop: 4 }} /><span>Upload Briefing Materials</span>
              </div>
            )}
            {!job.briefing_document_url ? (
              <OverlayTrigger
                placement={'top'}
                trigger={['hover','focus']}
                overlay={!job.briefing_document_url && (
                    <Tooltip className={styles.mustAddBriefingTooltip} id={`tooltip-top`}>
                      You must upload briefing materials before the job can be launched
                    </Tooltip>
                  )
                }
              >
                <div className={styles.submitBtnWrapper}>
                  <Button disabled={team.length === 0 || !job.briefing_document_url || saving} className="primary" onClick={handleSubmit}>Launch Job</Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button disabled={team.length === 0 || saving} className="primary" onClick={handleSubmit}>Launch Job</Button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
