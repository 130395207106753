import React, { useState, useContext, useEffect, useMemo } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import jobsResource from 'resources/jobs'
import styles from '../styles.module.css'
import { toast } from 'react-toastify';
import Avatar from 'components/general/avatar.jsx'
import CurrencyInput from 'react-currency-input';
import { moneyConverter } from 'components/jobs/create'
import { formatCurrency } from 'utils/formatters'
import Button from 'components/general/button'
import Discount from 'components/general/discount'
import Loading from 'components/general/loading.jsx'
import adminJobsResource from '../../../../resources/admin_jobs'
import { useFormik } from 'formik'

const renderPct = (amount) => Math.floor(amount * 100) + '%'

export default function AdminReviewPaymentsJob ({ history, match, location }) {
  const { dispatch, state } = useContext(Store)
  const [job, setJob] = useState(undefined)
  const { values, isSubmitting, setFieldValue } = useFormik({
    initialValues: { payments: [] },
    onSubmit: finish
  })

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await adminJobsResource.show({ params: { id: match.params.jobId }})
        setJob(response.data)
        setFieldValue('payments', response.data.payments)
      } catch (error) {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again later.")
      }
    }
    fetchJob()
  }, [])

  const experts = job && job.expertTeam

  const additionalFeePct = useMemo(() => job?.subscription_metadata_cache?.additionalFeePct(), [job])

  const finish = async () => {
    try {
      const { payments } = values
      const response = await adminJobsResource.payments({ data: { payments }, params: { id:  job.id }})
      toast.success(`Payments initiated. Reminder: invoice ${job.organization.name} separately.`)
      history.push('/')
    } catch (error) {
      console.error(error)
      toast.warn("Uh oh! Something went wrong. Please try again later.")
    }
  }

  let additionalFeeDollars = 0.0

  if(!job) {
    return <>
      <Loading>
        <p>Loading proposed payments...</p>
      </Loading>
    </>
  }

  return (
    <Container>
      <ContentHeader>
        <Col md={12} lg={12} style={{ textAlign: "left", display: 'flex', alignItems: "center" }}>
          <span className="job-title">Review Payments for {job.title}</span>
        </Col>
      </ContentHeader>
      <Row style={{paddingTop: 34}}>
        <Col xs={12} sm={12} md={6} lg={6}>
          {values.payments.map((paymentValue, index) => {
            const expert = experts.find((expert) => expert.id === paymentValue.expert_id)
            return (
              <Col lg={12} xs={12} key={expert.id}>
                <div className={styles.endJobExpertTile} style={{display: 'flex', flexDirection: 'column'}}>
                  <div style={{padding: 20}}>
                    <Avatar record={expert} className={styles.endJobExpertAvatar} />
                    <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                      <span className={styles.enbJobExpertName}>{expert.fullName}</span>
                    </div>
                  </div>
                  <div style={{backgroundColor: '#F2F2F2', padding: 20, height: 220}}>
                    <CurrencyInput
                      className={styles.endJobFinalBudget}
                      prefix="$"
                      name={`payments.${index}.amount_in_cents`}
                      value={paymentValue.amount_in_cents / 100}
                      onChangeEvent={(e) => {
                        const newValue = moneyConverter(e.target.value);
                        setFieldValue(`payments.${index}.amount_in_cents`, newValue * 100)  
                      }}
                      style={{ display: 'block', marginTop: 30}}
                    />
                  </div>
                </div>
              </Col>
            )
          })}
        </Col>
      </Row>
      <Row style={{marginTop: 30}}>
        <Col lg={6} xs={0}></Col>
        <Col lg={5} xs={12}>
          <div className={styles.endJobSummaryHeading}>Payment Summary</div>
          <div className={styles.endJobSummaryRow} style={{borderBottom: 'none', marginBottom: 15}}>
            <span>FREELANCERS</span><span>PAYMENT</span>
          </div>
          {values.payments.map((paymentValue, index) => {
            const expert = experts.find((expert) => expert.id === paymentValue.expert_id)
            const feePortion = (paymentValue.amount_in_cents / 100) * additionalFeePct.discountedAmount
            additionalFeeDollars += feePortion
            return (
              <div className={styles.endJobSummaryRow}>
                <span>{expert.fullName}</span><span>{formatCurrency(paymentValue.amount_in_cents / 100, true)}</span>
              </div>
            )
          })}
          <div className={styles.endJobSummaryRow}>
            <span>Handling Fees for <br/> {job.organization.name} (<Discount formatter={renderPct} {...additionalFeePct} />)</span><span>{formatCurrency(additionalFeeDollars, true)}</span>
          </div>
          <div style={{marginTop: 25, float: 'right', marginBottom: 100}}>
            <Button type="primary" onClick={finish} disabled={isSubmitting}>
              Pay Freelancers
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
