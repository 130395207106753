import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const { show } = defaultResourceSchema

const discountsResource = resourceBuilder.build('/discounts', {
  show
})

export default discountsResource
