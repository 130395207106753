import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const { index } = defaultResourceSchema

const adminExpertsResource = resourceBuilder.build('/admin/experts', {
  index,
  deactivate: {
    url: '/{id}/deactivate',
    method: 'PUT',
  },
})

export default adminExpertsResource
