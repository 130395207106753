import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { BrowserView, isBrowser } from "react-device-detect";
import { useDrag, useDrop } from 'react-dnd'
import StarRatingComponent from 'react-star-rating-component';
import { toast } from 'react-toastify'
import axios from 'axios'
import classnames from 'classnames'
import * as yup from 'yup'

import { expertBorder as expertBorderExistingJob } from 'components/jobs/show/index'
import Avatar from 'components/general/avatar'
import { SvgInfoIcon, SvgPlusIcon, SvgPlusIconLarge, SvgRemoveIcon, SvgStarEmptyIndicator, SvgStarFilledIndicator, SvgStarHalfIndicator } from 'components/general/icons'
import Loading from 'components/general/loading'
import ReturnToTop from 'components/general/return_to_top';
import linkedinIconSrc from 'images/linkedin_icon.png'
import myPortfolioButtonSrc from 'images/portfolio.png'
import expertsResource from 'resources/experts'
import Config from 'utils/config'
import { formatCurrency } from 'utils/formatters'
import expertCardStyles from './expert_card_styles.module.css'
import InstructionBox from './instruction_box'
import styles from './styles.module.css'

const CancelToken = axios.CancelToken;
let searchCancelSource = null

function ExpertSearch ({ setExperts, setSearching, setLoading }) {
  function handleChange (event) {
    const value = event.target.value
    if (value.length > 2) {
      setSearching(true)
      setLoading(true)
      if(searchCancelSource) { searchCancelSource.cancel('superceded by subsequent request') }
      searchCancelSource = CancelToken.source()
      const promise = expertsResource.search({cancelToken: searchCancelSource.token, params: {q: value}})
      promise.then((response) => {
        setExperts(response.data)
        setLoading(false)
      }).catch((error) => {
        if(!error.isCancel) {
          console.error(error)
          toast.warn("Uh oh! Something went wrong. Please try again shortly.")
        }
        setLoading(false)
      })
    } else {
      setSearching(false)
      setLoading(false)
    }
  }


  return (
    <>
      <span className={styles.slimBoxLabel}>Search: </span>
      <input
        className={styles.searchBox}
        placeholder="by name, skill, or other keyword"
        onChange={handleChange} />
    </>
  )
}

const expertShortProfileStyles = {
  wrapper: (wide, expert) => ({
    backgroundColor: expert.expert_type === 'expert' ? '#FBED22' : '#fff',
    border: wide ? '1px solid black' : 'none',
    paddingBottom: 25,
    paddingLeft: 21,
    paddingTop: 18,
    position: 'relative',
  }),
  bio: (wide) => {
    let styles = {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: '18px',
      letterSpacing: 1,
      marginRight: 10,
    }
    if(wide) {
      styles = Object.assign(styles, {
          backgroundColor: '#F5F6F6',
          padding: '12px 10px',
          marginRight: 20
      })
    }
    return styles
  }
}
export const ExistingExpertShortProfile = ({ expert, children, layout }) => {
  const wide = layout === 'wide'
  const completedThreeJobs = expert.completed_jobs_count >= 3;
  return (
    <div className="mb-3" style={expertShortProfileStyles.wrapper(wide, expert)}>
      {!wide && <>
        <div style={{float: 'right', width: 155 }}>
          <h3 style={{marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{expert.first_name}<br />{expert.last_name}</h3>
          <div style={{width: '90%', borderBottom: '1px solid black'}} />

          {expert.cached_expert_rating && completedThreeJobs && <>
            <span style={{fontSize: 10, display: 'inline-block', marginTop: 6}}>
              Average Customer<br />Rating
            </span>

            <div style={{float: 'right', position: 'relative', fontSize: 48, fontWeight: 'bolder', right: 35, top: 15}}>
              {expert.cached_expert_rating}
            </div>
          </>}
        </div>
        <Avatar className={styles.avatarLarge} record={expert} />
      </>}
      {wide && <div style={{display: 'flex', flexDirection: 'row', borderBottom: '2px solid black', paddingBottom: 20, marginBottom: 12, marginRight: 20}}>
        <Avatar className={styles.avatarLarge} record={expert} />
        <div style={{flexGrow: 1, fontSize: 32, marginLeft: 18, fontWeight: 'bold', alignItems: 'center', display: 'flex'}}>{expert.first_name}<br />{expert.last_name}</div>
        {Config.ratings_enabled && <div style={{flexGrow: 0, pointerEvents: 'none'}}>
          { expert.cached_expert_rating && <div style={{fontSize: 18, borderBottom: '2px solid black', paddingBottom: 8, marginBottom: 8}}>Average Customer Rating</div>}
          <div className={styles.starTooltip}>
            { !completedThreeJobs && (
              <span className={styles.starTooltipText}>
                Rating will be displayed after three completed jobs.
              </span>
            )}
            <StarRatingComponent
              name="rating_overall"
              value={completedThreeJobs ? expert.cached_expert_rating + 1 : 0}
              starCount={10}
              renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
                {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
              </span>)}
              renderStarIconHalf={() => (
                <span style={{marginRight: 6, display: 'inline-block'}}>
                  <SvgStarHalfIndicator style={{marginRight: 6}}/>
                </span>
              )}
            />
          </div>
        </div>}
      </div>}

      <div style={{position: 'absolute', top: 11, right: 10}}>
        {children}
      </div>

      <div>
        <div style={{marginLeft: 5, marginTop: 25}}>
          { wide && <div style={{fontSize: 18, fontWeight: 'bold', marginBottom: 20}}>Bio</div>}
          <div style={expertShortProfileStyles.bio(wide)}>{expert.bio}</div>

          { false && <><a style={{float: 'right', padding: 5, margin: 4, color: 'white', background: 'black', borderRadius: '50%'}} href={`https://twitter.com/${expert.twitter}`}>
            &nbsp;t&nbsp;
          </a>

          <a style={{float: 'right', padding: 5, margin: 4, color: 'white', background: 'black', borderRadius: '50%'}} href={expert.facebook_url}>
            &nbsp;f&nbsp;
          </a></>}

          <span style={{fontWeight: 'bold', fontSize: 14, marginTop: 7, display: 'inline-block'}}>
            {expert.location}
          </span>
        </div>

        <div style={{ display: 'flex', marginTop: 6, marginBottom: 24, }}>
          { !wide && !!expert.linkedin_url && <a href={expert.linkedin_url} target="_blank" style={{ height: 30, backgroundColor: "#fff", borderRadius: 5, width: 86, display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer", marginRight: 10, textDecoration: 'none' }}>
            <img src={linkedinIconSrc} style={{height: 18, marginRight: 5}} alt="See my LinkedIn profile"></img>
            <span style={{ fontWeight: 600, fontSize: 14, color: "#000", marginTop: 2, marginBottom: 3}}>Profile</span>
          </a>}

          { !wide && !!expert.portfolio_url && <a href={expert.portfolio_url} target="_blank" style={{ height: 30 }} >
            <img src={myPortfolioButtonSrc} style={{height: '100%', marginRight: 5}} alt="See my portfolio"></img>
          </a>}
        </div>

        <hr style={{width: wide ? 'calc(100% - 20px)' : '95%', float: wide ? 'left' : 'none', marginRight: wide ? 20 : 0, marginLeft: 0, background: 'black', height: wide ? 2 : 1, border: 'none'}} />

        { wide && <div style={{fontSize: 18, fontWeight: 'bold', marginBottom: 10, marginTop: 20}}>Skills</div>}
        <div style={{lineHeight: 2}}>
          {expert.tags.map((tag, index) => {
            let tagLabel = tag
            if(tagLabel[0] !== '#') tagLabel = '#' + tagLabel
            return (
              <span key={index} className={styles.tag}>{tagLabel} </span>
          )})}
        </div>
      </div>
    </div>
  )
}

const NoExpertSelected = ({ title }) => (
  <div className="mb-3" style={{ fontStyle: 'italic', textAlign: 'center', backgroundColor: '#fff', border: '1px solid black', padding: '125px 35px' }}>
    <p>{ title || 'Click on any Freelancer to see their "baseball card" here.'}</p>
  </div>
)

export const ExpertShortProfile = (props) => (
  props.expert ?
  <ExistingExpertShortProfile {...props} /> : isBrowser ? <NoExpertSelected title={props.noDataText} /> : null
)

/* Has to be fixed height/width, so the squares are even, and therefore the grid works. */
export const ExpertCard = ({ expert, children, isSelected, setSelectedExpert, dragType, addToTeam, removeFromTeam, borderFn, allowDrag, showStars,  transparentBg, options={}, }) => {
  const border = borderFn && borderFn(expert) || '1px solid black'

  const [{ isDragging }, drag] = useDrag({
    item: { type: dragType },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        dropResult.team ? addToTeam(expert) : removeFromTeam(expert)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  return (
      <div style={{background:  transparentBg ? 'transparent' : (isSelected ? 'rgb(255,240,53)' : 'white'), height: 89, display: showStars? 'flex' : null, flexDirection: showStars ? 'row': null}} ref={allowDrag ? (allowDrag(expert) && drag || undefined) : drag}>
        <div className={styles.row} style={{cursor: (allowDrag ? (allowDrag(expert) && 'grab' || 'normal') : 'normal'), margin: 10, border, background:  transparentBg ? 'transparent' : (isSelected ? 'rgb(255,240,53)' : 'white'), height: 69, width: options && options.width ? options.width : 113, overflow: 'hidden', position: 'relative'}} onClick={() => setSelectedExpert && setSelectedExpert(expert)}>

          <div style={{float: 'right', marginTop: 5, maxWidth: 51}}>
            {expert.is_my_expert && <div style={{position: 'absolute', top: 5, left: 5, display: 'inline-block', backgroundColor: '#009245', width: 5, height: 5, borderRadius: '50%'}} />}
            <h6 className={expertCardStyles.name} title={expert.fullName}>{expert.fullName}</h6>
            <div style={{width: '90%', borderBottom: '1px solid black', marginTop: 4}} />
            <h2 style={{margin: 0}}>{expert.cached_expert_rating}</h2>
            <div style={{position: 'absolute', top: 0, right: 0}}>
            </div>
            {children}
          </div>

          <Avatar className={styles.avatar} record={expert} />

        </div>
      { Config.ratings_enabled && showStars &&
        <StarRatingComponent
          className="pdf-star-div"
          name="rating_overall"
          value={expert.cached_expert_rating ? expert.cached_expert_rating + 1 : 0}
          starCount={10}
          renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
            {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
          </span>)}
          renderStarIconHalf={() => (
            <span style={{marginRight: 6, display: 'inline-block'}}>
              <SvgStarHalfIndicator style={{marginRight: 6}}/>
            </span>
          )}
        />}
    </div>
)}

export const ExpertGrid = ({ experts, selectedExpert, setSelectedExpert, isSearching, children, ...props }) => (
  <div className={styles.hideScroll} style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'start', height: props.freelancerList && 524, overflowY: "scroll" , flexDirection: props.direction ? props.direction : null, ...(props.wrapperStyles || {})  }}>
    {props.showTalent && (
      <>
        {!isSearching && props.freelancerList && <span style={{ width: '100%', height: 19, fontSize: 12, textDecoration: 'underline' }}>Previously Invited:</span>}
        {experts.map((expert) => {
          if(expert.is_my_expert){
            const result = children(expert)
            return result ?
              <ExpertCard key={expert.id} expert={expert} isSelected={selectedExpert === expert} setSelectedExpert={setSelectedExpert} {...props}>{result}</ExpertCard>
              : null
          }
        })}
      </>
    )}
    {props.showExpert && (
      <>
        {!isSearching && props.freelancerList && <span style={{ width: '100%', height: 19, fontSize: 12, textDecoration: 'underline', marginTop: 10 }}>Other Top Rated Freelancers:</span>}
        {experts.map((expert) => {
          if(!expert.is_my_expert){
            const result = children(expert)
            return result ?
              <ExpertCard key={expert.id} expert={expert} isSelected={selectedExpert === expert} setSelectedExpert={setSelectedExpert} {...props}>{result}</ExpertCard>
              : null
          }
        })}
      </>
    )}
  </div>
)

export const ExpertGridOrHelp = (props) => (
  props.experts.length ? <ExpertGrid {...props} freelancerList={true} /> : <p style={{fontStyle: 'italic'}}>Use the search to get a list of Freelancers.</p>
)

export const ExpertDropTarget = ({drop, hint, team, isDragging, isDraggingOnTeamTarget, expertGridProps, expertGridChildRender = () => {}}) => {
  return (
    <div ref={drop} className={classnames(styles.teamDropZone, {[styles.empty]: isBrowser && team.length < 1, [styles.activeDrag]: isDragging && !isDraggingOnTeamTarget, [styles.activeHover]: isDraggingOnTeamTarget})}>
      {team.length < 1 && (
        <div>
          <div className={styles.teamEmptyText}>{hint}</div>
          <BrowserView>
            <SvgPlusIconLarge className={styles.teamEmptyDropIcon} style={{height: 68, width: 68}} />
          </BrowserView>
        </div>
      )}
      <ExpertGrid experts={team} {...expertGridProps}>
        {expertGridChildRender}
      </ExpertGrid>
    </div>
  )
}

export const getValidEmails = (expertEmails) => {
  const emails = Array.isArray(expertEmails.value) ? expertEmails.value : expertEmails.value.split(/\s*,\s*/)
  return emails.filter((email) => email && yup.string().email().isValidSync(email))
}

let titleComponent = null
if(Config.jobBuilderV3) {
  titleComponent = ({ title }) => (
    <Col>
      <h1>Step 2: Invite Your Team</h1>
      <p>Search for the expertise or experience you need below. Drag and drop your chosen Freelancers into the "Team Builder" or input email addresses of Freelancers or Employees you’d like to invite. To field a team of 4 you will want to invite 8-10 people, as some may not be available. Also, you will be able to invite an Idea Director to monitor your job and up to 3 Observers later when the Idea Stream is set up.</p>
    </Col>
  )
}
export const title = titleComponent

export function component ({ attributes, expertBudget, setNeedsFreelancerBudgets, ...props }) {
  // requirement change: remove budget from this page
  props.suppressBudget = true

  const [showHelp, setShowHelp] = useState(false)
  const [showExpert, setShowExpert] = useState(true);
  const [showTalent, setShowTalent] = useState(true);

  const [featuredLoading, setFeaturedLoading] = useState(true)
  const [featuredExperts, setFeaturedExperts] = useState([])

  const [searchLoading, setSearchLoading] = useState(false)
  const [useSearchResults, setUseSearchResults] = useState(false)
  const [searchedExperts, setSearchedExperts] = useState([])

  const [selectedExpert, setSelectedExpert] = useState()

  const [team, _setTeam] = useState([])
  const setTeam = (team) => {
    attributes.myTeamIds.set(team.map((member) => member.id))
    _setTeam(team)
  }
  const addToTeam = (expert) => {setTeam(team.concat([expert])), props.setInvitedTeam(team.concat([expert]))}
  const removeFromTeam = (expert) => {setTeam(team.filter((member) => member.id !== expert.id)), props.setInvitedTeam(team.filter((member) => member.id !== expert.id))}
  const teamIncludes = (expert) => !!team.find((member) => member.id === expert.id)

  const [{ isDragging, isDraggingOnTeamTarget }, teamDrop] = useDrop({
    accept: 'expert',
    drop: () => ({ team: true }),
    collect: monitor => ({
      isDraggingOnTeamTarget: monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
  })

  const [{ isDraggingTeam, isDraggingOnExpertTarget }, expertDrop] = useDrop({
    accept: 'team',
    drop: () => ({ team: false }),
    collect: monitor => ({
      isDraggingOnExpertTarget: monitor.isOver(),
      isDraggingTeam: monitor.canDrop(),
    }),
  })

  useEffect(() => {
    const fetchDefaultExperts = async () => {
      try {
        const response = await expertsResource.featured()
        setFeaturedExperts(response.data)
      } catch(error) {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      } finally {
        setFeaturedLoading(false)
      }
    }
    fetchDefaultExperts()
  }, [])

  useEffect(()=>{
    let budgetedFreelancers = team.filter((e)=>e.expert_type != 'employee')
    let freelancersInEmails = getValidEmails(attributes.myOwnFreelancerEmails)
    setNeedsFreelancerBudgets && setNeedsFreelancerBudgets((budgetedFreelancers.length + freelancersInEmails.length) > 0)
  },[team, attributes])

  let experts = useSearchResults ? searchedExperts : featuredExperts
  const alreadyInvitedExpertIds = props.alreadyInvitedExperts?.map(e => e.id) || []
  experts = experts.filter((e) => !alreadyInvitedExpertIds.includes(e.id))
  const loading = useSearchResults ? searchLoading : featuredLoading

  const expertBorder = (expert) => {
    if(teamIncludes(expert)) {
      return '2px solid #ED9B2F'
    } else {
      return '1px solid black'
    }
  }

  const expertGridRendered = useMemo(() => {
    return (<>
      {!loading &&
        <div ref={expertDrop} className={classnames(styles.dropZone, {[styles.activeDrag]: isDraggingTeam && !isDraggingOnExpertTarget, [styles.activeHover]: isDraggingOnExpertTarget})}>
          <ExpertGridOrHelp
            experts={experts}
            isSearching={useSearchResults}
            dragType="expert"
            selectedExpert={selectedExpert}
            setSelectedExpert={setSelectedExpert}
            borderFn={expertBorder}
            addToTeam={addToTeam}
            showExpert={showExpert}
            showTalent={showTalent}
          >
            {(expert) => (
              teamIncludes(expert)
                ?
              null
                :
                <div style={{position: 'absolute', bottom: 1, right: 5, cursor: 'pointer'}} onClick={() => addToTeam(expert)} >
                  <SvgPlusIcon style={{height: 14, width: 14}} />
                </div>
            )}
          </ExpertGridOrHelp>
        </div>
      }
    </>)
  }, [loading, team.length, experts.length, selectedExpert, showExpert, showTalent])

  return (
    <>
      {!props.suppressHeader && <>
        <h3 className={styles.step_heading}>
          {!showHelp && <SvgInfoIcon onClick={() => setShowHelp(true)} style={{float: 'right', cursor: 'pointer'}}/>}
          Invite Freelancers
        </h3>

        <InstructionBox
          show={showHelp} onHide={() => setShowHelp(false)}
          headline={'And now the most important step, choosing a team of Freelancers to invite to your project.'}>
          <p>To that end, use the Search field below to find people with the right kind of talent and/or experience for your job. Roll over the people in the search results to get a view of their respective "baseball card" showing you more detail on their experience. When you find someone interesting that you wish to invite, just {isBrowser ? <>drag their card over to the Team Builder. Everyone you drag</> : <>add them to the Team Builder. Everyone you add</>} into this space will get an invitation.</p>
          <p>And if the perfect person is not in our database yet, just type in their email address and we will invite them into the platform to work on your project. See? Anyone on earth can work on your project.</p>
          <p><div style={{display: 'inline', color: '#FFF104'}}>Tip:</div> over invite to make sure you can field a team of four, meaning perhaps invite 8-10 people realizing some may not be available or interested for whatever reason. And don't worry, if too many accept the job you can release those you don't need in a later step, leaving you with four perfect people for your job.</p>
        </InstructionBox>
      </>}

      <Row style={{marginTop: 60}}>
        <Col lg={{ span: 7, order: 1}} xs={{ span: 12, order: 1}}>
          <Row>
            <Col xs={12}>
              <div className={classnames(styles.slimBox, 'mb-3')}>
                <ExpertSearch setExperts={setSearchedExperts} setLoading={setSearchLoading} setSearching={setUseSearchResults} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={6} lg={12} md={6} sm={12} xs={12}>
                  <ExpertShortProfile expert={selectedExpert}>
                    {
                      !selectedExpert || teamIncludes(selectedExpert)
                        ?
                      null
                        :
                      <button type="button" className="btn btn-primary btn-mini" onClick={() => addToTeam(selectedExpert)}>
                        Add
                      </button>
                    }
                  </ExpertShortProfile>
                </Col>

                <Col xl={6} lg={12} md={6} sm={12} xs={12}>
                  <div className={styles.expert_box}>
                    <div style={{display: 'flex'}}>
                      <h3 style={{marginTop: 0, marginBottom: 7, width: 'calc(100% - 60px)'}}>
                        Freelancers&nbsp;
                        {!loading && <>({
                          Math.max(experts.length - team.length, 0)})</>}
                      </h3>
                    </div>
                    <div className={styles.checkboxContainer}>
                      <input id="expert_checkbox" type="checkbox" checked={showExpert} onChange={e => setShowExpert(e.target.checked)} />
                      <label htmlFor='expert_checkbox' style={{ marginRight: 12 }}>Experts</label>
                      <input id="talent_checkbox" type="checkbox" checked={showTalent} onChange={e => setShowTalent(e.target.checked)} />
                      <label htmlFor='talent_checkbox'>My Talent</label>
                    </div>
                    {expertGridRendered}
                    {loading && <Loading />}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col lg={{ span: 5, order: 2}} xs={{ span: 12, order: 1}} className="mb-3">
          {!props.suppressBudget && <Row>
            <Col xs={12}>
              <div className={classnames(styles.slimBox, 'mb-3')}>
                <span className={styles.slimBoxLabel} style={{float: 'right'}}>{formatCurrency(expertBudget)}</span>
                <span className={styles.slimBoxLabel}>My budget:</span>
              </div>
            </Col>
          </Row>}
          <Row>
            <Col xs={12}>
              <div style={{border: '1px solid black', padding: 10 }}>
                <div style={{float: 'right'}}>
                  <span style={{fontSize: 12, fontWeight: 'bold', marginRight: 5}}>
                    Invitations
                  </span>

                  <span style={{fontSize: 24, fontWeight: 'bold', background: 'rgb(255,240,53)', padding: 5, width: 32, height: 32, borderRadius: '50%', display: 'inline-block', lineHeight: '22px', textAlign: 'center', verticalAlign: 'middle'}}>
                    {Math.max(team.length + getValidEmails(attributes.myOwnFreelancerEmails).length + getValidEmails(attributes.myOwnEmployeeEmails).length, 0)}
                  </span>
                </div>
                <div style={{minWidth: 'calc(100% - 70px)'}}>
                  <h3 style={{marginTop: 0, marginBottom: 7, fontSize: 24}}>Team Builder</h3>
                </div>

                {getValidEmails(attributes.myOwnFreelancerEmails).length < 1 && <div className={styles.validationError} style={{textAlign: 'left'}}>
                  {attributes.myTeamIds.error.value}
                </div>}
                {getValidEmails(attributes.myOwnEmployeeEmails).length < 1 && <div className={styles.validationError} style={{textAlign: 'left'}}>
                  {attributes.myTeamIds.error.value}
                </div>}

                <ExpertDropTarget
                  drop={teamDrop}
                  team={team}
                  isDragging={isDragging} isDraggingOnTeamTarget={isDraggingOnTeamTarget}
                  hint={isBrowser ? <>Drag and drop freelancer cards here<br />to build your team.</> : <>Add Freelancers to<br />build your team.</>}
                  expertGridProps={{
                    dragType: "team",
                    selectedExpert: selectedExpert,
                    setSelectedExpert: setSelectedExpert,
                    removeFromTeam: removeFromTeam,
                    borderFn: expertBorder,
                    showTalent: true,
                    showExpert: true,
                  }}
                  expertGridChildRender={(expert) => (
                    <div style={{position: 'absolute', bottom: 2, right: 6, cursor: 'pointer'}} onClick={() => removeFromTeam(expert)} >
                      <SvgRemoveIcon style={{height: 12, width: 12}} />
                    </div>
                  )}
                />

                {!!props.alreadyInvitedExperts?.length && <>
                  <h3>Previously invited</h3>
                  <ExpertGrid
                    experts={props.alreadyInvitedExperts}
                    dragType="none"
                    borderFn={expertBorderExistingJob}
                    allowDrag={(expert) => false}
                    showExpert={true}
                    showTalent={true}
                  >
                    {() => <></>}
                  </ExpertGrid>
                </>}
                {/* invite freelancers */}
                <div style={{marginTop: 10, padding: 10, border: '1px solid'}}>
                  <h4 style={{marginTop: 0, marginBottom: 10}}>Invite my own Freelancers:</h4>
                  <textarea
                    className={styles.inviteMyOwnExpert}
                    placeholder="john.doe@gmail.com, jane.smith@home.com"
                    value={Array.isArray(attributes.myOwnFreelancerEmails.value) ? attributes.myOwnFreelancerEmails.value.join(', ') : attributes.myOwnFreelancerEmails.value}
                    {...attributes.myOwnFreelancerEmails.eventHandlers} />
                </div>
                {/* invite empoyees */}
                <div style={{marginTop: 10, padding: 10, border: '1px solid'}}>
                  <div style={{ marginBottom: 10 }}>
                    <h4 style={{margin: 0 }}>Invite my own Employees:</h4> <span style={{ fontSize: 12 }}>Note: employees are NOT paid through Ideasicle X</span>
                  </div>
                  <textarea
                    className={styles.inviteMyOwnExpert}
                    placeholder="john.doe@gmail.com, jane.smith@home.com"
                    value={Array.isArray(attributes.myOwnEmployeeEmails.value) ? attributes.myOwnEmployeeEmails.value.join(', ') : attributes.myOwnEmployeeEmails.value}
                    {...attributes.myOwnEmployeeEmails.eventHandlers} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ReturnToTop />
    </>
  )
}

export const validate = (attributes) => {
  let valid = true
  const validFreelancerEmails = getValidEmails(attributes.myOwnFreelancerEmails)
  const validEmployeeEmails = getValidEmails(attributes.myOwnEmployeeEmails)
  if ((attributes.myTeamIds.value.length + validFreelancerEmails.length + validEmployeeEmails.length) < 1) {
    attributes.myTeamIds.error.set('Please select at least one team member')
    valid = false
  }
  if(valid) {
    attributes.myOwnFreelancerEmails.data.value = validFreelancerEmails // discard text box invalid emails
    attributes.myOwnEmployeeEmails.data.value = validEmployeeEmails
    return true
  } else {
    return false
  }
}
