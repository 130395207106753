import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const expertsResource = resourceBuilder.build('/experts', {
  search: {
    url: '/search',
    method: 'get'
  },
  featured: {
    url: '/featured',
    method: 'get',
  },
  favorites: {
    url: '/favorites',
    method: 'get',
  },
  expertsWithEarnings: {
    method: 'get',
    url: '/experts_with_earnings'
  },
  ...defaultResourceSchema,
})

export default expertsResource
