const decimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const wholeNumberFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})

const formatCurrency = (value, includeDecimal = false) => {

  return includeDecimal ? decimalFormatter.format(value) : wholeNumberFormatter.format(Math.floor(value))
}

export {
  formatCurrency
}
