import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const ErrorPage = ({heading, children}) => {
  return (
    <Container style={{border: '1px solid black', padding: '100px 0 140px 0'}} className="mt-4"> 
      <center>
        <h2>{heading}</h2>
        {children}
      </center>
    </Container>
  )
}

export default ErrorPage
