import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 1393,
  },
  spinner: {
    position: 'absolute',
    top: `calc(50% - 20px)`,
    left: `calc(50% - 20px)`,
  },
});

const LoadingSpinner = () => {
  const styles = useStyles();
  return (
    <Box className={styles.wrapper}>
      <CircularProgress className={styles.spinner} />
    </Box>
  );
};

export default LoadingSpinner;
