import React, { useMemo, useCallback } from 'react'

const useSubscriptionMetadata = (organization) => {
  const subscription = organization?.subscription
  const interval = subscription?.price?.recurring?.interval // month or year

  const maxCommandCentersAllowed = useMemo(
    () => parseInt(subscription?.metadata?.NUMBER_OF_COMMAND_CENTERS) || null,
  [subscription?.metadata?.NUMBER_OF_COMMAND_CENTERS])

  const multipleCommandCentersAllowed = useMemo(
    () => organization && (!maxCommandCentersAllowed || maxCommandCentersAllowed > 1),
    [organization, maxCommandCentersAllowed]
  )

  const maxProjectsAllowed = useMemo(
    () => parseInt(subscription?.metadata?.NUMBER_OF_PROJECTS) || null,
  [subscription?.metadata?.NUMBER_OF_PROJECTS])

  const currentCommandCenters = organization?.users?.length

  const currentTier = useMemo(
    () => subscription?.plan?.tiers && subscription?.plan.tiers.find((tier, index) => {
      return !tier.up_to || tier.up_to >= currentCommandCenters
    })
  , [subscription?.plan, currentCommandCenters])

  const nextTier = useMemo(
    () => subscription?.plan && currentTier && subscription?.plan.tiers[subscription?.plan.tiers.indexOf(currentTier) + 1]
  , [subscription?.plan, currentTier])

  const commandCentersToUpgradeTier = useMemo(
    () => nextTier && (currentTier.up_to + 1 - currentCommandCenters)
  , [nextTier, currentTier, currentCommandCenters])

  const discount = subscription?.discount
  const activeDiscount = useMemo(() => {
    if(
      (discount?.end && subscription?.current_period_end && discount?.end > subscription?.current_period_end) ||
      (discount?.coupon?.duration == 'forever')
    ) {
      return discount.coupon
    }
  }, [discount])

  const discounter = useCallback((amount) => {
    if(activeDiscount) {
      return amount - (activeDiscount.amount_off ? activeDiscount.amount_off : amount * activeDiscount.percent_off / 100)
    }
    return amount
  }, [activeDiscount])

  const paymentInfoDescriptor = useMemo(
    () => `${subscription?.card?.brand} ending in ${subscription?.card?.last4}`
  , [subscription])

  return {
    subscription,
    interval,
    currentCommandCenters,
    currentTier,
    nextTier,
    commandCentersToUpgradeTier,
    multipleCommandCentersAllowed,
    maxCommandCentersAllowed,
    maxProjectsAllowed,
    activeDiscount,
    discounter,
    paymentInfoDescriptor
  }
}

export default useSubscriptionMetadata
