import React, {  } from 'react'
import styles from './styles.module.css'
import { Row, Col } from 'react-bootstrap'
import { SvgPlusIcon } from 'components/general/icons'
import classnames from 'classnames'
import ImageUploader from 'react-images-upload'
import { DirectUploadProvider } from 'react-activestorage-provider'
import ProgressBar from 'components/general/progress_bar'

function CustomerProfileEditor ({
  headerMessage,
  headerMessageLink,
  onAvatarUploadSuccess,
  setProfileAvatarSignedId,
  avatarUploader,
  onAvatarUpload,
  profileAvatarFileUrl,
  setProfileAvatarFileUrl,
  handleUpload,
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  email
}) {
  return (
    <div className={styles.box}>
      {headerMessage && <div className="info-alert">
        {headerMessage}
        {headerMessageLink && <>
          <br />
          <a href="mailto:willb@ideasicle.com">Email us with questions</a>
        </>}
      </div>}

      <div className={classnames(styles.row, 'row')}>
        <Col md={3} xs={12} style={{marginBottom: 50}}>
          <h2 className={styles.section_heading}>
            User Info
          </h2>
          <DirectUploadProvider directUploadsPath="/api/v1/direct_uploads" onSuccess={onAvatarUploadSuccess(setProfileAvatarSignedId)} render={({ handleUpload, uploads, ready }) => (
            <div className={classnames(styles.upload_wrapper, {[styles.upload_wrapper_disabled]: !ready})}
              onClick={() => { ready && avatarUploader.current.triggerFileUpload() }}>
              <div style={{ visibility: 'hidden', position: 'absolute' }}>
                <ImageUploader
                  ref={avatarUploader}
                  onChange={onAvatarUpload(setProfileAvatarFileUrl, handleUpload)}
                  singleImage={true}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={1024 * 1024 * 100}
                />
              </div>
              <SvgPlusIcon className={styles.upload_button}/>
              {!profileAvatarFileUrl && <div className={styles.upload_box}>
                Avatar
              </div>}
              {profileAvatarFileUrl && <img src={profileAvatarFileUrl} style={{ maxHeight: '100%', maxWidth: '100%', opacity: ready ? 1 : 0.3 }} />}
              {uploads.map(upload => {
                if(upload.state === 'waiting' || upload.state === 'uploading') {
                  return (<ProgressBar width={100} height={8} percentage={upload.progress} className={styles.uploadProgressBar} />)
                }
              })}
            </div>
          )} />
        </Col>
        <Col md={9} xs={12}>
          <Row>
            <Col lg={6} xs={12}>
              <div style={{position: 'relative'}}>
                {touched.firstName && errors.firstName && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                  {errors.firstName}
                </span>}
                <input
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="First name"
                  className={styles.input}
                  tabIndex={1}
                />
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div style={{position: 'relative'}}>
                {touched.lastName && errors.lastName && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                  {errors.lastName}
                </span>}
                <input
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Last name"
                  className={styles.input}
                  tabIndex={2}
                />
              </div>
            </Col>
            {email && <Col lg={6} xs={12}>
              <div style={{position: 'relative'}}>
                {touched.email && errors.email && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                  {errors.email}
                </span>}
                <input
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                  className={styles.input}
                  tabIndex={3}
                />
              </div>
            </Col>}
          </Row>
        </Col>
      </div>

      <div className={classnames(styles.row, 'row')}>
        <Col md={3} xs={12}>
          <h2 className={styles.section_heading}>
            Password
          </h2>
        </Col>
        <Col md={9} xs={12}>
          <Row>
            <Col xs={6}>
              <div style={{position: 'relative'}}>
                  {touched.password && errors.password && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                    {errors.password}
                  </span>}
                <input
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter password"
                  className={styles.input}
                />
              </div>
              <div style={{position: 'relative'}}>
                {touched.passwordConfirmation && errors.passwordConfirmation && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                  {errors.passwordConfirmation}
                </span>}
                <input
                  name="passwordConfirmation"
                  type="password"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Confirm password"
                  className={styles.input}
                />
              </div>
            </Col>
            <Col xs={6}>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  )
}

export default CustomerProfileEditor
