import React, { Fragment } from 'react'
import logoSrc from './logo.png'
import classnames from 'classnames'

const Logo = ({ logoClass }) => (
  <Fragment>
    <img src={logoSrc} alt="Ideasicle X" className={classnames([logoClass, "logo"])} />
  </Fragment>
)

export default Logo
