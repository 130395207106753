import React, { useState, useLayoutEffect } from 'react'
import jobShowStyles from './styles.module.css'
import classnames from 'classnames'
import * as routes from 'constants/routes'
import moment from 'moment'
import { Container, Row, Col, Collapse } from 'react-bootstrap'
import VideoPlayer from 'react-video-js-player';
import MiniHeader from 'components/general/mini_header'
import Modal from 'react-bootstrap/Modal'
import Button from '../../general/button'
import Icon from '@mdi/react';
import { mdiDragVariant, mdiOpenInNew } from '@mdi/js';
import Loading from 'components/general/loading'
import { detect } from 'detect-browser';
const browser = detect();
import { SvgWarningIcon } from 'components/general/icons/index.jsx'

const styles ={
  row: {
    paddingBottom: 17
  },
  docPreview: {
    width: '100%',
    height: 'auto'
  },
  newWindowButton: {
    position: 'relative',
    right: 20
  }
}

export default function Briefing ({job, ...props}) {
  const [modalOpen, _setModalOpen] = useState(false)
  const [objLoaded, setObjLoaded] = useState(false)
  const [objLoadTimedout, setObjLoadTimedout] = useState(false)
  const [objLoadFailed, setObjLoadFailed] = useState(false)

  const setModalOpen = (value) => {
    setObjLoaded(false)
    setObjLoadTimedout(false)
    setObjLoadFailed(false)
    _setModalOpen(value)
    if(value) {
      setTimeout(() => {
        // adapted frfom gijgo gj.dialog.methods.draggable
        const $dialog = $('.modal-content')
        const $header = $('.modal-header')
        $header.addClass('gj-draggable');
        $dialog.draggable({
            handle: $header,
            start: function () {
                $dialog.addClass('gj-unselectable');
                gj.dialog.events.dragStart($dialog);
            },
            stop: function () {
                $dialog.removeClass('gj-unselectable');
                gj.dialog.events.dragStop($dialog);
            }
        });
      })
    }
  }

  const OBJ_LOAD_TIMEOUT = 1000

  useLayoutEffect(() => {
    if(modalOpen && !objLoadFailed) {
      setObjLoadTimedout(false)
      setTimeout(() => {
        setObjLoadTimedout(true)
        const obj = document.querySelector('#brief_obj')
        if(obj && obj.offsetWidth === 0) {
          setObjLoadFailed(true)
        }
      }, OBJ_LOAD_TIMEOUT)
    }
  }, [modalOpen, objLoadFailed])

  const openBriefDoc = () => {
    if(window.matchMedia("(min-width: 768px)").matches) {
      setModalOpen(true)
    } else {
      open(job.briefing_document_url)
    }
  }

  const firefox = browser && browser.name === 'firefox'

  return (
    <>
      <Row style={styles.row} className="print-hide">
        <Col md={9} xs={12} style={{maxHeight: 500}} className="print-hide mb-2">
          <VideoPlayer
            controls={true}
            width={468}
            src={job.briefing_video_url}
            poster={job.briefing_video_preview_url}
            hideControls={['playbackrates']}
          />
        </Col>
        <Col md={3} xs={12} className={`${jobShowStyles.rightColBox} print-hide`} style={{marginBottom: 0, maxHeight: 500}}>
          <MiniHeader text="Brief" noBorder={true} />
          <div style={styles.docPreview} onClick={openBriefDoc}>
            <img src={job.briefing_document_preview_url} style={styles.docPreview} />
          </div>
        </Col>
      </Row>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={false}
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        dialogClassName="briefDialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{width: '100%'}}>
            <Icon path={mdiDragVariant} style={{marginRight: 20, height: 18, top: 3, position: 'relative'}} />
            Brief
            <button type="button" class="close" style={styles.newWindowButton} onClick={() => { open(job.briefing_document_url); setModalOpen(false); }}>
              <Icon path={mdiOpenInNew} style={{height: 18, top: 2, position: 'relative'}} />
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: 0, marginBottom: -5}}>
          { !objLoadFailed && <object id="brief_obj" data={job.briefing_document_url} onLoad={() => setObjLoaded(true)} type={job.briefing_document_content_type} style={{width: '100%', minHeight: 500, height: '100%'}}>
            <embed src={job.briefing_document_url} onLoad={() => setObjLoaded(true)} type={job.briefing_document_content_type} style={{width: '100%', minHeight: 500, height: '100%'}} />
          </object>}
          { objLoadFailed && objLoadTimedout && <Row style={{marginTop: 40}}>
            <Col xs={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
              <SvgWarningIcon />
            </Col>
            <Col xs={8}>
              <h2 style={{marginTop: 0}}>Unable to display Brief</h2>
              {firefox && <div>It looks like your Firefox settings preventing you from viewing PDF files. To correct this:
                <ol>
                  <li>Open Firefox <em>Preferences</em></li>
                  <li>Find the <em>Applications</em> section under <em>General</em></li>
                  <li>Change the <em>Portable Document Format (PDF)</em> setting to <em>Preview in Firefox</em></li>
                  <li><a href="#" onClick={() => setObjLoadFailed(false)}>Refresh the Brief window</a></li>
                </ol>
                Alternatively, you may <a href={job.briefing_document_url} target="_blank" >download the Brief directly</a>
                </div>}
              {!firefox && <div>Check your browser settings or <a href={job.briefing_document_url} target="_blank" >download the Brief directly</a></div>}
            </Col>
          </Row> }
          { !objLoaded && !objLoadTimedout && <div style={{position: 'absolute', top: 100, zIndex: 1, width: '100%'}} ><Loading /></div>}
        </Modal.Body>
      </Modal>
    </>
  )
}
