import React, { useState, useContext, useEffect, useMemo } from 'react';
import Store from 'store';
import { Container, Row, Col } from 'react-bootstrap';
import organizationsResource from 'resources/organizations';
import masterCommandCentersResource from 'resources/master_command_centers';
import Loading from '../../../components/general/loading';
import { toast } from 'react-toastify';
import ideaStyles from '../../jobs/idea/styles.module.css';
import styles from './styles.module.css';
import IxLogo from '../icons/IX-logo.png';
import moment from 'moment';
import { useNewCardInfoModal } from '../payments/new_card_info_modal'
import Config from 'utils/config'
import { useArchiveCommandCenterModal } from './archive_modal'
import AddCommandCentersTile from './add_command_centers_tile'
import ErrorPage from 'components/general/error_page'
import useSubscriptionMetadata from 'utils/useSubscriptionMetadata'
import BackArrowNav from 'components/general/back_arrow_nav'
import { Link } from 'react-router-dom'
import { MANAGE_ORGANIZATION } from 'constants/routes'
import queryString from 'query-string'
import useMasterCommandCenterLink from 'utils/useMasterCommandCenterLink'
import { currentUser as currentUserStore } from 'utils/local_storage'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import DuplicateOrganizationModal from 'components/general/organization/duplicate_organization_modal'
import useAuthenticationRequired from 'utils/useAuthenticationRequired'

export default function MasterCommandCenter({ history, location }) {
  useAuthenticationRequired()

  const [loading, setLoading] = useState(true)
  const [organization, setOrganization] = useState()

  const { state, dispatch } = useContext(Store)
  const { currentUser } = state
  const { multipleCommandCentersAllowed } = useSubscriptionMetadata(organization)

  const parsedQuery = useMemo(() => queryString.parse(location.search), [])
  const { upgrade } = parsedQuery

  const upgradeToMasterCommandCenter = async () => {
    try {
      const response = await masterCommandCentersResource.create()
      currentUserStore.set(response.data.user)
      dispatch({type: CURRENT_USER_FETCHED, payload: response.data.user})
      setOrganization(response.data.organization)
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! Something went wrong. Please try again shortly.")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await organizationsResource.showCurrent()
        setOrganization(response.data)
      } catch(error) {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      } finally {
        setLoading(false)
      }
    }

    if(currentUser && currentUser['is_master_command_center?']) {
      fetchOrganization()
    } else if(currentUser && currentUser['is_organization_owner?'] && upgrade) {
      upgradeToMasterCommandCenter()
      // trial customer needs to see the error message
      // condition should match condition for ErrorPage below
    } else if (currentUser?.subscription_metadata?.isTrial()) {
      setLoading(false)
      // non-owner should see Duplicate Org modal when trying to upgrade
      // condition should match condition for ErrorPage below
    } else if(currentUser && upgrade && !currentUser['is_organization_owner?'] && currentUser.organization) {
      setLoading(false)
    }
  }, [currentUser && currentUser['is_master_command_center?'], upgrade])

  return (
    <Container>
      <BackArrowNav to="/" toLabel="Dashboard" />
      <Page
        loading={loading}
        setLoading={setLoading}
        multipleCommandCentersAllowed={multipleCommandCentersAllowed}
        upgrade={upgrade}
        currentUser={currentUser}
        organization={organization}
        setOrganization={setOrganization}
      />
    </Container>
  );
};

// Simple control structure for which page state to show
// Wrapped by common elements in the parent component
const Page = ({ loading, setLoading, multipleCommandCentersAllowed, upgrade, currentUser, organization, setOrganization }) => {
  const { ExternalLinkComponent } = useMasterCommandCenterLink()

  // all code below needs organization to continue
  if (loading) {
    return <Loading />
  }

  if(currentUser.subscription_metadata.isTrial()) {
    return <ErrorPage heading="Subscribe">
      Before you can activate your Master Command Center, you'll need to <Link to={MANAGE_ORGANIZATION}>become a subscriber</Link>.
    </ErrorPage>
  }

  if(!upgrade && !currentUser['is_master_command_center?']) {
    return <ErrorPage heading="Upgrade to Master Command Center">
      You need to activate your Master Command Center to access this page. <ExternalLinkComponent>Read more here</ExternalLinkComponent>.
    </ErrorPage>
  }

  // upgrade for organization owner is automatic
  // special case of upgrade for non-org owners: need to fill out a new org name
  // and payment info

  // organization will be null if user not logged in; handled by useAuthenticationRequired
  if(upgrade && !currentUser['is_organization_owner?'] && currentUser.organization) {
    return <DuplicateOrganizationModal
      show={true}
      organization={currentUser.organization}
      onCompleted={({ organization }) => { setOrganization(organization); setLoading(false) }}
    />
  }

  return (<>
    <Row className={styles.hero} style={{ margin: 0}}>
        <Col md={12} xs={12}>
            <p className={styles.header}>
                {organization.name}
            </p>
        </Col>
    </Row>
    <MainCommandCenter organization={organization} setOrganization={setOrganization} />
  </>)
}

const dateFormat = (rawValue) => {
  const date = moment(rawValue)
  return date.format("MMMM Do, YYYY")
}

const currencyFormat = (user) => {
  return ((user?.total_spent || 0) / 100).toLocaleString("en-US", {style:"currency", currency:"USD", maximumFractionDigits: 0})
}

const MainCommandCenter = ({ organization, setOrganization }) =>{
    const { openModal: openCreditCardModal, ModalComponent: CreditCardModal } = useNewCardInfoModal({
      onCompleted: ({ organization }) => { setOrganization(organization) }
    })
    const isChangingTeamState = useState(false)

    const { paymentInfoDescriptor } = useSubscriptionMetadata(organization)

    const ownerUser = organization.users.find(u => u.is_owner)

    return(
        <div>
            <div style={{ padding: 20, border: '2px solid black', borderTop: 0, paddingBottom: 10, marginBottom: 25 }}>
                <Row style={{ paddingBottom: 12, borderBottom: '2px solid black', margin: 0, display: 'flex', alignItems: 'center' }}>
                    <img src={IxLogo} className={styles.MccLogo} />
                    <h2 className={styles.masterCommandHeader}>Master Command Center</h2>
                </Row>
                <Row style={{ margin: 0 }}>
                    <Col lg={6} md={12} className={styles.masterCommandTable}>
                        <table style={{ width: '100%', paddingTop: 26, fontSize: 18 }}>
                            <tbody>

                                <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Owner</td>
                                    <td className={styles.tableData}>{ownerUser?.first_name} {ownerUser?.last_name}</td>
                                </tr>
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Established</td>
                                    <td className={styles.tableData}>{dateFormat(ownerUser?.created_at)}</td>
                                </tr>
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Active Command Centers</td>
                                    <td className={styles.tableData}>{organization?.subscription?.quantity}</td>
                                </tr>
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Completed Projects</td>
                                    <td className={styles.tableData}>{ownerUser?.completed_projects}</td>
                                </tr>
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Currently Live</td>
                                    <td className={styles.tableData} style={{ paddingLeft: 0 }}>
                                        <div className={styles.colorHighlight}>
                                            {ownerUser?.active_projects}
                                        </div>
                                    </td>
                                </tr>
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Total Ideas Posted</td>
                                    <td className={styles.tableData}>{ownerUser?.total_ideas}</td>
                                </tr>
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Freelancer Spent YTD</td>
                                    <td className={styles.tableData}>{currencyFormat(ownerUser)}</td>
                                </tr>
                                {organization?.subscription?.card && <tr className={styles.tableRow}>
                                    <td className={styles.tableHeader}>Account</td>
                                    <td className={styles.tableData}>{paymentInfoDescriptor}</td>
                                </tr>}
                            </tbody>
                        </table>
                        <div>
                            <span style={{ margin: 0, fontSize: 18, fontWeight: 'bold', paddingLeft: 1 }}>Actions:</span>
                        </div>
                        <div className={styles.masterActionBtns}>
                            { Config.masterCommandCenter?.changeOwner && <button className={styles.actionBtn}>Change Owner</button>}
                            <button className={styles.actionBtn} onClick={openCreditCardModal}>Update Billing Info</button>
                            <CreditCardModal />
                            <a href="mailto:inspiration@ideasiclex.com" className={styles.actionBtn}>Contact Ideasicle X</a>
                        </div>
                    </Col>
                    <Col lg={6} md={12} style={{ backgroundColor: '#F5F5F5', padding: '7.5px 20px 0', marginTop: 10, display: 'flex' }}>
                      <AddCommandCentersTile
                        organization={organization}
                        onAddUsers={({ organization }) => setOrganization(organization)}
                        isChangingTeamState={isChangingTeamState}
                      />
                    </Col>
                </Row>
            </div>
            <SecondaryCommandCenters
              organization={organization}
              setOrganization={setOrganization}
              isChangingTeamState={isChangingTeamState}
            />
        </div>
    )
}

const SecondaryCommandCenters = ({ organization, setOrganization, isChangingTeamState }) =>{
  const users = organization?.users?.filter(u => !u.is_owner) || []



  const [isChangingTeam, setIsChangingTeam] = isChangingTeamState
  const { openModal: openArchiveModal, ModalComponent: ArchiveModal } = useArchiveCommandCenterModal({
    organization,
    setIsSubmitting: setIsChangingTeam,
    onCompleted: ({ organization }) => { setOrganization(organization) }
  })

    return(
        <Row style={{ width: '100%', margin: 0, justifyContent: 'space-between' }}>
            {users.map((user, i)=>{
                return(
                    <Col key={i} xs={12} sm={12} md={6} className={styles.secondaryCommandCenter}>
                        <Row style={{ paddingBottom: 14, borderBottom: '2px solid black', margin: 0, display: 'flex', alignItems: 'center' }}>
                            <div style={{ backgroundColor: '#FBFB7F', padding: 8, marginRight: 8 }}>
                                <div className={styles.numberCircle}>{i + 1}</div>
                            </div>
                            <h2 className={styles.masterCommandHeader}>Command Center</h2>
                        </Row>
                        <div>
                            <table style={{ width: '100%', paddingTop: 26, fontSize: 18 }}>
                                <tbody>
                                    <tr className={styles.tableRow}>
                                        <td className={styles.tableHeader}>Owner</td>
                                        <td className={styles.tableData}>{user.fullName}</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <td className={styles.tableHeader}>Established</td>
                                        <td className={styles.tableData}>{dateFormat(user.created_at)}</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <td className={styles.tableHeader}>Completed Projects</td>
                                        <td className={styles.tableData}>{user.completed_projects}</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <td className={styles.tableHeader}>Currently Live</td>
                                        <td className={styles.tableData} style={{ paddingLeft: 0 }}>
                                            <div className={styles.colorHighlight}>
                                                {user.active_projects}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <td className={styles.tableHeader}>Total Ideas Posted</td>
                                        <td className={styles.tableData}>{user.total_ideas}</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <td className={styles.tableHeader}>Freelancer Spent YTD</td>
                                        <td className={styles.tableData}>{currencyFormat(user)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                <span style={{ margin: 0, fontSize: 18, fontWeight: 'bold', paddingLeft: 1 }}>Actions:</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: 22 }}>
                                { Config.masterCommandCenter?.viewArchivedProjects && <button className={styles.actionBtn}>View Archived Projects</button>}
                                { Config.masterCommandCenter?.changeOwner && <button className={styles.actionBtn}>Change Owner</button>}
                                <button
                                  disabled={isChangingTeam}
                                  onClick={() => openArchiveModal(user)}
                                  className={styles.actionBtn}>
                                  Archive
                                </button>
                            </div>
                        </div>
                    </Col>
                )
            })}
            <ArchiveModal />
        </Row>
    )
}
