import React, { useState, useCallback, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'components/general/button'
import organizationsResource from 'resources/organizations'
import { toast } from 'react-toastify'
import useSubscriptionMetadata from 'utils/useSubscriptionMetadata'
import { formatCurrency } from 'utils/formatters'
import { MANAGE_ORGANIZATION } from 'constants/routes'
import Discount from 'components/general/discount'
import { onlyValidEmails } from './add_command_centers_tile'

function InviteConfirmationModal({ organization, emails, onConfirm, show, onClose }) {
  const {
    interval,
    currentTier,
    nextTier,
    currentCommandCenters,
    commandCentersToUpgradeTier,
    discounter
  } = useSubscriptionMetadata(organization)

  const [submitting, setSubmitting] = useState(false)

  const submit = async () => {
    setSubmitting(true)
    try {
      await onConfirm()
    } finally {
      setSubmitting(false)
    }
  }

  if(!emails) {
    return null
  }

  const validEmails = onlyValidEmails(emails)

  if(!validEmails.length || !organization) {
    return null
  }

  const newTotalCommandCenters = currentCommandCenters + validEmails.length
  // use pricing in next tier, if one exists and if the new CCs will bump into that tier
  const billingTier = validEmails.length >= commandCentersToUpgradeTier && nextTier ? nextTier : currentTier
  // calculate amount using tiered or fixed unit amount billing
  const newAmount = newTotalCommandCenters * (billingTier ? billingTier.unit_amount : organization.subscription.plan.amount) / 100.0
  const discountedAmount = discounter(newAmount)

  return (
    <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={onClose}
    >
        <Modal.Header closeButton >
        <Modal.Title>Confirm Command Centers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h2>Please confirm subscription details</h2>
            <p>You are adding {validEmails.length} Commands Centers which will use your billing information. Each of the following email addresses will be invited to activiate their Command Center:</p>
            <ul>
              {validEmails.map(e => <li>{e}</li>)}
            </ul>
            <p>
              When you click submit, your subscription cost will increase to <Discount
                formatter={formatCurrency}
                amount={newAmount}
                discountedAmount={discountedAmount}
              /> per {interval}. You will be charged a prorated amount for the current billing period.
              See <a href={MANAGE_ORGANIZATION} target="_blank">Account Status</a> for more information.</p>
        </Modal.Body>
        <Modal.Footer>
        <Button disabled={submitting}  onClick={onClose}>
            Cancel
        </Button>
        <Button disabled={submitting} className="success" onClick={submit}>
            Submit
        </Button>
        </Modal.Footer>
    </Modal>
  )
}

export const useInviteConfirmationModal = ({
  organization,
  onConfirm = async ({ emails }, formik) => {},
}) => {
  const [emails, setEmails] = useState()
  const [formik, setFormik] = useState()
  const [showModal, setShowModal] = useState(false)

  const closeModal = () => setShowModal(false)
  const openModal = ({ emails }, formik) => {
    setEmails(emails)
    setFormik(formik)
    setShowModal(true)
  }

  const ModalComponent = useCallback(() => {
    return <InviteConfirmationModal
      show={showModal}
      onClose={closeModal}
      // bind formik result here so the modal doesn't need to know about it
      onConfirm={async () => { await onConfirm({ emails }, formik) }}
      emails={emails}
      organization={organization}
    />
  }, [showModal])

  return { openModal, closeModal, ModalComponent }
}

export default InviteConfirmationModal
