import BaseModel from './base'
import { createSubscriptionHandlingFees } from './fees'

export default class SubscriptionMetadata extends BaseModel {
  isTrial() {
    // Use this flag as a proxy to indicate trial
    // We may need a real flag in subscription metadata later
    return this.CANCEL_SUBSCRIPTION_AFTER_PERIOD?.toLowerCase() === 'true'
  }

  isEnterpriseBilling() {
    return !!this.enterprise_billing;
  }

  additionalFeePct() {
    if(this.commission?.percent_off) {
      return createSubscriptionHandlingFees(this.commission.percent_off)
    }

    return createSubscriptionHandlingFees()
  }
}
