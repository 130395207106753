import BaseModel from './base'
import SubscriptionMetadataMixins from 'models/mixins/subscription_metadata'

export default class Organization extends BaseModel {
  onInititalize() {
    if(this.subscription?.metadata) {
      // TODO: replace this with __type attribute on server so
      // we can autoTypeCast as well as serialize it
      // mixins cause hard-to-debug failures
      Object.assign(this.subscription.metadata, SubscriptionMetadataMixins)
    }
    if(this.offer_plans?.metadata) {
      this.offer_plans.forEach(plan => {
        // TODO: replace this with __type attribute on server so
        // we can autoTypeCast as well as serialize it
        // mixins cause hard-to-debug failures
        Object.assign(plan.metadata, SubscriptionMetadataMixins)
      })
    }
  }
}
