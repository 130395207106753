import React, { Fragment, useState, useContext, useMemo } from 'react'
import DiscountedAmount from './discounted_amount.jsx'

const Discount = ({
  formatter, // required, use renderPct or formatCurrency
  amount, // required, will be formatted
  discountedAmount, discount, // discountedAmount OR discount stripe object is is required.
  discountColor // optional, will default to green
}) => {
  const _discountedAmount = useMemo(() => {
    if(typeof(discountedAmount) === 'number') {
      return discountedAmount
    }
    else if(discount?.percent_off) {
      return amount - (amount * discount.percent_off / 100)
    } else if (discount?.amount_off) {
      return amount - discount.amount_off
    }
  }, [amount, discountedAmount, discount])

  const renderedAmount = useMemo(() => formatter(amount), [amount])

  const renderedDiscountedAmount = useMemo(() =>
    typeof(_discountedAmount) === 'number' && formatter(_discountedAmount)
  , [_discountedAmount])

  return (
    <DiscountedAmount amount={renderedAmount} discountedAmount={renderedDiscountedAmount} discountColor={discountColor} />
  )
}

export default Discount
