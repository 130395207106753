import React, { useState, useContext, useCallback } from 'react'
import subscriptionsResource from 'resources/subscriptions'
import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/formatters'
import Discount from 'components/general/discount'
import classNames from 'classnames'
import Button from 'components/general/button'
import budgetModalStyles from 'components/jobs/create/styles.module.css'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useNewCardInfoModal } from '../payments/new_card_info_modal'
import Store from 'store'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { currentUser } from 'utils/local_storage'
import usersResource from 'resources/users'

function SubscribeModal({
  show,
  onClose,
  isSubmittingState,
  onSubscribeComplete,
  plans,
  currentSubscription,
}) {
  const { dispatch, state } = useContext(Store)
  const [submitting, setSubmitting] = isSubmittingState
  const [showChangeCreditCard, setShowChangeCreditCard] = useState(false)

  const { openModal: _openCreditCardModal, ModalComponent: CreditCardModal } = useNewCardInfoModal({
    onCompleted: ({ organization }) => { setShowChangeCreditCard(false) },
    onClose: () => { setShowChangeCreditCard(false) }
  })

  const openCreditCardModal = () => {
    setShowChangeCreditCard(true)
    _openCreditCardModal()
  }

  const performUpgrade = useCallback(async (plan) => {
    setSubmitting(true)
    try {
      const response = await subscriptionsResource.upgrade({ params: { plan_id: plan.lookup_key } })

      const currentUserResponse = await usersResource.showCurrent()
      dispatch({type: CURRENT_USER_FETCHED, payload: currentUserResponse.data})
      currentUser.set(currentUserResponse.data)

      onSubscribeComplete(response.data)
      toast.success(
        `You have subscribed to the ${plan.nickname}. You now have full access to Ideasicle X`,
        { autoClose: 15000 }
      )
    } catch (e) {
      if(e?.response?.data?.error) {
        toast.warn(e?.response?.data?.error)
      } else {
        console.error(e?.response?.data)
        toast.warn(`Uh oh! We weren't able to subscribe you to the ${plan.nickname}. Please try again soon.`)
      }
    } finally {
      setSubmitting(false)
    }
  }, [])


const GrayRow  = ({ children }) => <div className={budgetModalStyles.fixedBudget}>{children}</div>
const WhiteRow = ({ children }) => <div style={{padding: 20}}>{children}</div>

const MCCWrapper = plans.length % 2 == 0 ? GrayRow : WhiteRow

  return (
    <>
      <CreditCardModal />
      <Modal
        show={show && !showChangeCreditCard}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-75w"
        style={{border: '2px solid black' }}
      >
        <Modal.Header closeButton={!submitting}>
        <Modal.Title>Subscribe to Ideasicle X</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <WhiteRow>
            {currentSubscription?.metadata?.isTrial() && <>Welcome to the future of idea-generation! </>}
            {plans.length > 1 && <>Choose your plan below.
              {currentSubscription?.metadata?.isTrial() && <> Either plan will give you one Command Center with full functionality, but a discount if you subscribe annually. </>}
            </>}
            When you click "Subscribe" the credit card on file will be charged the amount shown. <a href="#" onClick={openCreditCardModal}>Click here</a> to update your credit card.
          </WhiteRow>
          {plans.map((plan, index) => {
            const Wrapper = index % 2 == 0 ? GrayRow : WhiteRow

            return (
              <Wrapper key={index}>
                <h3 className={classNames([budgetModalStyles.setBudgetHeader, 'mt-0'])}>{plan.nickname}</h3>
                <div className={budgetModalStyles.setBudgetText}>
                  {plan.recurring.interval === 'month' && <>30 days<br /></>}
                  {plan.recurring.interval === 'year' && <>365 days<br /></>}

                  One Command Center<br />

                  Unlimited idea projects {plan.recurring.interval === 'year' && <>per month</>}<br />

                  {!plan.discount && <strong>{formatCurrency(plan.unit_amount / 100)}/{plan.recurring.interval}</strong>}
                  {plan.discount && <>
                    <strong>
                      {formatCurrency((plan.unit_amount - plan.discount.amount_off) /100)} for the first {plan.recurring.interval}
                    </strong><br />
                    {formatCurrency(plan.unit_amount / 100)}/{plan.recurring.interval} after that
                  </>}<br />

                  Does not include payments to freelancers, if any
                  <div style={{marginTop: 10, textAlign: 'right'}}>
                    <button disabled={submitting} className="btn btn-primary btn-slim" style={{width: 200}} type="button" onClick={() => performUpgrade(plan)}>
                      Subscribe
                    </button>
                  </div>
                </div>
              </Wrapper>
            )
          })}
          <MCCWrapper>
            <h3 className={classNames([budgetModalStyles.setBudgetHeader, 'mt-0'])}>Master Command Center</h3>
            The Master Command Center is our enterprise solution. Once you have an Annual Plan set up you will be able to upgrade your Command Center (free) to a Master Command Center, which gives you more organizational features:
            <ul>
              <li>Invite others within your organization to set up their own Command Centers</li>
              <li>Monitor all idea activity including spending on freelancers</li>
              <li>Consolidate all on one bill. </li>
              <li>Once your Command Center is set up you can upgrade from the "hamburger" menu at the top of your dashboard.</li>
            </ul>
          </MCCWrapper>
        </Modal.Body>
      </Modal>
    </>
  )
}

export const useSubscribeModal = ({
  plans,
  currentSubscription,
  onSubscribeComplete = async (organziation) => {},
}) => {
  const [showModal, setShowModal] = useState(false)
  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  const ModalComponent = useCallback(({ isSubmittingState }) => {
    return <SubscribeModal
      show={showModal}
      onClose={closeModal}
      isSubmittingState={isSubmittingState}
      onSubscribeComplete={onSubscribeComplete}
      plans={plans}
      currentSubscription={currentSubscription}
    />
  }, [showModal])

  return {
    openModal,
    closeModal,
    ModalComponent
  }
}

export default SubscribeModal
