export default (classNames) => (
  classNames.filter((className) => className).join(' ')
)

export const mergeStyleModules = (...styleModules) => {
  return styleModules.reduce(
    (styleModule, styles) => {
      Object.keys(styleModule).forEach((key) => {
        styles[key] = styles[key] ? `${styles[key]} ${styleModule[key]}` : styleModule[key]
      })
      return styles
    }
    , {}
  )
}
