// FIXME: blockRenderers always overrides inlineStyleFn (maybe because it's the first?).
//  FIX: https://github.com/sstur/draft-js-utils/pull/174
//
// https://github.com/sstur/draft-js-utils/issues/120
import { stateToHTML } from 'draft-js-export-html'
import { ALIGNMENT_DATA_KEY, ALIGNMENTS } from './extended-rich-utils'
import { exporter } from './customStyles'

const alignmentsToBeSaved = Object.values(ALIGNMENTS).filter(alignment => alignment !== 'left')
const customColorKey = 'CUSTOM_COLOR_rgba'

const options = {
  // TODO: Can we put this into the exporter?
  // Export alignment (unless it's "left").
  blockRenderers: {
    unstyled: (block) => {
      const data = block.getData()
      const alignment = data.get(ALIGNMENT_DATA_KEY)

      if (alignmentsToBeSaved.includes(alignment)) {
        return `<p style="text-align: ${alignment}">${block.getText()}</p>`
      }
    },
  },

  entityStyleFn: (entity) => {
    const entityType = entity.get('type').toLowerCase();
    if(entityType == 'draft-js-video-plugin-video') {
      const data = entity.getData();
      return {
        element: 'draft-js-video-plugin-video',
        attributes: {
          src: data.src
        }
      }
    }
  },
}


export default (editorState) => {
  const inlineStyles = exporter(editorState)
  return stateToHTML(editorState.getCurrentContent(), Object.assign({}, options, {inlineStyles}))
}
