import { useState } from 'react'

class AttributeError {
  constructor(data) {
    this.data = data
  }

  get value()  {
    return this.data.value
  }

  set(value) {
    this.data.setter(value)
  }
}

class Attribute {
  constructor(data) {
    this.data = data
  }

  get label() {
    return this.data.label
  }

  get value() {
    return this.data.value
  }

  get defaultValue() {
    return this.data.defaultValue
  }

  get error() {
    return this.data.error
  }

  set(value) {
    this.data.setter(value)
  }

  // Here you might be wondering why the hell not to simply use an instance method such as:
  // eventHandler(event) { ... }
  //
  // The reason is that if we do onClick={attribute.eventHandler}, `this` within the method
  // body is undefined.
  //
  // So this is essentially a way to do fn.bind(this).
  get changeEventHandler() {
    return (event) => {
      event.persist()
      const fn = this.data.converters.change || ((value) => value)
      this.set(fn(event.target.value))
    }
  }

  /*
    = Why we cannot rely just on the change event?

    In short, it's because of unfinished input.

    Say you want to have an input that is represented by an array value.

    const handleChange = (event) => {
      setEmails(event.target.value.split(/,\s+/).filter(email => email))
    }

    <input value={emails.join(', ')} onChange={handleChange} />

    So you're starting to type an email. All good. You're typing, typing and now you're
    done and you want to add another email address. You press comma ... nothing ...
    comma ... nothing ... aaaand that's it really.

    Since the function runs on every change, you're calling "joe@doe.com,".split(/,\s+),
    you get "joe@doe.com" and an empty string that the filter function filters out and
    the comma disappears.

    Before you think "well, why do we need the filter function then", think twice.

    If you do that, you can never delete the comma, therefore you cannot delete anything
    (unless you select and delete).

    And so for that we introduced the blur hook to sanitize and convert to value afterwards.

    Here is a functioning example:

    attributes.emails = useAttribute('emails', [], {
      focus: (value) => value.join(', '),
      blur: (rawValue) => rawValue.split(/,\s+/).filter((email) => email)
    })

    <textarea
      value={attributes.emails.value}
      {...attributes.emails.eventHandlers} />

    Note: I use \s+, it should be \s*, but given that after it is slash to end up
    the regexp, it ends the block comment, which is inconvenient here.
  */
  get blurEventHandler() {
    return (event) => {
      event.persist()
      const fn = this.data.converters.blur || ((rawValue) => rawValue)
      this.set(fn(event.target.value))
    }
  }

  get focusEventHandler() {
    return (event) => {
      event.persist()
      const fn = this.data.converters.focus || ((value) => value)
      this.set(fn(this.value))
    }
  }

  get eventHandlers() {
    return {
      onChange: this.changeEventHandler,
      onFocus: this.focusEventHandler,
      onBlur: this.blurEventHandler
    }
  }

  get isValid() {
    return !this.error.value
  }
}

export default function useAttribute (label, defaultValue, forceRender = null, converters = {}) {
  const [ value, _setter ] = useState(defaultValue)
  const [ errorMessage, errorMessageSetter ] = useState(null)

  const error = new AttributeError({value: errorMessage, setter: errorMessageSetter})

  const setter = (value) => { _setter(value); error.set(null); if(forceRender) { forceRender() } }

  return new Attribute({label, value, defaultValue, converters, setter, error})
}
