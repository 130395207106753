import React from 'react';
import { Container } from 'react-bootstrap';

import { SvgBackArrow } from '../general/icons';
import styles from './styles.module.scss';
import EmployeeForm from './EmployeeForm';
import FreelancerForm from './FreelancerForm';

const InviteTalents = ({ history }) => {
  const navigateToDashboard = () => history.push('/');

  return (
    <Container>
      <div className={styles.back} onClick={navigateToDashboard}>
        <SvgBackArrow />
        <span>Back to Dashboard</span>
      </div>
      <div className={styles.header}>
        <h2>Add Talent To Your Pool</h2>
        <p>
          Add your employees and your favorite freelancers to your agency's talent pool simply by inputting their email
          addresses below. When you click "Invite All" each individual will receive an email invitation to fill out a
          profile.
        </p>
      </div>
      <div className={styles.talentPoolContainer}>
        <EmployeeForm history={history} />
        <div className={styles.divider} />
        <FreelancerForm history={history} />
      </div>
    </Container>
  );
};

export default InviteTalents;
