import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import Store from 'store'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import Button from '../../general/button'
import Loading from '../../../components/general/loading'
import { SvgBackArrow } from '../../../components/general/icons'
import ideaStyles from '../../jobs/idea/styles.module.css'
import styles from './styles.module.css'
import { SvgRemoveIcon } from '../../../components/general/icons'
import * as yup from 'yup'
import useAttribute from '../../../utils/useAttribute'
import invoicesResource from 'resources/invoices'
import { toast } from 'react-toastify';
import tilesStyles from '../../dashboard/tiles.module.css'
import classnames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import Config from 'utils/config';
import { formatCurrency } from 'utils/formatters'
import Discount from 'components/general/discount'

export default function ManageBilling ({ history, match, location }) {
  const { state } = useContext(Store)
  const [loading, setLoading] = useState(true)
  const [invoices, setInvoices] = useState()
  const disableActions = loading

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await invoicesResource.index()
        setInvoices(response.data)
      } catch(error) {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      } finally {
        setLoading(false)
      }
    }
    fetchInvoices()
  }, [])

  const navigateToDashboard = () => history.push('/')

  return (
    <Container>
      { loading && <Loading /> }
      { !loading && <>
        <div className={ideaStyles.back} onClick={navigateToDashboard}>
          <SvgBackArrow /> <span>Back to Dashboard</span>
        </div>
        <Row className={styles.hero}>
            <Col md={12} xs={12}>
            <p className={styles.header}>
                {state.currentUser.organization.name}
            </p>
            </Col>
        </Row>
        <Row className="mt-3">
        </Row>

      </>}
    </Container>
  )
}
