import Config from 'utils/config'

// all /users/ paths are ignored by stonly
export const SIGN_IN = '/users/sign_in'
export const SIGN_UP = '/users/sign_up'
export const FORGOT_PASSWORD = '/users/forgot_password'
export const CONFIRM_EMAIL = '/users/confirm_email/:token'
export const RESET_PASSWORD = '/users/reset_password/:reset_password_token'
export const SAML_SIGN_IN = '/users/:idp/sign_in'

export const NON_AUTHENTICATED_PATHS = [
  SIGN_IN,
  // todo: remove sign up, appears unused
  SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_EMAIL,
  RESET_PASSWORD,
  SAML_SIGN_IN,
]

// todo: support pattern matched urls such as CONFIRM_EMAIL, RESET_PASSWORD, etc.
export const pathRequiresAuth = (path) => !NON_AUTHENTICATED_PATHS.includes(path)

export const SETTINGS = '/settings'
export const NOTIFICAITON_SETTINGS = '/settings/notifications'
export const EDIT_PROFILE = '/settings/profile'

export const CREATE_JOB_V1 = '/jobs/new'
export const JOB_BUILDER_STEP_1_VITALS = '/job-builder/step-1-vitals'
export const JOB_BUILDER_STEP_2_FREELANCERS = '/job-builder/step-2-freelancers'
export const JOB_BUILDER_STEP_3_BRIEFING = '/job-builder/step-3-briefing'
export const JOB_BUILDER_STEP_4_FINALIZE_TEAM = '/job-builder/step-4-finalize-team'

export const CREATE_JOB = Config.jobBuilderV3 ? JOB_BUILDER_STEP_1_VITALS : CREATE_JOB_V1

export const JOB = (jobId) => `/jobs/${jobId}`
export const JOB_PRINT = (jobId) => `/jobs/${jobId}/print`
export const IDEA_PRINT = (jobId, ideaId, index) => `/jobs/${jobId}/${ideaId}/${index}/print`
export const JOB_LIST = `/jobs/list`

export const JOB_DIRECT_ACCESS = (jobAccessLinkId) => `/direct/jobs/${jobAccessLinkId}`

export const MANAGE_ORGANIZATION = `/manage_organization`
export const MASTER_COMMAND_CENTER = `/master-command-center`
export const MANAGE_BILLING = `/billing`

export const INVITE_MORE = (jobId) => `/jobs/${jobId}/invite_more`
export const END_JOB = (jobId) => `/jobs/${jobId}/end_session`
export const CREATE_IDEA = (jobId) => `/jobs/${jobId}/ideas/new`
export const EDIT_IDEA = (jobId, ideaId) => `/jobs/${jobId}/ideas/${ideaId}/edit`
export const IDEA = (jobId, ideaId) => `/jobs/${jobId}/ideas/${ideaId}`

export const PROFILE = '/profile'

export const TOU = '/terms-of-use'
export const TOUv2 = '/terms-of-use-2021'
export const PRIVACY_POLICY = '/privacy-policy'
export const EXPERT_AGREEMENT = '/expert-agreement'
export const FREELANCER_AGREEMENT = '/freelancer-agreement-2021'

export const CUSTOMER_ONBOARDING = '/customers/onboarding'
export const TEAM_MEMBER_ONBOARDING = '/team_members/onboarding'

export const EXPERT_ONBOARDING = '/experts/onboarding'
export const FREELANCER_ONBOARDING = '/freelancers/onboarding'

export const GENERIC_USER_ONBOARDING = '/users/onboarding'

export const INVITE_TALENTS = '/invite_talents'
export const TALENT_POOL = '/talent_pool'
export const ADMIN_MANAGE_IDEASICLE_EXPERTS = '/manage_ideasicle_experts'
export const ADMIN_APPROVE_JOB_PAYMENT = (jobId) => `/jobs/${jobId}/payments/review`