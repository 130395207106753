import React, { useState, } from 'react'
import styles from './styles.module.css'
import { SvgYoutubeIcon } from '../general/icons'
import DefaultVideoComponent from 'draft-js-video-plugin/lib/video/components/DefaultVideoComponent.js'
import Modal from 'react-bootstrap/Modal'
import '../general/modals.scss';
import Button from '../general/button'
import Loading from '../general/loading';
import { videoTheme } from 'components/jobs/idea/rich_content'
/*
  Managing focus in Draft.js

  1) Explanation and setTimeout as a workaround

    Note that we're using setTimeout in the onClick handler.

    The reason for that is that without it, the editorState would
    get set to the correct value and then overwritten with the old
    one.

    The reason for that is that the onClick event of the whole Editor
    is set to set focus to the (inner) editor. That updates the selection
    state, therefore the editorState.

    So these 2 events fire at the same time:

      - VideoButton.onClick
      - Editor.onClick

    And because window.prompt blocks the main thread, Editor.onClick
    gets stuck in a limbo state, so all the time that the user needs
    to fill in the URL won't help to update the state, because the main
    thread is blocked.

    Adding a timeout helps Editor.onClick to be propagated, therefore
    embedding a video works.

    But ... it introduces other problems, such as it breaks the color
    picker for some misterious reasons.

  2) Nonblocking dialogs

    An obvious solution might be nonblocking dialogs.

    The issue here is that since these are nested within the Editor
    component and therefore we run into an issue, where we click the
    input box in the modal, get focus for about 1s, before the focus
    switches back to the Editor component, as both onClick handlers
    get called (and I haven't been able to fix it using e.preventDefault()).

  3) Nonblocking dialog out of Editor's focus area

    Other option was to keep the dialog out of the area of Editor's focus
    area. That breaks encapsulation, but ultimately the main problem is
    how to get data from the Dialog (that's no longer ruled by VideoButton).

  4) Using onMouseDown instead of onClick

    Works. Don't ask me why.
*/

const VideoButton = ({ modifier, onChange, editorState }) => {
  const [modalOpen, _setModalOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')

  const setModalOpen = (value) => {
    //clear state when closing
    setVideoUrl('')
    _setModalOpen(value)
  }

  const handleMouseDown = (e) => {
    e.preventDefault()

    setModalOpen(true)
  }

  let parsedUrl = null
  try { parsedUrl = new URL(videoUrl) } catch(e) {}
  const validUrl = parsedUrl && parsedUrl.pathname.length > 1 // ignore "/"

  const handleModalSuccess = () => {
    setTimeout(() => {
      const newEditorState = modifier(editorState, {src: videoUrl})
      onChange(newEditorState)
    })

    setModalOpen(false)
  }

  return (
    <div className={styles.buttonWrapper}>
      <button onMouseDown={handleMouseDown} className={styles.menuButton} style={{paddingTop: 3}}>
        <SvgYoutubeIcon />
      </button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalOpen}
        onHide={() => setModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Video
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter a YouTube URL</p>
          <input
            name="videoUrl"
            type="text"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            placeholder="https://www.youtube.com/watch?v=Fl6Jgjv0rPo"
            tabIndex={0}
            className={styles.input}
          />
          {validUrl && <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <DefaultVideoComponent style={{width: 300, height: 150}} blockProps={{src: videoUrl}} theme={videoTheme} />
          </div>}
          </Modal.Body>
        <Modal.Footer>
          <Button className="none" onClick={() => setModalOpen(false)}>Close</Button>
          <Button className="primary" type="primary" onClick={handleModalSuccess} disabled={!validUrl}>Add</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default VideoButton
