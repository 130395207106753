import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const NOTIFICATION_METADATA = {
  job_needs_input: {
    heading: 'Job Needs Attention',
    label: 'Additional information is needed in order to begin a Job',
  },
  job_expires_soon_customer: {
    heading: 'Job Ends Soon',
    label: 'One of my Jobs is ending in the next 24 hours',
  },
  new_idea_posted: {
    heading: 'New Idea Posted',
    label: 'A new Idea has been posted on one of my Jobs',
  },
  idea_comment: {
    heading: 'New Build Posted',
    label: 'A Build has been posted in an Idea of one of my Jobs',
  },
};

const Notification = ({ values, onChange }) => {
  const metadata = NOTIFICATION_METADATA;

  return (
    <div>
      <div className={styles.subheader}>Email me when any of the following events occur:</div>
      <Row>
        {Object.keys(metadata).map((k) => (
          <Col xs={6} key={k}>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                checked={values.hasOwnProperty(k) ? values[k] : true}
                onChange={onChange}
                className={styles.checkbox}
                name={k}
                id={k}
              />
              <label htmlFor={k}>
                <span className={styles.primaryLabel}>{metadata[k].heading}</span>
                <br />
                <span style={{ paddingLeft: 30 }} className={styles.secondaryLabel}>{metadata[k].label}</span>
              </label>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

Notification.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Notification;
