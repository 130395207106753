import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import moment from 'moment';

import { INVITE_TALENTS } from 'constants/routes';
import Avatar from 'components/general/avatar';
import { SvgPlusIconLarge } from 'components/general/icons';
import Store from 'store';
import expertsResource from 'resources/experts';
import { formatCurrency } from 'utils/formatters';
import { SvgBackArrow } from '../general/icons';
import styles from './styles.module.scss';

const TalentPool = ({ history }) => {
  const { state } = useContext(Store);
  const [experts, setExperts] = useState([]);

  const organizationName = useMemo(() => get(state, 'currentUser.organization.name', ''), [state]);

  const navigateToDashboard = () => history.push('/');

  useEffect(() => {
    const fetchExperts = async () => {
      try {
        const response = await expertsResource.expertsWithEarnings();
        setExperts(response.data);
      } catch (error) {
        console.error(error);
        toast.warn('Uh oh! Something went wrong. Please try again shortly.');
      } finally {
        setLoading(false);
      }
    };
    fetchExperts();
  }, []);

  const totalEarnings = useMemo(() => experts.reduce((acc, expert) => acc + expert.total_earnings_in_cents, 0) / 100);

  return (
    <Container>
      <div className={styles.back} onClick={navigateToDashboard}>
        <SvgBackArrow />
        <span>Back to Dashboard</span>
      </div>
      <div className={styles.header}>
        <h2>Monitor and manage your talent pool</h2>
        <p>Below is an overview of all of your agency's talent on your platform and how much they have earned.</p>
      </div>
      <div className={styles.talentPoolContainer}>
        <table className={styles.expertsTable}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>{organizationName.toUpperCase()} EXPERT</th>
              <th style={{ width: '30%' }}>DATE ASSIGNED</th>
              <th>EARNINGS</th>
            </tr>
          </thead>
          <tbody>
            {experts.map((expertUser) => {
              const { expert, assigned_date: assignedDate, total_earnings_in_cents: earningsInCents } = expertUser;
              return (
                <tr key={expertUser.expert.id}>
                  <td>
                    <Avatar record={expert} className={styles.avatar} />
                    {expert.first_name} {expert.last_name}
                  </td>
                  <td>{moment(assignedDate).format('D-M-YY')}</td>
                  <td>{formatCurrency(earningsInCents / 100)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={styles.totalEarning}>
          <span>TOTAL:</span>
          {formatCurrency(totalEarnings)}
        </div>
        <div className={styles.addTalent} onClick={() => history.push(INVITE_TALENTS)}>
          <SvgPlusIconLarge style={{ height: 56, width: 56 }} />
          <div className={styles.addTalentText}>
            <p className={styles.addTalentTitle}>Add more talent from your agency</p>
            <p>Anyone you add could be recruited and hired by other {organizationName} agencies for idea projects.</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TalentPool;
