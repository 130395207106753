import BaseModel from './base'

export default class Job extends BaseModel {
  get expertTeam() {
    if(!this._expertTeam) {
      // Failsafe: prevent experts without profiles (not onboarded yet) from being considered
      // as part of the job's team
      this._expertTeam = this.experts.filter((expert) => expert.chosen && expert.expert_type)
    }
    return this._expertTeam;
  }

  get canParticipate() {
    return this.participation_mode === 'participate'
  }

  get ideaDirectorUsers() {
    return this.direct_access_users.filter((u) => u.allows_edit)
  }

  get observerUsers() {
    return this.direct_access_users.filter((u) => !u.allows_edit)
  }
}
