import React, { useState, useContext, useEffect } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import Loading from 'components/general/loading'
import { SvgBackArrow } from 'components/general/icons'
import ideaStyles from '../idea/styles.module.css'
import jobsResource from 'resources/jobs'
import styles from './styles.module.css'
import { formatCurrency } from 'utils/formatters'
import * as routes from 'constants/routes'
import moment from 'moment'

const renderJobsTable = (jobs, isActive) => (
  <>
    <p className={isActive ? styles.activeJobsHeader : styles.completedJobsHeader}>
      {isActive ? 'Active' : 'Archived' } Jobs
    </p>
    <table className={styles.jobsTable}>
      <thead>
        <tr>
          <th>JOB</th>
          <th>DATE {isActive ? 'STARTED' : 'CLOSED'}</th>
          <th>PAYMENTS {isActive ? 'ESTIMATED' : ''}</th>
        </tr>
      </thead>
      <tbody>
        {jobs && jobs.map((job) => {
          return (
            <tr key={job.id}>
              <td><a className={styles.job_link} href={routes.JOB(job.slug)}>{job.title}</a></td>
              <td>{isActive ? job.start_date : moment(job.closed_at || job.updated_at).format("MM.DD.YY")}</td>
              <td>{formatCurrency(job.payment_in_cents / 100.0, true)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </>
)

const renderHero = (jobs, totalPaid, user) => (
  <Row className={styles.hero}>
    <Col md={6} xs={12}>
      <p className={styles.header}>
        {user.first_name}
      </p>
    </Col>
    <Col className={styles.heroRight} md={6} xs={12}>
      <p className={styles.subHeader}>
        {jobs.length}
      </p>
      <p className={styles.text}>
        Jobs<br/>Completed
      </p>

      <span className={styles.border}></span>

      <p className={styles.subHeader}>
        {totalPaid}
      </p>
      <p className={styles.text}>
        Total<br/>Paid
      </p>
    </Col>
  </Row>
)

export default function JobList ({ history, match, location }) {
  const { state } = useContext(Store)
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState(location.state && location.state.job)

  const fetchJobs = async () => {
    try {
      const response = await jobsResource.index()
      setJobs(response.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  const navigateToDashboard = () => history.push('/')
  const activeJobs = jobs && jobs.filter(j => ['created', 'active'].includes(j.status))
  const archivedJobs = jobs && jobs.filter(j => ['closed', 'archived'].includes(j.status))
  const totalPaid = jobs && formatCurrency(archivedJobs.reduce((acc, job) => acc + job.payment_in_cents, 0) / 100.0, true)


  return (
    <Container>
      { loading && <Loading /> }
      { !loading && <>
        <div className={ideaStyles.back} onClick={navigateToDashboard}>
          <SvgBackArrow /> <span>Back</span>
        </div>
        {renderHero(archivedJobs, totalPaid, state.currentUser)}
        {/* {renderJobsTable(activeJobs, true)} */}
        {renderJobsTable(archivedJobs, false)}
      </>}
    </Container>
  )
}
