import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Store from 'store';

import ContentHeader from 'components/general/content_header';
import Loading from 'components/general/loading';
import notificationSettingsResource from 'resources/notification_settings';
import usersResource from 'resources/users';
import styles from './styles.module.css';
import Notification from './notification';
import Branding from './branding';
import CustomDomain from './custom_domain';

export default function SettingsPage({ history }) {
  const { dispatch, state } = useContext(Store);
  const [initialValues, setInitialValues] = useState({});
  const [settings, setSettings] = useState({});
  const [notificationSettings, setNotificationSettings] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const fetchSettings = useCallback(async () => {
    try {
      const notificationSetting = await notificationSettingsResource.index();
      const values = {
        notification: notificationSetting.data,
      };
      setInitialValues(values);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const { values, setFieldValue } = useFormik({
    initialValues: initialValues,
  });

  const save = async () => {
    try {
      setSubmitting(true);
      const result = await usersResource.updateCurrent({
        data: { user: { notification_settings: values.notification } },
      });
      toast.success('Your settings have been saved.');
      history.push('/');
    } catch (e) {
      console.error(e);
      toast.warn('Uh oh! Something went wrong. Please try again shortly.');
    } finally {
      setSubmitting(false);
    }
  };

  const orgName = state.currentUser?.organization?.name;

  const handleChangeNotification = (e) => {
    const newNotification = { ...values.notification, [e.target.name]: e.target.checked };
    setFieldValue('notification', newNotification);
  };

  return (
    <>
      <Helmet>
        <title>Settings | {orgName}</title>
      </Helmet>
      <Container>
        <Row>
          <Col>
            <ContentHeader text="My Settings">
              <button className="btn btn-primary btn-slim" type="button" disabled={submitting} onClick={save}>
                Save Settings
              </button>
            </ContentHeader>
            {loading && <Loading />}
            {!loading && (
              <>
                <Notification values={values.notification || {}} onChange={handleChangeNotification} />
                <div className={styles.divider} />
                <Branding values={{}} onChange={handleChangeNotification} />
                <div className={styles.divider} />
                <CustomDomain />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
