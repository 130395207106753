import React, { useState, useCallback, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import {injectStripe} from 'react-stripe-elements'
import NewCardInfo from '../../customer_onboarding/new_card_info'
import styles from '../payments/new_card_info_modal.module.css'
import onboardingStyles from '../../customer_onboarding/styles.module.css'
import classnames from 'classnames'
import Button from 'components/general/button'
import Store from 'store'
import masterCommandCentersResource from 'resources/master_command_centers'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { withFormik, Field } from 'formik'
import { company as companyValidations } from 'constants/validations'
import * as yup from 'yup'
import { currentUser as currentUserStore } from 'utils/local_storage'
import { CURRENT_USER_FETCHED } from 'constants/actions'

function DuplicateOrganizationModal({
  history,
  show,
  stripe,
  elements,
  onCompleted,
  isValid,
  errors,
  values
}) {
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const { state, dispatch } = useContext(Store)
  const { currentUser } = state

  const onClose = history.goBack

  const duplicateOrganization = useCallback(async () => {
    setSubmitting(true)
    setError(null)

    let stripeResponse = null
    try {
      stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('cardNumber'),
        billing_details: {
          name: currentUser.fullName,
          email: currentUser.email,
          address: {
            postal_code: document.querySelector('input[name=cardZip]').value
          }
        }
      })
      if(stripeResponse.error) {
        setError(stripeResponse.error.message)
        setSubmitting(false)
        return
      }
    } catch(error) {
      if(error.toString().indexOf('We could not infer which Element you want to use') > -1) {
        setError('Please add your billing information')
        setSubmitting(false)
        return
      }
      console.error(error)
      return
    }

    try {
      const data = {
        payment_method_id: stripeResponse.paymentMethod.id,
        organization_name: values.companyName,
      }

      const response = await masterCommandCentersResource.create({ data })
      toast.success("Your Master Command Center has been created")
      currentUserStore.set(response.data.user)
      dispatch({type: CURRENT_USER_FETCHED, payload: response.data.user})
      onCompleted({ organization: response.data.organization })
    } catch(error) {
      console.error(error)
      if(error.response?.data?.payment_error) {
        setError(error.response.data.payment_error)
      } else {
        toast.warn("Uh oh! Something went wrong. Please try again soon.")
      }
    } finally {
      setSubmitting(false)
    }
  }, [setSubmitting, setError, stripe, elements, currentUser, values])

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      style={{border: '2px solid black'}}
    >
      <Modal.Header closeButton>
      <Modal.Title>Upgrade to Master Command Center</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please customize the name of your new Master Command Center, for instance, by adding your department name:</p>

        <div style={{position: 'relative'}}>
          {errors.companyName && <span className={classnames(onboardingStyles.small, onboardingStyles.below_input, onboardingStyles.error)}>
            {errors.companyName}
          </span>}
          <Field name="companyName" className={classnames(onboardingStyles.input, styles.input)} />
        </div>

        <p>Enter your new credit card information below. This payment information will be used for all future charges for your new Master Command Center.</p>
        <NewCardInfo
          errorMessage={error}
          styles={styles}
          clearStripeErrors={() => setError()}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={submitting}  onClick={onClose}>
            Cancel
        </Button>
          <Button disabled={!isValid || submitting} className="primary" onClick={duplicateOrganization}>
              Submit
          </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withFormik({
  mapPropsToValues: ({ organization }) => ({ companyName: organization.name + " - My Dept" }),
  validationSchema: ({ organization }) => yup.object().shape({ companyName: companyValidations.name.notOneOf([organization.name], "Please choose a new name") }),
  validateOnMount: true,
})(withRouter(injectStripe(DuplicateOrganizationModal)))
