import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DirectUploadProvider } from 'react-activestorage-provider';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import ImageUploader from 'react-images-upload';
import { injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import snakeCase from 'snakecase-keys';
import Store from 'store';
import * as yup from 'yup';

import Discount from 'components/general/discount';
import ErrorPage from 'components/general/error_page';
import { SvgPlusIcon } from 'components/general/icons';
import Loading from 'components/general/loading.jsx';
import ProgressBar from 'components/general/progress_bar';
import SignedInBar from 'components/general/signed_in_bar';
import { CURRENT_USER_FETCHED } from 'constants/actions';
import { TOU } from 'constants/routes';
import { company as companyValidations } from 'constants/validations';
import achMailResource from 'resources/ach_mail';
import discountsResource from 'resources/discounts';
import plansResource from 'resources/plans';
import usersResource from 'resources/users';
import Config from 'utils/config';
import { formatCurrency } from 'utils/formatters';
import { currentUser, pathAfterSignIn } from 'utils/local_storage';

import NewCardInfo from './new_card_info';
import CustomerProfileEditor from './profile_editor';
import styles from './styles.module.css';

const validationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  companyName: companyValidations.name,
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Passwords do not match').required('Password Confirmation is required'),
  tos: yup.boolean().oneOf([true], 'Must accept Terms of Use to proceed')
})

const getDiscountLabel = (discount) => {
  return discount.metadata && discount.metadata.UI_LABEL || discount.name
}

function CustomerOnboarding ({location, stripe, elements, history}) {
  const { dispatch, state } = useContext(Store)

  const avatarUploader = useRef(null)
  const companyAvatarUploader = useRef(null)

  const [profileAvatarSignedId, setProfileAvatarSignedId] = useState(null)
  const [profileAvatarFileUrl, setProfileAvatarFileUrl] = useState(null)

  const [companyAvatarSignedId, setCompanyAvatarSignedId] = useState(null)
  const [companyAvatarFileUrl, setCompanyAvatarFileUrl] = useState(null)

  const [plans, setPlans] = useState(null)

  const onAvatarUpload = (setFileUrl, handleUpload) => (files) => {
    const image = files[files.length - 1]
    if (!image) {
      if(avatarUploader.current.state.notAcceptedFileType.length) {
        toast.warn("Sorry! That file type is not supported.")
        avatarUploader.current.state.notAcceptedFileType.pop()
      }
      if(avatarUploader.current.state. notAcceptedFileSize.length) {
        toast.warn("Uh oh! This image is too large. Please upload an image less than 100 MB")
        avatarUploader.current.state. notAcceptedFileSize.pop()
      }
      return
    }
    avatarUploader.current.state.files.pop()
    setFileUrl(URL.createObjectURL(image))
    handleUpload([image])
  }

  const onAvatarUploadSuccess = (setSignedId) => (signedIds) => {
    setSignedId(signedIds[0])
  }

  const [showCardDetails, setShowCardDetails] = useState(false)

  const parsedQuery = useMemo(() => queryString.parse(location.search), [])

  const couponCode = useMemo(() => parsedQuery.coupon_code || Config.defaultCouponCode?.code, [parsedQuery.coupon_code])
  const [discount, setDiscount] = useState(null)
  const [loadingDiscount, setLoadingDiscount] = useState(!!couponCode)
  const discountInvalid = useMemo(() => discount && !discount.valid, [discount])

  const commissionCouponCode = useMemo(() => parsedQuery.commission_coupon_code, [parsedQuery.commission_coupon_code])
  const [commissionDiscount, setCommissionDiscount] = useState(null)
  const [loadingCommissionDiscount, setLoadingCommissionDiscount] = useState(!!commissionCouponCode)
  //const commissionDiscountInvalid = useMemo(() => commissionDiscount && !commissionDiscount.valid, [commissionDiscount])

  const couponCodeAppliesToPlan = useCallback((selectedPlan) => {
    return couponCode && (couponCode !== Config.defaultCouponCode?.code || Config.defaultCouponCode?.plan === selectedPlan)
  }, [couponCode])

  const getFirstTierPrice = useCallback((selectedPlan) => {
    const plan = plans[selectedPlan]
    if(plan.unit_amount) return plan.unit_amount
    if(plan.tiers) {
      const sortedTiers = sortBy(plan.tiers, (t) => t.up_to || Number.POSITIVE_INFINITY)
      const lowestTier = sortedTiers[0]
      return lowestTier.unit_amount
    }
  })

  useEffect(() => {
    const fetchDiscount = async () => {
      try {
        if(couponCode && couponCode !== 'none') { //ignore coupon code for test, this is just used to ignore the default discount
          const response = await discountsResource.show({ params: { id: couponCode}} )
          if(response.data) setDiscount(response.data)
        }
      } catch(e) {
        console.error("Failed to fetch discount", e)
        toast.warn("Uh oh! We weren't able to fetch your discount. Please try again soon.")
      } finally {
        setLoadingDiscount(false)
      }
    }
    fetchDiscount()
  }, [])

  useEffect(() => {
    const fetchCommisionDiscount = async () => {
      try {
        if(commissionCouponCode) {
          const response = await discountsResource.show({ params: { id: commissionCouponCode}} )
          if(response.data) setCommissionDiscount(response.data)
        }
      } catch(e) {
        console.error("Failed to fetch commission discount", e)
        toast.warn("Uh oh! We weren't able to fetch your discount. Please try again soon.")
      } finally {
        setLoadingCommissionDiscount(false)
      }
    }
    fetchCommisionDiscount()
  }, [])

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await plansResource.index()
        const planFilter = Config.couponPlans[couponCode] || Config.defaultPlans
        if(planFilter.length) {
          const filteredPlans = {}
          planFilter.forEach(key => {
            filteredPlans[key] = response.data[key]
          })
          if(parsedQuery.plan && response.data[parsedQuery.plan]) {
            filteredPlans[parsedQuery.plan] = response.data[parsedQuery.plan]
          }
          setPlans(filteredPlans)
        } else {
          setPlans(response.data)
        }
      } catch(e) {
        console.error("Failed to fetch plans", e)
        toast.warn("Uh oh! Something went wrong. Please try again soon.")
      }
    }
    fetchPlans()
  }, [])

  useEffect(() => {
    const redirectFirst50 = async () => {
      if(discount && discountInvalid && couponCode === 'FIRST-50-IX92953') {
        window.location = "https://www.ideasiclex.com/post-50-sign-up "
      }
    }
    redirectFirst50()
  }, [discount, discountInvalid])

  const PlanMarketingCopy = ({values}) => {
    const amount = getFirstTierPrice(values.plan)
    const formatter = (p) => formatCurrency(p / 100)
    const planName = values.plan
    const plan = plans[planName]

    // if "instead" is set for this plan...
    const instead = Config.onboardingPlanMarketingCopy &&
      Config.onboardingPlanMarketingCopy[values.plan] &&
      Config.onboardingPlanMarketingCopy[values.plan].instead;
    // ...and it is a function, call it. else, just use it as-is
    const insteadCopy = typeof instead === 'function' ?
      instead({ plan, formattedAmount: formatter(amount) }) : instead;
    // finally, if our function return value or raw "instead" value is just
    // a string, we will render it directly. if its an array, we will render
    // bullet points

    return <>
        <strong>Plan Details:</strong><br />
        {Array.isArray(insteadCopy) && <ul>
          {insteadCopy.map((copy, i) => <li key={i}>{copy}</li>)}
        </ul>}
        {typeof insteadCopy === 'string' && insteadCopy}
        {!insteadCopy && 
          <>
            {Config.onboardingPlanMarketingCopy && Config.onboardingPlanMarketingCopy[values.plan] &&
              Config.onboardingPlanMarketingCopy[values.plan].pre}
            {' '}{getFirstTierPrice(values.plan) && <>Your credit card will be charged <Discount
              formatter={formatter}
              amount={amount}
              discount={couponCodeAppliesToPlan(values.plan) && discount} /> when you click Save.{' '}</>}
            {Config.onboardingPlanMarketingCopy && Config.onboardingPlanMarketingCopy[values.plan] &&
              Config.onboardingPlanMarketingCopy[values.plan].post}
            
          </>}
          { ( (discount && couponCodeAppliesToPlan(values.plan)) || commissionDiscount) && <>
            <br />
                { discount && couponCodeAppliesToPlan(values.plan) && <>
                  <span className={styles.small}>{couponCode === Config.defaultCouponCode?.code ? 'Offer: ' : 'Special offer: '}</span>
                  <span className={styles.small} style={{color: '#009245'}}>{ getDiscountLabel(discount) }<br /></span>
                </>}
                { commissionDiscount && <>
                  <span className={styles.small}>Special offer: </span>
                  <span className={styles.small} style={{color: '#009245'}}>{ getDiscountLabel(commissionDiscount) }</span>
                </>}
            </>}
    </>
  }

  const inquireACH = async (values) => {
    try {
      await achMailResource.create({ params: {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        company: values.companyName
      }})
      toast.success("We've received your inquiry. The Ideasicle X team will be in touch soon.")
    } catch (err) {
      toast.error(get(err, "response.data.error", "Failed to send email to admin users"))
    }
  }

  return (
    <SignedInBar unauthenticatedMode="dark">
      <Helmet>
        <title>Customer Onboarding | Ideasicle X</title>
      </Helmet>
      { (loadingDiscount || loadingCommissionDiscount || !plans) && <Loading />}
      { discountInvalid && <ErrorPage heading="Offer Invalid">
          We're sorry, but that offer is no longer valid. <a href="https://www.ideasiclex.com/subscriptions">Back to Pricing</a>
      </ErrorPage>}
      { !loadingDiscount && !discountInvalid && !loadingCommissionDiscount && plans && <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          companyName: '',
          password: '',
          passwordConfirmation: '',
          plan: parsedQuery.plan || Object.keys(plans)[0],
          tos: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          setSubmitting(true)
          setFieldError('stripe', null)
          let stripeResponse = null
          try {
            stripeResponse = await stripe.createPaymentMethod({
              type: 'card',
              card: elements.getElement('cardNumber'),
              billing_details: {
                name: `${values.firstName} ${values.lastName}`,
                email: values.email,
                address: {
                  postal_code: document.querySelector('input[name=cardZip]').value
                }
              }
            })
            if(stripeResponse.error) {
              setFieldError('stripe', stripeResponse.error.message)
              setSubmitting(false)
              return
            }
          } catch(error) {
            if(error.toString().indexOf('We could not infer which Element you want to use') > -1) {
              setFieldError('stripe', 'Please add your billing information')
              setSubmitting(false)
              return
            }
          }

          const user = snakeCase(values)
          if(values.tos) {
            user.tos_acception_date = new Date()
            delete user.tos
          }
          user.avatar = profileAvatarSignedId
          user.organization_attributes = {
            name: user.company_name,
            stripe_plan_id: user.plan,
            avatar: companyAvatarSignedId,
            referral_code: parsedQuery.ref,
          }
          delete user.company_name
          delete user.plan

          const data = {
            user,
            commission_discount: commissionDiscount,
            payment_method_id: stripeResponse.paymentMethod.id,
          }
          if(couponCodeAppliesToPlan(user.organization_attributes.stripe_plan_id)) {
            data.discount = discount
          }
          try {
            const result = await usersResource.create({ data })
            dispatch({type: CURRENT_USER_FETCHED, payload: result.data})
            currentUser.set(result.data)
            const nextPath = pathAfterSignIn.get() || '/'
            pathAfterSignIn.clear()
            history.push(nextPath)
          } catch(error) {
            console.error(error)
            const { response } = error
            if(response && response.data && response.data.errors && response.data.errors.email) {
              const message = 'Email ' + response.data.errors.email
              toast.warn(message)
              setFieldError('email', message)
            } else if(response && response.data && response.data.payment_error) {
              toast.warn(response.data.payment_error)
              setFieldError('stripe', response.data.payment_error)
            } else if(response && response.data && response.data.errors && response.data.errors.email) {

            }
          } finally {
            setSubmitting(false)
          }
        }}
        render={({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          setFieldError,
          validateForm
        }) => {
          const clearStripeErrors = () => {
            setFieldError('stripe', null)
            validateForm()
          }
          return (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col>
                  <CustomerProfileEditor
                    headerMessage="Let's get started! Set up your user name, company, and payment information."
                    headerMessageLink={true}
                    onAvatarUploadSuccess={onAvatarUploadSuccess}
                    setProfileAvatarSignedId={setProfileAvatarSignedId}
                    avatarUploader={avatarUploader}
                    onAvatarUpload={onAvatarUpload}
                    profileAvatarFileUrl={profileAvatarFileUrl}
                    setProfileAvatarFileUrl={setProfileAvatarFileUrl}
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    email={true}
                    />

                  <div className={styles.box}>
                    <div className={classnames(styles.row, 'row')}>
                      <Col md={3} xs={12} style={{marginBottom: 50}}>
                        <h2 className={styles.section_heading}>
                          Company Info
                        </h2>
                        <DirectUploadProvider directUploadsPath="/api/v1/direct_uploads" onSuccess={onAvatarUploadSuccess(setCompanyAvatarSignedId)} render={({ handleUpload, uploads, ready }) => (
                          <div className={classnames(styles.upload_wrapper, {[styles.upload_wrapper_disabled]: !ready})}
                            onClick={() => { ready && companyAvatarUploader.current.triggerFileUpload() }}>
                            <div style={{ visibility: 'hidden', position: 'absolute' }}>
                              <ImageUploader
                                ref={companyAvatarUploader}
                                onChange={onAvatarUpload(setCompanyAvatarFileUrl, handleUpload)}
                                singleImage={true}
                                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                                maxFileSize={1024 * 1024 * 100}
                              />
                            </div>
                            <SvgPlusIcon className={styles.upload_button}/>
                            {!companyAvatarFileUrl && <div className={styles.upload_box}>
                              Company Logo
                            </div>}
                            {companyAvatarFileUrl && <img src={companyAvatarFileUrl} style={{ maxHeight: '100%', maxWidth: '100%', opacity: ready ? 1 : 0.3 }} />}
                            {uploads.map(upload => {
                              if(upload.state === 'waiting' || upload.state === 'uploading') {
                                return (<ProgressBar width={100} height={8} percentage={upload.progress} className={styles.uploadProgressBar} />)
                              }
                            })}
                          </div>
                        )} />
                      </Col>
                      <Col md={9} xs={12}>
                        <Row>
                          <Col md={6} xs={12}>
                            <div style={{position: 'relative'}}>
                              {touched.companyName && errors.companyName && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                                {errors.companyName}
                              </span>}
                              <input
                                name="companyName"
                                type="text"
                                value={values.companyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Company name"
                                className={styles.input}
                              />
                            </div>
                            <label htmlFor="plan" className={styles.label}>Select your plan</label>
                            <div style={{position: 'relative'}}>
                              <Field component="select" name="plan" className={styles.input} style={{ marginBottom: 30 }}>
                                {Object.keys(plans).map(key => {
                                  return <option key={key} value={key}>{plans[key].nickname}</option>;
                                })}
                              </Field>
                            </div>
                          </Col>
                          <Col md={6} xs={12}>
                          </Col>
                          <Col xs={12}>
                              <PlanMarketingCopy values={values} />
                          </Col>
                        </Row>
                      </Col>
                    </div>
                    <div className={classnames(styles.row_separated, 'row')}>
                      <Col lg={5} md={7} xs={12}>
                        <h2 className={styles.section_heading}>
                          Payment Info
                        </h2>
                        <h3 className={styles.subsection_header}>
                          {!showCardDetails && <>Credit Card on File</>}
                          {showCardDetails && <>Credit Card Details</>}
                        </h3>
                        <hr style={{border: 'none', borderTop: '1px solid black'}} />
                        {!showCardDetails && <div onClick={() => setShowCardDetails(true)}>
                          <div className={styles.add_card_box}>
                            <SvgPlusIcon className={styles.add_card_button} />
                            Add new card
                          </div>
                        </div>}
                        {showCardDetails && <NewCardInfo errorMessage={errors.stripe} clearStripeErrors={clearStripeErrors} />}
                        <hr style={{ border: 'none', borderTop: '1px solid black', marginTop: 24 }} />
                      </Col>
                      <Col xs={12}>
                        <div>
                          <button
                            className="btn btn-primary btn-slim mt-2 px-2"
                            style={{ width: 'unset' }}
                            disabled={!values.email || errors.email}
                            onClick={() => inquireACH(values)}
                          >
                            Inquire about ACH payments
                          </button>
                          <p className={styles.inquireHelpText}>Click to request via email that we set up a direct ACH link to your bank.</p>
                        </div>
                      </Col>
                      <Col xs={2}></Col>
                      <Col md={5} xs={12}>

                      </Col>
                    </div>
                    <div className={classnames(styles.row_separated, 'row')}>
                      <Col xs={12} style={{textAlign: 'center'}}>
                        <div style={{marginBottom: 30}}>
                          <Field type="checkbox" name="tos" id="tos" style={{position: 'relative', top: -2}} />
                          <label htmlFor="tos">
                            I have read and accept the <a href={TOU} target="_blank">Terms of Use</a>.
                          </label>
                        </div>
                        <button type="button" className="btn btn-none" disabled={isSubmitting}>
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={!isValid || !dirty || isSubmitting || !showCardDetails}>
                          {isSubmitting && <div className="spin-grow light"></div>}
                          Save
                        </button>
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        )}}
      />}
    </SignedInBar>
  )
}

export default injectStripe(CustomerOnboarding)
