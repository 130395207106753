import React, { useState, useContext, useEffect } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import ideasResource from 'resources/ideas'
import * as routes from 'constants/routes'
import styles from './styles.module.css'
import { SvgBackArrow } from 'components/general/icons'
import classnames from 'classnames'
import jobCreateStyles from '../create/styles.module.css'
import useAttribute from 'utils/useAttribute'
import Editor from '../../editor/index.jsx'
import { toast } from 'react-toastify';


export default class EditIdea extends React.Component {

  editorRef = React.createRef()

    state={
      title: "",
      saving: false,
      editorInteracting: false
    }

    setTitleName(e){
        this.setState({
            title: e.target.value
        })
    }

    componentDidMount(){
        this.setState({
            title: this.props.location.state.idea.title,
        })
    }

    navigateToJob = () => this.props.history.push(routes.JOB(this.props.job.slug))

    submit = () => {
        this.setState({
            saving: true
        },async ()=>{
          const editorHtmlOutput = this.editorRef.current.getHtml()
          const editorState = this.editorRef.current.getSerializedEditorState()
            const response = await ideasResource.update({ data: {
              idea: {
                title: this.state.title,
                content: editorHtmlOutput,
                editor_state: editorState,
              }
            }, params: { id: this.props.location.state.idea.id }})

            this.props.history.push({
                pathname: routes.IDEA(this.props.job.slug, this.props.location.state.idea.slug),
                state: {
                  idea: response.data,
                }
            })
        })
    }


    render(){
        return (
            <>
                <div className={styles.back} onClick={this.navigateToJob}>
                    <SvgBackArrow /> <span>Back to {this.props.job.title}</span>
                </div>
                <ContentHeader text={this.props.job.title} noBorder={true}>
                </ContentHeader>
                <Row>
                    <Col lg={{span: 6, order: 1}} md={{span: 9, order: 1}} sm={{span: 12, order: 2}} xs={{span: 12, order: 2}}>
                        <input
                            id="idea_attributes."
                            className={classnames([jobCreateStyles.hero_input])}
                            value={this.state.title}
                            tabIndex={1}
                            onChange={e => this.setTitleName(e)}
                        />
                    </Col>
                    <Col lg={{span: 6, order: 2}} md={{ span: 3, order: 2}} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }} style={{ textAlign: 'right'}}>
                        <button className="btn btn-primary btn-slim idea-save" type="button" disabled={this.state.saving || this.props.job.status === "closed" || this.state.editorInteracting} onClick={this.submit}>
                            Save
                        </button>
                    </Col>
                </Row>
                <Editor
                  ref={this.editorRef}
                  onInteracting={(value) => this.setState({editorInteracting: value})}
                  initialState={this.props.location.state.idea.editor_state}
                />
                <button style={{marginBottom: 100}} className={classnames("btn btn-primary btn-slim", styles.postButton)} type="button" disabled={this.state.saving || this.props.job.status === "closed" || this.state.editorInteracting} onClick={this.submit}>
                    Save
                </button>
            </>
        )
    }

}
