import React, { memo, useContext, useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import * as routes from '../../../constants/routes'
import sessionsResource from '../../../resources/sessions'
import usersResource from '../../../resources/users'
import Logo from 'components/general/logo'
import Button from 'components/general/button'
import styles from '../shared/styles.module.css'
import Store from 'store'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { CUSTOMER_ONBOARDING, RESEND_CONFIRMATION } from 'constants/routes'
import { toast } from 'react-toastify';
import { navigateAfterRespondToJob } from 'components/expert_onboarding/index.jsx'
import UnauthenticatedLayout from '../shared/unauthenticated_layout.jsx'
import { clearToken } from 'utils/auth'
import { pathAfterSignIn, currentUser } from 'utils/local_storage'

const validationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Email is required'),
  password: yup.string().required('Password is required'),
});

export default function SignIn ({ location, history }) {
  const { dispatch, state } = useContext(Store)
  const [showConfirmError, setShowConfirmError] = useState(false)

  const [didForceSignOut, setDidForceSignOut] = useState(false)
  useEffect(() => {
    // Not ready to run this if we don't have location initialized;
    // don't want to enter history push loop
    if(!location) return

    if(location.state?.forcedSignOut && !didForceSignOut) {
      // if forced out, make sure to do a full sign out
      // which appears not to be possible in the axios
      // code which sets this location state
      dispatch({type: CURRENT_USER_FETCHED, payload: null})
      clearToken()
      currentUser.clear()
      setDidForceSignOut(true)
    }
    else if (state?.currentUser?.id && history) {
      history.replace({ pathname: '/' })
    }
  }, [state?.currentUser, history, location, didForceSignOut])
  

  const sendResetInstructions = (email) => {
    const url = `/api/v1/users/resend_confirmation_email?email=${encodeURIComponent(email)}`;
    fetch(url)
    .then(function(response) {
      if (response.ok) {
        setShowConfirmError(false)
        toast.success(`We have sent you an email to ${email}, please check your inbox.`)
      }
    })
  };

  return (<>
    <Helmet>
      <title>Sign In | Ideasicle X</title>
    </Helmet>
    <UnauthenticatedLayout>
      <Formik
        initialValues={{
          email: location.state && location.state.user && location.state.user.email || '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            const result = await sessionsResource.signIn(values.email, values.password)
            dispatch({type: CURRENT_USER_FETCHED, payload: result.data})
            currentUser.set(result.data)
            if(location.state && location.state.isRespondingToJob && location.state.user.id === result.data.id) {
              const jobAcceptResult = await usersResource.updateCurrent({data: { user: {}, job_id: location.state.job.id, accept: location.state.isAcceptingJob }})
              navigateAfterRespondToJob(location.state.isAcceptingJob, location.state.job, history)
            } else {
              const nextPath = pathAfterSignIn.get() || '/'
              pathAfterSignIn.clear()
              history.push(nextPath)
            }
          } catch(error) {
            const { response } = error
            if(response && response.data && response.data.error) {
              toast.warn(response.data.error)
              if (response.data.error === "You must confirm your email address before continuing.") {
                setShowConfirmError(true)
              } else {
                setShowConfirmError(false)
              }
            } else {
              setShowConfirmError(false)
              toast.warn("Uh oh! Something went wrong. Please try again soon.")
              console.error("response:", response)
              console.error("error:", error)
            }
          } finally {
            setSubmitting(false)
          }
        }}
        render={({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className={styles.signInForm}
            data-testid="form"
            >

            <h2 className={styles.signInHeader}>Sign in</h2>


            {false && touched.email && errors.email && <div className="help" data-testid="error">
               {errors.email}
            </div>}

            {showConfirmError && (
              <div className={styles.resendConfirmationEmail} data-testid="error">
                <a onClick={() => {sendResetInstructions(values.email)}}>Resend Confirmation Email</a>
              </div>
            )}

            <input
              name="email"
              type="text"
              data-testid="email"
              value={values.email}
              onChange={(e) => { setFieldValue('email', e.target.value.trim() ); }}
              onBlur={handleBlur}
              placeholder="Email"
              className={styles.input}
            />
            <input
              name="password"
              type="password"
              data-testid="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
              className={styles.input}
            />
            <p style={{marginTop: '20px', fontStyle: 'italic', fontSize: '10px'}}>
              <Link style={{ color: 'black', textDecoration: 'none'}}
                data-testid="forgot-password"
                to={routes.FORGOT_PASSWORD}>Forgot Password?</Link>
            </p>
            <div className={styles.signInButtonContainer}>
              <p className={styles.signInButton}>
                <Button style={{borderColor: 'black', background: 'none'}} disabled={isSubmitting || !isValid} type="submit">Sign In</Button>
              </p>
            </div>
          </Form>
        )}
      />
    </UnauthenticatedLayout>
  </>)
}
