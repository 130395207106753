import React, { useState, useContext, useEffect } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import usersResource from 'resources/users'
import Loading from 'components/general/loading'
import * as routes from 'constants/routes'
import { HistoryProvider } from 'components/app/history'
import Avatar from 'components/general/avatar.jsx'
import moment from 'moment'
import { toast } from 'react-toastify';
import { pathAfterSignIn } from 'utils/local_storage'
import ExpertProfileEditor from './expert_profile_editor'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { currentUser } from 'utils/local_storage'
import {Helmet} from 'react-helmet'

function EditExpertProfile () {
  const { dispatch, state } = useContext(Store)
  const [fetchedUser, setFetchedUser] = useState()

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await usersResource.showCurrent()
        setFetchedUser(response.data)
        dispatch({type: CURRENT_USER_FETCHED, payload: response.data})
        currentUser.set(response.data)
      } catch(error) {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      }
    }
    fetchCurrentUser()
  }, [])

  const onSubmit = async (user, setSubmitting) => {
    try {
      const result = await usersResource.updateCurrent({data: { user }})
      dispatch({type: CURRENT_USER_FETCHED, payload: result.data})
      currentUser.set(result.data)
      toast.success('Your profile has been updated.')
      HistoryProvider.history.push('/')
      console.log(result.data)
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! Looks like something went wrong. Please try again soon.")
    } finally {
      setSubmitting(false)
    }
  }


  return (<>
    { fetchedUser && <ExpertProfileEditor user={fetchedUser} onSubmit={onSubmit} editMode={true} />}
  </>)
}

export default function EditProfile(props) {
  const { dispatch, state } = useContext(Store)

  if (state && HistoryProvider.history && (!state.currentUser || !state.currentUser.id)) {
    pathAfterSignIn.set()
    HistoryProvider.history.push({ pathname: routes.SIGN_IN })
  }

  return (<>
    <Helmet>
      <title>Edit Profile | Ideasicle X</title>
    </Helmet>
    { state.currentUser.role === 'expert' && <EditExpertProfile {...props} />}
  </>)
}
