import React, { useEffect, useContext, useState } from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import * as routes from '../../../constants/routes'
import sessionsResource from '../../../resources/sessions'
import usersResource from '../../../resources/users'
import organizationsResource from '../../../resources/organizations'
import { pathAfterSignIn, currentUser } from '../../../utils/local_storage'
import Logo from 'components/general/logo'
import Button from 'components/general/button'
import styles from '../shared/styles.module.css'
import Store from 'store'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { CUSTOMER_ONBOARDING, RESEND_CONFIRMATION } from 'constants/routes'
import { toast } from 'react-toastify';
import { navigateAfterRespondToJob } from 'components/expert_onboarding/index.jsx'
import UnauthenticatedLayout from '../shared/unauthenticated_layout.jsx'
import Loading from 'components/general/loading.jsx'

export default function SamlIdpSignIn ({ location, history, match }) {
  const { state } = useContext(Store)
  
  if (state?.currentUser?.id && history) {
    history.replace({ pathname: '/' })
  }

  const [idp, setIdp] = useState()

  useEffect(() => {
    const fetchIdp = async () => {
      try {
        const response = await organizationsResource.idp({ params: { idp: match.params.idp }})
        setIdp(response.data);
      } catch(e) {
        console.e.error(e)
        toast.warn("Uh oh! Something went wrong. Please try again soon.")
      }
    }
    fetchIdp()
  }, [])

  return (<>
    <Helmet>
      {idp?.organization?.name && <title>{idp?.organization?.name} Sign In | Ideasicle X</title>}
      {!(idp?.organization?.name) && <title>Sign In | Ideasicle X</title>}
    </Helmet>
    <UnauthenticatedLayout signUp={false} cobrandOrganization={idp?.organization}>
      
        <h2 className={styles.signInHeader}>Sign in</h2>

        <div className={styles.signInButtonContainer}>
          <p style={{ width: 292}}>
            {!idp && <Loading />}
            {idp && <>
              To continue to Ideasicle X, please click the button below. You'll be redirected to
              {idp?.organization ? ` the ${idp?.organization?.name} ` : ' your '}
              authentication provider to sign in.
            </>}
          </p>
          <p className={styles.signInButton}>
            <Button style={{borderColor: 'black', background: 'none'}} disabled={!idp} onClick={() =>{
              window.location = idp.idp_settings.idp_sso_service_url
            }}>
              Sign In
            </Button>
          </p>
        </div>
    </UnauthenticatedLayout>
  </>)
}
