import React from 'react'
import styles from './styles.module.css';
import { SvgBackArrow } from 'components/general/icons';
import { Link } from 'react-router-dom'

const BackArrowNav = ({ to, toLabel }) => {
  return (
    <Link className={styles.back} to={to}>
      <SvgBackArrow/> <span>{toLabel ? `Back to ${toLabel}` : children }</span>
    </Link>
  )
}

export default BackArrowNav
