import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../general/button'
import { Container, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify';
import * as routes from 'constants/routes'
import {Helmet} from 'react-helmet'
import MiniHeader from 'components/general/mini_header'
import { renderVideo } from 'components/jobs/show'
import Uploader from 'components/jobs/show/uploader'
import { SvgPdfIcon, SvgVideoIcon } from 'components/general/icons/index.jsx'
import FileList from 'components/jobs/show/file_list'
import ideaStyles from '../idea/styles.module.css'
import { SvgBackArrow } from '../../../components/general/icons'
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import useRoleRequired from 'utils/useRoleRequired'

export default function JobBuilderStep3 ({ history, location }) {
  useRoleRequired('customer')

  const [job, _setJob] = useState(location.state && location.state.job)

  const setJob = (job) => {
    _setJob(job)
    history.replace({
      pathname: location.pathname,
      state: {
        job: job,
      }
    })
  }

  useEffect(() => {
    if(!job) {
      history.push('/')
      toast.warn("Uh oh! We couldn't find the job you were working on. Please try again")
    }
    if(job.status !== 'created') {
      history.push({
        pathname: routes.JOB(job.slug),
        state: {
          job: job,
        }
      })
    }
    if(job.briefing_document_url && job.briefing_video_url && !location.state.preventRedirect) {
      history.push({
        pathname: routes.JOB_BUILDER_STEP_4_FINALIZE_TEAM,
        state: {
          job: job,
        }
      })
    }
  }, [])

  const handleBackButton = () => {
    history.push({
      pathname: routes.INVITE_MORE(job.id),
      state: {
        job: job,
      }
    })
  }

  const handleNextButton = (event) => {
    event.preventDefault()
    history.push({
      pathname: routes.JOB_BUILDER_STEP_4_FINALIZE_TEAM,
      state: {
        job: job,
      }
    })
  }

  if(!job) {
    // useEffect will redirect in a moment, but dont want to throw an error below
    return
  }

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              <div className={ideaStyles.back} onClick={handleBackButton}>
                <SvgBackArrow /> <span>Invite More Freelancers</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Helmet>
              <title>Start a Job | Ideasicle X</title>
            </Helmet>
            <Col>
              <h1>Step 3: Post Inspiring Briefing Materials</h1>
              <p>Inspire your team by uploading a single-page creative brief PDF and a simple, under 10-minute video explaining it to your team. These elements will show up at the top of the Idea Stream.</p>
              <p>
                <small>
                  Note: you must at least upload a PDF brief to move on, but we recommend both a video and a PDF for the best creative experience.
                </small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div style={{ border: '1px solid black', padding: 15, paddingTop: 15, paddingBottom: 30 }}>
                <MiniHeader text="Brief Materials" style={{marginBottom: 17 }} noBorder={true}></MiniHeader>
                <div>
                <Col sm={12} className={styles.briefingUploads}>
                  <div className={styles.fileListContainer}>
                    <Row className="mx-0">
                      <Col lg={5} sm={12}>
                        <div className="p-2 mr-1" style={{ border: '1px solid black', height: '100%', display: 'flex', flexDirection: 'column' }}>
                          { job.briefing_video_url && renderVideo(job) }
                          <div className="mt-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <Uploader accept="video/*" job={job} setJob={setJob} icon={SvgVideoIcon} name="Video" attribute="briefing_video" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={3} sm={12}>
                        <div className="p-2 ml-1 mr-1" style={{ border: '1px solid black', height: '100%', display: 'flex', flexDirection: 'column' }}>
                          { job.briefing_document_url &&
                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                              <a href={job.briefing_document_url} target="_blank">
                                {job.briefing_document_preview_url && <img src={`${job.briefing_document_preview_url}`} />}
                                {!job.briefing_document_preview_url && <Icon path={mdiDownload} style={{height: 32, width: 32}} />}
                              </a>
                            </div>}
                          <div className="mt-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <Uploader accept="image/*, .pdf" job={job} setJob={setJob} icon={SvgPdfIcon} name="Brief" attribute="briefing_document" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} sm={12}>
                        <div className="p-2 ml-1" style={{ border: '1px solid black', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        { job.other_documents.length > 0 && <FileList style={{flexDirection: 'column' }} files={job.other_documents} /> }
                          <div className="mt-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <Uploader job={job} setJob={setJob} icon={SvgPdfIcon} name="Supporting Files" attribute="other_documents" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                </div>
              </div>
            </Col>
          </Row>

          <div className={styles.buttons}>
            <Button disabled={!job.briefing_document_url} onClick={handleNextButton}>Next</Button>
            {!job.briefing_document_url && (
              <div className={styles.skip} onClick={handleNextButton}>
                <span>Skip for now</span><SvgBackArrow style={{ transform: 'rotate(180deg)', paddingRight: 8, paddingTop: 4 }} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
