import React, { useState } from 'react'
import MiniHeader from 'components/general/mini_header'
import { ExpandCollapseSectionButton } from '..'
import styles from '../styles.module.css'
import Config from 'utils/config'
import Avatar from 'components/general/avatar'
import { SvgMessageIcon } from 'components/general/icons'
import { Collapse } from 'react-bootstrap'
import AddDirectAccessUser from './AddDirectAccessUser'
import { SvgRemoveIcon } from 'components/general/icons'
import jobAccessLinksResource from 'resources/job_access_links'
import { toast } from 'react-toastify';

const ClientTeamSection = ({
  job,
  setJob,
  organizerCollapsedMobile,
  setOrganizerCollapsedMobile
}) => {
  const customerPerspective = job?.can_edit
  const [submitting, setSubmitting] = useState(false)

  const deleteJobAccessLink = async (jobAccessLink) => {
    if(submitting) { return }

    if (window.confirm(`Are you sure you want to remove ${jobAccessLink.user.fullName}'s from the team? They'll no longer be able to access this project.`)) {
      setSubmitting(true)
      try {
        const response = await jobAccessLinksResource.destroy({params: {id: jobAccessLink.slug }})
        setJob(response.data.job)
        toast.success(`${jobAccessLink.user.fullName} has been removed.`)
      } catch(error) {
        console.error('error in deleteJobAccessLink', error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (<>
    <MiniHeader text="Client Team" style={{marginBottom: 17 }}>
      <ExpandCollapseSectionButton isExpanded={organizerCollapsedMobile} setExpanded={setOrganizerCollapsedMobile} />
    </MiniHeader>
    <Collapse in={organizerCollapsedMobile} className={"d-lg-block "}>
      <div>
        <div className={styles.clientTeamMemberRow}>
          <div className={styles.organizerLeftCol}>
            <Avatar className={styles.organizerAvatar} record={job.creator} />
          </div>
          <div className={styles.organizerRightCol}>
            {Config.messaging && <div style={{float: 'right', cursor: 'pointer'}} >
              <SvgMessageIcon />
            </div>}
            <div className={styles.organizerDesignation}>Organizer</div>
            <div className={styles.organizerName}>{job.creator.fullName}</div>
          </div> 
        </div>
        {job.direct_access_users.sort((u1,u2) => u1.allows_edit ? 1 : -1).map((jobAccessLink) => 
          <div className={styles.clientTeamMemberRow} key={jobAccessLink.slug}>
            {customerPerspective && <div className={styles.removeButton} onClick={() => deleteJobAccessLink(jobAccessLink)} >
              <SvgRemoveIcon style={{height: 30, width: 30}} />
              <small style={{fontSize: 10}} >Remove</small>
            </div>}
            <div className={styles.organizerLeftCol}>
              <Avatar className={styles.organizerAvatar} record={jobAccessLink.user} />
            </div>
            <div className={styles.organizerRightCol}>
              {Config.messaging && <div style={{float: 'right', cursor: 'pointer'}} >
                <SvgMessageIcon />
              </div>}
              <div className={styles.organizerDesignation}>{jobAccessLink.allows_edit ? "Idea Director" : "Observer" }</div>
              <div className={styles.organizerName}>{jobAccessLink.user.fullName}</div>
            </div>
          </div>
        )}
        {customerPerspective && <AddDirectAccessUser job={job} setJob={setJob} />}
      </div>
    </Collapse>
  </>)
}

export default ClientTeamSection