import React, { Fragment, useState, useEffect } from 'react'
import useAttribute from '../../../utils/useAttribute'
import { daysFromNow, daysFromNowV2 } from '../../../utils/date'
import styles from './styles.module.css'
import Button from '../../general/button'
import * as ExpertInviteStep from './expert_invite_step'
import * as VitalsStep from './vitals_step'
import jobsResource from '../../../resources/jobs'
import { Container, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify';
import * as routes from 'constants/routes'
import Config from 'utils/config'
import moment from 'moment'
import {Helmet} from 'react-helmet'
import useRoleRequired from 'utils/useRoleRequired'

const STEPS = [VitalsStep, ExpertInviteStep]

const dateConverter = (rawValue) => new Date(`${rawValue} 00:00`)
const intConverter = (rawValue) => rawValue ? parseInt(rawValue) : 0
export const moneyConverter = (rawValue) => rawValue ? parseFloat(rawValue.replace(/[^\d.]/g,'')) : 0.0

export default function CreateJob ({ history }) {
  useRoleRequired('customer')
  
  const [ currentStep, setCurrentStep ] = useState(1)
  const [maxStep, setMaxStep] = useState(currentStep)
  const [_, forceRender] = useState(undefined)

  const [expertBudget, setExpertBudget] = useState(null)

  const [saving, setSaving] = useState(false)

  const calculateExpertBudget = () => {
    if(attributes.paymentType.value === 'equal') {
      setExpertBudget(attributes.numberOfExperts.value * attributes.perExpertBudget.value)
    } else if (attributes.paymentType.value === 'custom') {
      setExpertBudget(attributes.customizedBudget.value)
    } else if (attributes.paymentType.value === 'none') {
      setExpertBudget(0.0)
    }
  }

  if(Config.jobBuilderV3) {
    // Not using this component, redirect instead to the V3 builder
    history.replace(routes.JOB_BUILDER_STEP_1_VITALS)
  }

  const MONEY_ATTRIBUTES = ['customized_budget', 'per_expert_budget']

  /* Step 1 */
  const attributes = {}
  attributes.title = useAttribute('title', '', forceRender)
  attributes.mission = useAttribute('mission', '', forceRender)
  attributes.startDate = useAttribute('start_date', '', forceRender)
  attributes.endDate = useAttribute('end_date', '', forceRender)

  attributes.numberOfExperts = useAttribute('number_of_experts', 4, forceRender, {
    change: (value) => { const newValue = intConverter(value); return Math.min(newValue, 50) }, blur: intConverter
  })
  attributes.customizedBudget = useAttribute('customized_budget', 6000.00, forceRender, {
    change: moneyConverter, blur: moneyConverter })
  attributes.perExpertBudget = useAttribute('per_expert_budget', 1500.00, forceRender, {
    change: (value) => { const newValue = moneyConverter(value); return Math.min(newValue, 50.0 * 1000) }
  })
  attributes.paymentType = useAttribute('payment_type', 'equal', forceRender)

  /* Step 3 */
  attributes.myTeamIds = useAttribute('expert_ids', [], forceRender)
  attributes.myOwnFreelancerEmails = useAttribute('invite_freelancer_emails', [], forceRender, {
    focus: (value) => value.join(', '),
    blur: (rawValue) => rawValue.split(/\s*,\s*/).filter((email) => email)
  })
  attributes.myOwnEmployeeEmails = useAttribute('invite_employee_emails', [], forceRender, {
    focus: (value) => value.join(', '),
    blur: (rawValue) => rawValue.split(/\s*,\s*/).filter((email) => email)
  })

  useEffect(() => {
    calculateExpertBudget()
  }, [attributes.numberOfExperts.value, attributes.customizedBudget.value, attributes.perExpertBudget.value, attributes.paymentType.value])


  const handleInputChange = (attribute) => (
    (event) => {
      event.persist()
      attribute.setter(event.target.value)
    }
  )

  const handleBackButton = () => {
    if (currentStep < 2) return
    setCurrentStep(currentStep - 1)
  }

  const currentStepModule = STEPS[currentStep - 1]

  const handleNextButton = (event, isSubmit) => {
    event.preventDefault()
    if (currentStep > STEPS.length) return
    if (currentStepModule.validate(attributes)) {
      if(isSubmit) {
        handleSubmitButton()
      } else {
        setMaxStep(Math.max(currentStep + 1, maxStep))
        setCurrentStep(currentStep + 1)
        window.scrollTo(0,0)
      }
    }
  }

  const handleSubmitButton = async () => {
    const job = Object.values(attributes).reduce((buffer, attribute) => Object.assign(buffer, {[attribute.label]: attribute.value}), {})
    MONEY_ATTRIBUTES.forEach((attribute) => {
      job[`${attribute}_in_cents`] = job[attribute] * 100
      delete job[attribute]
    })
    job.start_date = moment(job.start_date, "MM/DD/YYYY").toDate()
    job.end_date = moment(job.end_date, "MM/DD/YYYY").toDate()
    try {
      setSaving(true)
      const response = await jobsResource.create({data: {job}})
      toast.success("Congrats! You've set up your job and invitations are on their way to your Freelancers.")
      history.push({
        pathname: routes.JOB(response.data.slug),
        state: {
          job: response.data,
        }
      })
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to create your job. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  const CurrentStepComponent = currentStepModule.component
  const CurrentStepTitleComponent = currentStepModule.title || (({ title }) => <Col><h1>{title.value}</h1></Col>)

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Helmet>
              <title>Start a Job | Ideasicle X</title>
            </Helmet>
            <CurrentStepTitleComponent title={attributes.title} />
          </Row>

          <div className={styles.hr} />

          <CurrentStepComponent attributes={attributes} expertBudget={expertBudget} />

          <div className={styles.buttons}>
            {currentStep > 1 ? <Button onClick={handleBackButton}>Back</Button> : null}
            {currentStep < STEPS.length ?
              <Button onClick={(e) => handleNextButton(e)}>Next</Button> :
              <Button type="primary" disabled={saving} onClick={(e) => handleNextButton(e, true)}>
                Invite Freelancers
              </Button>
            }
          </div>

          <div className={styles.stepXofY}>
            Step {currentStep} of {STEPS.length}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
