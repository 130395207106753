import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const ideasResource = resourceBuilder.build('/ideas', {
  ...defaultResourceSchema,
  search: {
    url: '/search',
    method: 'get'
  },
  toggleFlag: {
    url: '/{id}/toggle_flag',
    method: 'post'
  }
})

export default ideasResource
