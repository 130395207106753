import moment from 'moment'
import { config } from 'auto-type-cast'

config.afterTypeCast = (object) => {
  if(object.onInititalize) {
    object.onInititalize()
  }
}

export default class BaseModel {
  // allows axios-rest-resource to stringify models used in url formatting
  // e.g. if a resource specified the url: "/tasks/{task}", passing an instance
  // of Task whose id is "123-456" would hit the URL "/tasks/123-456".
  toString() {
    return this.id
  }

  empty() {
    return !this.id
  }

  createdAtMoment() {
    return moment(this.created_at)
  }

  updatedAtMoment() {
    return moment(this.updated_at)
  }

  onInititalize() { }
}
