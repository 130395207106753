import React, {} from 'react'
import styles from './styles.module.css'

export default function FileList ({files, headerText, ...props}) {
  return (
    <div {...props}>
      <span className={styles.uploadHeader}>
        {headerText}
      </span>

      { !Array.isArray(files) && <>
        <a
          href='#'
          style={{ display: 'block', color: 'black', fontSize: 14 }}
          onClick={(e) => { e.preventDefault(); window.open(files, files, `height=${window.screen.height},width=600,screenX=${window.screen.width - 600},screenY=0`) }}>
          Briefing Document
        </a>
      </>}

      { Array.isArray(files) && <>
        {files.map(file => (
          <a
            key={file.filename}
            href='#'
            style={{marginBottom: 5, display: 'block', color: 'black', fontSize: 14}}
            onClick={(e) => { e.preventDefault(); window.open(file.url, file.url, `height=${window.screen.height},width=600,screenX=${window.screen.width - 600},screenY=0`) }}>
            {file.filename}
          </a>
        ))}
      </>}
    </div>
  )
}
