import React, { useState, useContext, useEffect, useRef } from 'react'
import Store from 'store'
import styles from './styles.module.css'
import classnames from 'classnames'
import * as routes from 'constants/routes'
import RichContent from './rich_content.jsx'
import Avatar from 'components/general/avatar'
import { SvgPencilIcon, SvgTrashIcon } from 'components/general/icons'
import Editor from '../../editor/index.jsx'
import commentsResource from 'resources/comments'
import { toast } from 'react-toastify';

export default function Comment (props) {
  const { job, comment, deleteComment, disableActions, richContentProps, ...wrapperProps } = props
  const { state } = useContext(Store)
  const [editing, setEditing] = useState(false)
  const [editorInteracting, setEditorInteracting] = useState(false)
  const editorRef = useRef()
  const [saving, setSaving] = useState(false)

  const updateComment = async () => {
    try {
      setSaving(true)
      const editorHtmlOutput = editorRef.current.getHtml()
      const editorState = editorRef.current.getSerializedEditorState()
      const response = await commentsResource.update({ params: { id: comment.id }, data: {
        comment: { body: editorHtmlOutput, editor_state: editorState }
      }})

      comment.body = editorHtmlOutput
      comment.editor_state = editorState
      setEditing(false)
      toast.success("Your riff has been updated!")
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to update your riff. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className={styles.commentWrapper} {...wrapperProps}>
      { !disableActions && job?.can_edit &&
        <div className={styles.actions}>
        <SvgTrashIcon className={styles.deleteButton} onClick={()=>props.deleteComment(comment.id)}/>
      </div>
      }
      { !disableActions && job.status !== "closed" && comment.editor_state &&
        <div className={styles.actions}>
        <SvgPencilIcon className={styles.editButton} onClick={()=> setEditing(true) }/>
      </div>
      }
      <div className={styles.commentLeftCol}>
        <Avatar className={styles.commentAvatar} record={comment.author} />
      </div>
      <div className={styles.commentRightCol}>
        <div className={styles.commentAuthor}>{comment.author.fullName}</div>
        <div className={styles.commentContent}>
          { !editing && <RichContent content={comment.body} {...(richContentProps || {})} />}
        </div>

        <div className={styles.commentDate}>
          {comment.createdAtFormatted}
        </div>
      </div>

      { editing && <>
        <Editor
          ref={editorRef}
          onInteracting={setEditorInteracting}
          initialState={comment.editor_state}
        />
        <button type="button"
          style={{ marginTop: 15 }}
          className={classnames("btn btn-slim mr-3", styles.postButton)}
          onClick={() => setEditing(false) }
          disabled={saving || editorInteracting}>
          Cancel
        </button>
        <button type="button"
          style={{ marginTop: 15 }}
          className={classnames("btn btn-primary btn-slim", styles.postButton)}
          onClick={updateComment}
          disabled={saving || editorInteracting}>
          Save
        </button>
      </>}
    </div>
  )
}
