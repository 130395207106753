import resourceBuilder from './resource_builder'

const statsResource = resourceBuilder.build('/admin', {
  platform: {
    url: '/platform_stats',
  },
  customers: {
    url: '/customer_stats',
  },
  users: {
    url: '/users',
  },
})

export default statsResource
