import React, {} from 'react'
import tileStyles from '../../dashboard/tiles.module.css'
import styles from './styles.module.css'
import classnames from 'classnames'
import IdeaTile from './idea_tile'
import ReturnToTop from 'components/general/return_to_top';


export default function IdeaList ({job, ideas, createIdea, setJob, emptyContent, ...props}) {
  return (
    <div {...props} style={{ marginBottom: 12}}>
      {ideas.length == 0 && <div className={classnames(styles.ideaTile, tileStyles.empty_tile)} style={{width: '100%'}}>
        <span style={{marginTop:15, marginBottom: 15, fontSize: 18}}>
          { emptyContent || 'No one has started an Idea yet. Why not be the first?' }
        </span>
        { !emptyContent && job.canParticipate && <button className="btn btn-primary btn-slim" type="button" onClick={createIdea} style={{marginBottom: 15}} disabled={ job.status === "closed" ? true : null}>
        { job.status === "closed" ? "This job is Closed" : "Start New Idea"}
        </button>}
      </div>}
        {ideas.length > 0 && ideas.map((idea) => <IdeaTile key={idea.id} job={job} setJob={setJob} idea={idea} history={props.history} />)}

      <ReturnToTop />
    </div>
  )
}
