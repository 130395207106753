import * as Sentry from '@sentry/browser'

export const initErrorTracking = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: "https://e4a73761539949cf9e02452f581030c7@sentry.io/2114252",
      // todo: track git revision
      //release: process.env.REV,
      environment: process.env.NODE_ENV,
    })
  }
}

export const setUserContext = user => {
  const sentryUser = Object.assign({}, user)

  delete sentryUser['ability_rules']

  Sentry.configureScope(function(scope) {
    scope.setUser(sentryUser)
  })
}
