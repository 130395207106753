import React, { useState, useEffect, useContext } from 'react'
import SignedInBar from 'components/general/signed_in_bar'
import styles from '../customer_onboarding/styles.module.css'
import { Row, Col } from 'react-bootstrap'
import queryString from 'query-string'
import snakeCase from 'snakecase-keys'
import usersResource from 'resources/users'
import invitationsResource from 'resources/invitations'
import { JOB, SIGN_IN } from 'constants/routes'
import { pathAfterSignIn, currentUser } from 'utils/local_storage'
import Store from 'store'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { toast } from 'react-toastify';
import Loading from 'components/general/loading.jsx'
import ExpertProfileEditor from 'components/profile/expert_profile_editor'

export function navigateAfterRespondToJob(isAcceptingJob, job, history) {
  // Note that job may be null
  // todo: should go to job is isAcceptingJob && job?
  const nextPath = pathAfterSignIn.get('/')
  pathAfterSignIn.clear()
  history.push(nextPath)
  if(!isAcceptingJob) {
    toast.warn(`Thanks. We'll let the organizer know you aren't interested in the job.`)
  }
}

function ExpertOnboarding ({location, history}) {
  const { dispatch, state } = useContext(Store)
  const [loading, setLoading] = useState(true)
  const [job, setJob] = useState(null)

  const query = queryString.parse(location.search)
  const invitationToken = query.token
  const isAcceptingJob = query.reject !== 'true'
  const expertType = query.expert_type || 'freelancer'

  useEffect(() => {
    const acceptPlatformInvitation = async () => {
      try {
        const response = await invitationsResource.update({ params: { id: invitationToken, job_id: query.job_id, accept: isAcceptingJob  }})
        if(response.data.needs_sign_in) {
          history.push({ pathname: SIGN_IN, state: { isRespondingToJob: true, user: response.data.user, job: response.data.job, isAcceptingJob }})
        }
        else if(response.data.needs_onboarding) {
          dispatch({type: CURRENT_USER_FETCHED, payload: response.data.user})
          currentUser.set(response.data.user)
          setJob(response.data.job)
        } else {
          // job was accepted or rejected already
          navigateAfterRespondToJob(isAcceptingJob, response.data.job, history)
        }
      } catch (error) {
        console.error(error)
        toast.warn("Uh oh! It looks like that invitation is no longer valid.")
      }
      finally {
        setLoading(false)
      }
    }
    acceptPlatformInvitation()
  }, [])

  const onSubmit = async (user, setSubmitting) => {
    try {
      const result = await usersResource.updateCurrent({data: { user, job_id: job?.id, accept: isAcceptingJob }})
      dispatch({type: CURRENT_USER_FETCHED, payload: result.data})
      currentUser.set(result.data)
      navigateAfterRespondToJob(isAcceptingJob, job, history)
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! Looks like something went wrong. Please try again soon.")
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <SignedInBar unauthenticatedMode="light">
      {loading && <Loading />}
      {!loading && <div className={styles.expertInfoBox}>
        <Row>
          <Col lg={3} md={4} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={require('./expert-wheel.gif')} className={styles.expertInfoImage} alt="Ideasicle X Freelancers" />
          </Col>
          <Col lg={9} md={8} sm={12} className="pl-md-0 px-5" style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <div className={styles.infoBoxHero}>
              Congratulations!
            </div>
            <div className={styles.infoBoxSubhero}>
              For being invited to your first Ideasicle X job. Pretty cool.
            </div>
            <div className={styles.infoBoxInfoText}>
              Just fill out your profile below to get started. Be sure to fill out all of it and review the Terms of Service and Freelancer Agreement or the “Save” button will not work. And keep in mind you can edit your profile later.
            </div>
          </Col>
        </Row>
      </div>}
      {!loading && state.currentUser && <ExpertProfileEditor user={state.currentUser} expertType={expertType} onSubmit={onSubmit} />}
    </SignedInBar>
  )
}

export default ExpertOnboarding
