import React, { Fragment, useState, useContext, useMemo } from 'react'

const DiscountedAmount = ({amount, discountedAmount, discountColor}) => {
  const discounted = useMemo(() => amount !== discountedAmount && discountedAmount, [amount,discountedAmount])

  return (
    <>
      {discounted && <><s>{amount}</s> <span style={{color: discountColor || '#009245'}}>{discountedAmount}</span></>}
      {!discounted && <>{amount}</>}
    </>
  )
}

export default DiscountedAmount
