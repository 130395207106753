import React, { useState, useContext, useEffect } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import notificationSettingsResource from 'resources/notification_settings'
import Loading from 'components/general/loading'
import * as routes from 'constants/routes'
import styles from './styles.module.css'
import usersResource from 'resources/users'
import { toast } from 'react-toastify';
import {Helmet} from 'react-helmet'

const NOTIFICATION_TYPE_METADATA = {
  customer: {
    job_needs_input: {
      heading: 'Job Needs Attention',
      label: 'Additional information is needed in order to begin a Job'
    },
    job_expires_soon_customer: {
      heading: 'Job Ends Soon',
      label: 'One of my Jobs is ending in the next 24 hours'
    },
    new_idea_posted: {
      heading: 'New Idea Posted',
      label: 'A new Idea has been posted on one of my Jobs'
    },
    idea_comment: {
      heading: 'New Build Posted',
      label: 'A Build has been posted in an Idea of one of my Jobs'
    },
  },
  expert: {
    experts_locked: {
      heading: 'Chosen for Job',
      label: 'An organization has confirmed my participation in an upcoming Job'
    },
    not_chosen: {
      heading: 'Not Chosen for Job',
      label: 'A Job I was invited to did not include me in its Freelancer team'
    },
    job_starts: {
      heading: 'Job Has Begun',
      label: 'A Job I am participating in has begun and I can get started'
    },
    job_expires_soon_expert: {
      heading: 'Job Ends Soon',
      label: 'One of my Jobs is ending in the next 24 hours'
    },
    idea_comment: {
      heading: 'New Build Posted',
      label: 'A Build has been posted in an Idea of one of my Jobs'
    },
    payment_received: {
      heading: 'Payment Received',
      label: 'I received a payment for one of my Jobs'
    },
  },
}

export default function NotificationSettingsPage ({ history }) {
  const { dispatch, state } = useContext(Store)
  const [notificationSettings, setNotificationSettings] = useState(null)
  const [dirty, setDirty] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const fetchNotificationSettings = async () => {
      try {
        const response = await notificationSettingsResource.index()
        setNotificationSettings(response.data)
      } finally {
        setLoading(false)
      }
    }
    fetchNotificationSettings()
  }, [])

  const setValue = (e) => {
    notificationSettings[e.target.name] = e.target.checked
    setNotificationSettings(Object.assign({}, notificationSettings))
    setDirty(true)
  }

  const save = async () => {
    try {
      setSubmitting(true)
      const result = await usersResource.updateCurrent({data: { user: { notification_settings: notificationSettings }}})
      toast.success("Your settings have been saved.")
      history.push('/')
    } catch (e) {
      console.error(e)
      toast.warn("Uh oh! Something went wrong. Please try again shortly.")
    } finally {
      setSubmitting(false)
    }
  }

  const metadata = state.currentUser && NOTIFICATION_TYPE_METADATA[state.currentUser.role]

  return (
    <Container>
      <Row>
        <Col>
          <Helmet>
            <title>Notification Settings | Ideasicle X</title>
          </Helmet>
          <ContentHeader text="My Notification Settings">
            <button className="btn btn-primary btn-slim" type="button" disabled={!dirty || submitting} onClick={save}>
              Save Settings
            </button>
          </ContentHeader>
            { loading && <Loading /> }
            { !loading && <div>
              <div className={styles.subheader}>Email me when any of the following events occur:</div>
              <Row>
                {Object.keys(metadata).map(k => <>
                  <Col xs={6} key={k}>
                    <div className={styles.checkboxWrapper}>
                      <input type="checkbox"
                        checked={notificationSettings.hasOwnProperty(k) ? notificationSettings[k] : true}
                        onChange={setValue}
                        className={styles.checkbox} name={k} id={k}  />
                      <label htmlFor={k}>
                        <span className={styles.primaryLabel}>{metadata[k].heading}</span>
                        <br />
                        <span className={styles.secondaryLabel}>{metadata[k].label}</span>
                      </label>
                    </div>
                  </Col>
                </>)}
              </Row>
            </div>}
        </Col>
      </Row>
    </Container>
  )
}
