import React from 'react';
import { Modal } from 'react-bootstrap';

const ArchiveModal = ({ onClose, onConfirm }) => {
  return (
    <Modal backdrop="static" keyboard={false} show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Archive Talent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>Are you sure you want to remove this person from the pool?</h2>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-danger' onClick={onConfirm}>
          Confirm
        </button>
        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveModal;
