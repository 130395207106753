import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import organizationsResource from 'resources/organizations';
import { toast } from 'react-toastify';
import styles from './styles.module.css';
import { Formik, Form, Field, FieldArray } from 'formik';
import classnames from 'classnames';
import * as yup from 'yup'
import useSubscriptionMetadata from 'utils/useSubscriptionMetadata'
import { useInviteConfirmationModal } from './invite_confirmation_modal'

const initialEmails = ['', '', '', ''] // 4 email boxes
const emailValidator = yup.string().email('Email is invalid')
const validationSchema = yup.object().shape({
  emails: yup.array().compact().min(1).of(emailValidator)
})

export const onlyValidEmails = (emails) => {
  return  emails.filter(e => e.trim().length && emailValidator.isValidSync(e))
}

const AddCommandCentersTile = ({ organization, onAddUsers, isChangingTeamState }) => {
  const [isChangingTeam, setIsChangingTeam] = isChangingTeamState

  const { maxCommandCentersAllowed } = useSubscriptionMetadata(organization)

  const addTeamMebers = useCallback(async ({ emails }, { resetForm }) => {
    setIsChangingTeam(true)
    try {
      const validEmails = onlyValidEmails(emails)
      if(maxCommandCentersAllowed && organization.users.length + validEmails.length > maxCommandCentersAllowed) {
        const message = `Your subscription plan is limited to ${maxCommandCentersAllowed} Command Centers. Please visit the Account Status page to add Command Centers to your plan.`
        toast.warn(message, { autoClose: 15000 })
        return
      }

      const response = await organizationsResource.addUsers({ data: { emails: validEmails } })
      onAddUsers({ organization: response.data.organization })
      resetForm()
      if(response.data.any_invitations_sent) {
        toast.success("Your invitations have been sent.")
      }
      if(response.data.ineligible_emails.length) {
        let message = `It looks like ${response.data.ineligible_emails.length} of those emails were ineligible. `
        message += response.data.any_invitations_sent ? "Please review the invitations that were sent below." : 'Please try different email addresses for your Command Centers.'
        toast.warn(message, { autoClose: 15000 })
      }
      if(response.data.invalid_emails.length) {
        let message = `It looks like ${response.data.invalid_emails.length} of those emails were invalid. `
        message += response.data.any_invitations_sent ? "Please review the invitations that were sent below." : 'Please try again with valid email addresses.'
        toast.warn(message, { autoClose: 15000 })
      }
    } catch (e) {
      console.error(e)
      toast.warn("Uh oh! We weren't able to finish adding Command Centers. Please try again soon.")
    } finally {
      setIsChangingTeam(false)
      closeModal()
    }
  }, [])

  const { openModal, closeModal, ModalComponent: ConfirmModal } = useInviteConfirmationModal({ organization, onConfirm: addTeamMebers })

  return (
    <>
      <Formik
      initialValues={{ emails: initialEmails }}
      onSubmit={openModal}
      validateOnMount={true}
      validationSchema={validationSchema}
      render={({ values, errors, isValid }) => {

        return (
          <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3 style={{ fontSize: 19 }}>Add Command Centers</h3>
            <p style={{ textAlign: 'center', marginTop: 0, marginBottom: 12, marginTop: 8 }}>Invite others in your organization to activate their own Command Centers using your billing information:</p>
            <Row style={{ width: '100%', justifyContent: 'center', flexGrow: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <FieldArray
                  name="emails"
                  render={arrayHelpers => (<>
                    <Row>
                      {values.emails.map((email, index) => (<Col xs={6} key={index}>
                        <Field
                          className={classnames("text-input", styles.inviteInput, { 'error-border': errors.emails && email.trim().length && !emailValidator.isValidSync(email) })}
                          name={`emails.${index}`} placeholder="john.doe@gmail.com" />
                      </Col>))}
                    </Row>
                  </>)}
                />
              </div>
            </Row>
            <div className={styles.buttonSection}>
              <button type="submit" disabled={!isValid || isChangingTeam} className={styles.actionBtn}>Invite</button>
            </div>
          </Form>
        )
      }}
    />
      <ConfirmModal />
    </>
  )
}

export default AddCommandCentersTile
