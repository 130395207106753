import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

let { create, update } = defaultResourceSchema
create.then = (response) => response

const usersResource = resourceBuilder.build('/users', {
  create,
  confirmEmail: {
    url: '/confirmation',
    call: (confirmation_token) => {
      const params = { confirmation_token }
      return { params }
    },
  },
  showCurrent: {
    method: 'get',
    url: '/me'
  },
  updateCurrent: {
    ...update,
    url: null,
  }
})

export default usersResource
