import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styles from './styles.module.css'
import Config from 'utils/config'
import jobAccessLinksResource from 'resources/job_access_links'
import { toast } from 'react-toastify'
import pluralize from 'pluralize';
import { useEffect } from 'react'

const InviteTable = ({ emails, setEmails, submitting, onSubmit }) => {
  const handleChange = (event, index) => {
    const { value } = event.target;
    emails[index] = value
    setEmails([...emails])
  }

  return (<table style={{ marginTop: 12, width: '100%' }}>
    <thead>
      <tr>
        <th className={styles.tableHeader}>Email: </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {emails.map((email, index) => {
        return (<tr>
          <td style={{ width: '55%' }}>
            <input className={styles.emailInput} placeholder="jane.doe@website.com"
              value={email} onChange={(e) => handleChange(e, index)}
            />
          </td>
          <td style={{ paddingLeft: 30 }}> 
          {index === emails.length - 1 && <button disabled={submitting} className="btn btn-primary btn-slim" type="button" onClick={() => onSubmit(emails)}>
            Invite
          </button>}
          </td>
        </tr>)
      })}
    </tbody>
  </table>)
}

const AddDirectAccessUserModal = ({ job, setJob, show, onClose }) => {
  if(!job) return null

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = (allowEdit) => {
    const submitHandler = async (emails) => {
      setSubmitting(true)
      try {
        const response = await jobAccessLinksResource.create({
          data: { job_access_links: { emails, allows_edit: allowEdit }},
          params: { job_id: job.id }
        })
        setJob(response.data.job)
        toast.success(`Your ${pluralize('invitation', emails)} have been sent.`)
        onClose()
      } catch(e) {
        console.error("Failed to create access links", e)
        toast.warn("Uh oh! We weren't able to send your invitations. Please try again soon.")
      } finally {
        setSubmitting(false)
      }
    }

    return submitHandler
  }

  const remainingIdeaDirectors = Config.jobDirectAccessUsers.ideaDirectorsMax - job.ideaDirectorUsers.length
  const remainingObservers = Config.jobDirectAccessUsers.observersMax - job.observerUsers.length

  const [ideaDirectorEmails, setIdeaDirectorEmails] = useState(
    Array.from({ length: remainingIdeaDirectors }, () => '')
  )
  const [observerEmails, setObserverEmails] = useState(
    Array.from({ length: remainingObservers }, () => '')
  )

  useEffect(() => {
    setIdeaDirectorEmails(Array.from({ length: remainingIdeaDirectors }, () => ''))
  }, [remainingIdeaDirectors])

  useEffect(() => {
    setObserverEmails(Array.from({ length: remainingObservers }, () => ''))
  }, [remainingObservers])

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      style={{border: '2px solid black'}}
    >
      <Modal.Header closeButton>
      <Modal.Title>Invite Colleagues To Idea Stream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className={styles.modalHeading}>Idea Director</h3>
        <span>
          The Idea Director's role is to evaluate the Idea Team's ideas, suggest course-corrections, and post his or her own ideas and builds. Only one Idea Director is permitted so we don't overwhelm the Idea Team. Note the Idea Director will be visible to the Idea Team.
        </span>
        { !!ideaDirectorEmails.length &&
          <InviteTable emails={ideaDirectorEmails} setEmails={setIdeaDirectorEmails}
            submitting={submitting} onSubmit={onSubmit(true)} />
        }
        { !ideaDirectorEmails.length && <p>You already have an Idea Director on your team.</p>}
        <hr className={styles.divider} />
        <h3 className={styles.modalHeading}>Observer</h3>
        <span>
        As the Organizer you can invite up to 3 Observers to your Idea Stream. They will be able to see the entire Idea Stream, but will not be able to post, comment, or interact in any way. Note the Observers will be invisible to the Idea Team.
        </span>
        { !!observerEmails.length &&
          <InviteTable emails={observerEmails} setEmails={setObserverEmails}
            submitting={submitting} onSubmit={onSubmit(false)} />
        }
        { !observerEmails.length && <p>You already have {Config.jobDirectAccessUsers.observersMax} Observers on your team.</p>}
      </Modal.Body>
    </Modal>
  )
}

export default AddDirectAccessUserModal
