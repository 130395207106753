import React, { useRef, useState, useContext } from 'react'
import SignedInBar from 'components/general/signed_in_bar'
import styles from '../customer_onboarding/styles.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import { SvgPlusIcon } from 'components/general/icons'
import classnames from 'classnames'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import ImageUploader from 'react-images-upload'
import snakeCase from 'snakecase-keys'
import usersResource from 'resources/users'
import * as routes from 'constants/routes'
import { pathAfterSignIn, currentUser } from 'utils/local_storage'
import Store from 'store'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { toast } from 'react-toastify';
import { DirectUploadProvider } from 'react-activestorage-provider'
import ProgressBar from 'components/general/progress_bar'

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  tos: yup.boolean().oneOf([true], 'Must accept Terms of Use to proceed')
})

function GenericUserOnboarding ({location, elements, history}) {
  const { dispatch, state } = useContext(Store)

  const avatarUploader = useRef(null)

  const [profileAvatarSignedId, setProfileAvatarSignedId] = useState(null)
  const [profileAvatarFileUrl, setProfileAvatarFileUrl] = useState(null)

  const onAvatarUpload = (setFileUrl, handleUpload) => (files) => {
    const image = files[files.length - 1]
    if (!image) {
      if(avatarUploader.current.state.notAcceptedFileType.length) {
        toast.warn("Sorry! That file type is not supported.")
        avatarUploader.current.state.notAcceptedFileType.pop()
      }
      if(avatarUploader.current.state. notAcceptedFileSize.length) {
        toast.warn("Uh oh! This image is too large. Please upload an image less than 100 MB")
        avatarUploader.current.state. notAcceptedFileSize.pop()
      }
      return
    }
    avatarUploader.current.state.files.pop()
    setFileUrl(URL.createObjectURL(image))
    handleUpload([image])
  }

  const onAvatarUploadSuccess = (setSignedId) => (signedIds) => {
    setSignedId(signedIds[0])
  }

  const job = location.state.job

  return (
    <SignedInBar unauthenticatedMode="dark">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          tos: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          setSubmitting(true)

          const user = snakeCase(values)
          if(values.tos) {
            user.tos_acception_date = new Date()
            delete user.tos
          }
          user.avatar = profileAvatarSignedId

          const data = {
            user: {
              ...user,
              email: location.state.email,
            }
          }
          try {
            const result = await usersResource.create({ data })
            dispatch({type: CURRENT_USER_FETCHED, payload: result.data})
            currentUser.set(result.data)
            pathAfterSignIn.clear()
            history.replace({
              pathname: routes.JOB(job.slug),
              state: { job }
            })
          } catch(error) {
            console.error(error)
            const { response } = error
            if(response && response.data && response.data.errors && response.data.errors.email) {
              const message = 'Email ' + response.data.errors.email
              toast.warn(message)
            }
          } finally {
            setSubmitting(false)
          }
        }}
        render={({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          setFieldError,
          validateForm
        }) => {
          return (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col>
                  <div className={styles.box}>
                    <div className="info-alert">
                      Before proceeding, please tell us about yourself. You'll only need to do this once.
                      <br />
                      <a href="mailto:willb@ideasicle.com">Email us with questions</a>
                    </div>

                    <div className={classnames(styles.row, 'row')}>
                      <Col md={3} xs={12} style={{marginBottom: 50}}>
                        <DirectUploadProvider directUploadsPath="/api/v1/direct_uploads" onSuccess={onAvatarUploadSuccess(setProfileAvatarSignedId)} render={({ handleUpload, uploads, ready }) => (
                          <div className={classnames(styles.upload_wrapper, {[styles.upload_wrapper_disabled]: !ready})}
                            onClick={() => { ready && avatarUploader.current.triggerFileUpload() }}>
                            <div style={{ visibility: 'hidden', position: 'absolute' }}>
                              <ImageUploader
                                ref={avatarUploader}
                                onChange={onAvatarUpload(setProfileAvatarFileUrl, handleUpload)}
                                singleImage={true}
                                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                                maxFileSize={1024 * 1024 * 100}
                              />
                            </div>
                            <SvgPlusIcon className={styles.upload_button}/>
                            {!profileAvatarFileUrl && <div className={styles.upload_box}>
                              Avatar
                            </div>}
                            {profileAvatarFileUrl && <img src={profileAvatarFileUrl} style={{ maxHeight: '100%', maxWidth: '100%', opacity: ready ? 1 : 0.3 }} />}
                            {uploads.map(upload => {
                              if(upload.state === 'waiting' || upload.state === 'uploading') {
                                return (<ProgressBar width={100} height={8} percentage={upload.progress} className={styles.uploadProgressBar} />)
                              }
                            })}
                          </div>
                        )} />
                      </Col>
                      <Col md={9} xs={12}>
                        <Row>
                          <Col lg={6} xs={12}>
                            <div style={{position: 'relative'}}>
                              {touched.firstName && errors.firstName && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                                {errors.firstName}
                              </span>}
                              <input
                                name="firstName"
                                type="text"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="First name"
                                className={styles.input}
                                tabIndex={1}
                              />
                            </div>
                          </Col>
                          <Col lg={6} xs={12}>
                            <div style={{position: 'relative'}}>
                              {touched.lastName && errors.lastName && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                                {errors.lastName}
                              </span>}
                              <input
                                name="lastName"
                                type="text"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Last name"
                                className={styles.input}
                                tabIndex={2}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </div>

                    <div className={classnames('row')}>
                      <Col xs={12} style={{textAlign: 'center'}}>
                        <div style={{marginBottom: 30}}>
                          <Field type="checkbox" name="tos" id="tos" style={{position: 'relative', top: -2}} />
                          <label htmlFor="tos">
                            I have read and accept the <a href={routes.TOU} target="_blank">Terms of Use</a>.
                          </label>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={!isValid || !dirty || isSubmitting}>
                          {isSubmitting && <div className="spin-grow light"></div>}
                          Continue
                        </button>
                      </Col>
                    </div>
                  </div>

                </Col>
              </Row>
            </Container>
          </Form>
        )}}
      />
    </SignedInBar>
  )
}

export default GenericUserOnboarding
