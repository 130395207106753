import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Store from 'store';
import orgCustomDomainResource from 'resources/organization_custom_domains';
import SetCNAMEModal from './SetCNAMEModal';
import styles from './styles.module.css';

const validationSchema = object().shape({
  domain: string().trim().required('Domain is required'),
});

const CustomDomain = () => {
  const { state } = useContext(Store);
  const [cname, setCNAME] = useState('');
  const [openCNAMEModal, setOpenCNAMEModal] = useState(false);

  const handleSave = async (values) => {
    try {
      const response = await orgCustomDomainResource.create({ data: { custom_domain: values.domain } });
      setCNAME(response.data.cname);
      setOpenCNAMEModal(true);
    } catch (err) {}
  };

  const { values, touched, errors, isSubmitting, handleChange, handleSubmit } = useFormik({
    initialValues: { domain: state.currentUser?.organization?.domain },
    validationSchema,
    onSubmit: handleSave,
  });

  return (
    <>
      <div>
        <div className={styles.subheader}>Enter a custom domain name that you own:</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ position: 'relative' }}>
            <input
              name="domain"
              className={styles.domainInput}
              placeholder="ix.example.com"
              value={values.domain}
              onChange={handleChange}
            />
            {Boolean(touched.domain && errors.domain) && <span className={styles.error}>{errors.domain}</span>}
          </div>
          <button className="btn btn-primary" type="button" disabled={isSubmitting} onClick={handleSubmit}>
            Save Domain
          </button>
        </div>
      </div>
      {openCNAMEModal && (
        <SetCNAMEModal cname={cname} domain={values.domain} onClose={() => setOpenCNAMEModal(false)} />
      )}
    </>
  );
};

export default CustomDomain;
