import ability from './ability'
import jwtDecode from 'jwt-decode'

export const setAuthTokenFromHeader = (authHeader) => {
  if (authHeader && authHeader.startsWith('Bearer ')){
    const token = authHeader.substring(7, authHeader.length)
    localStorage.setItem('token', token)
    setPrivileges()
  }
}

export const setInitialToken = () => {
  if (!localStorage.getItem('token')) {
    clearToken()
  }
}

export const clearToken = () => {
  localStorage.removeItem('token')
}

export const getAuthToken = () => {
  return localStorage.getItem('token')
}

export const getAuthTokenPayload = () => {
  const token = getAuthToken()
  if (!token) {
    return
  }
  return jwtDecode(token)
}

export const setPrivileges = () => {
  let token_payload = getAuthTokenPayload()
  if (token_payload) {
    ability.update(token_payload.ability_rules)
  }
}

export const requestHeaders = () => {
  const authToken = getAuthToken()
  if (authToken !== null && authToken !== '') {
    return {
      authorization: `Bearer ${authToken}`
    }
  }
}
