import React, { useContext, useEffect } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import jobAccessLinksResource from 'resources/job_access_links'
import Loading from 'components/general/loading'
import * as routes from 'constants/routes'
import { toast } from 'react-toastify';
import { currentUser } from 'utils/local_storage'
import { CURRENT_USER_FETCHED } from 'constants/actions'

export default function HandleJobAccessLink ({ history, match, location }) {
  const { dispatch } = useContext(Store)

  const fetchJobAccessLink = async () => {
    try {
      const { jobAccessLinkId } = match.params
      const response = await jobAccessLinksResource.show({ params: { id: jobAccessLinkId }})
      const { job, current_user, email } = response.data
      const needsOnboarding = !current_user

      // see job_access_links_controller.rb for cases handled here
      // and expected outcomes

      if(needsOnboarding) {
        history.replace({
          pathname: routes.GENERIC_USER_ONBOARDING,
          state: { job, email }
        })
      } else {
        dispatch({type: CURRENT_USER_FETCHED, payload: current_user })
        currentUser.set(current_user)
        history.replace({
          pathname: routes.JOB(job.slug),
          state: { job }
        })
      }

    } catch(err) {
      console.log('err',err)
      if(err.response.status === 401) {
        toast.warn("Uh oh! You're not allowed to see that.")
      } else {
        toast.warn("Uh oh! We couldn't find that link.")
        console.error('Error fetching job access link', err)
      }
      history.push('/')
    }
  }

  useEffect(() => {
    fetchJobAccessLink();
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <Loading />
        </Col>
      </Row>
    </Container>
  )
}
