import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Branding = ({ values, onChange }) => {
  return (
    <div>
      <div className={styles.subheader}>Edit your own logo and branding colors:</div>
      <Row>
        <Col xs={12} className={styles.brandingCol}>
          <label>
            <span className={styles.primaryLabel}>Select your brand color,</span>
            <br />
            <span className={styles.secondaryLabel}>This will be applied for background color of Idea portal.</span>
          </label>
          <div>
            <div className={styles.colorPickerContainer}>
              <input className={styles.colorPicker} type="color" />
            </div>
          </div>
        </Col>
        <Col xs={12} className={styles.brandingCol}>
          <label>
            <span className={styles.primaryLabel}>
              Upload your company logo that contrasts well with your brand's color
            </span>
            <br />
            <span className={styles.secondaryLabel}>We recommend a png with 80px height.</span>
          </label>
          <div className={styles.logoPreviewContainer}>
            <label className={styles.logoBtn}>
              Upload logo
              <input
                hidden
                type="file"
                value=""
                accept="image/png,image/jpg,image/jpeg,image/svg+xml"
                onChange={onChange}
              />
            </label>
          </div>
        </Col>
        <Col xs={12} className={styles.brandingCol}>
          <label>
            <span className={styles.primaryLabel}>Link to your Terms of Service</span>
            <br />
            <span className={styles.secondaryLabel}>This will be shown for your own portal.</span>
          </label>
          <div>
            <input className={styles.tosInput} placeholder="Terms of Service" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

Branding.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Branding;
