import React, { useState, } from 'react'
import styles from './styles.module.css'
import DefaultVideoComponent from 'draft-js-video-plugin/lib/video/components/DefaultVideoComponent.js'
import Modal from 'react-bootstrap/Modal'
import '../general/modals.scss';
import Button from '../general/button'
import Loading from '../general/loading';

import { SvgBookIcon } from '../general/icons'
const ThesaurusButton = ({ editorState }) => {
  const initialPhrase = () => {
    // Get block for current selection.
    // Note that selection can span across multiple blocks,
    // but in this case we don't care, since we're only
    // interested in the first world anyway.
    let selection = editorState.getSelection()
    const anchorKey = selection.getAnchorKey()
    const currentContent = editorState.getCurrentContent()
    const currentBlock = currentContent.getBlockForKey(anchorKey)

    // Then based on the docs for SelectionState.
    const start = selection.getStartOffset()
    const end = selection.getEndOffset()
    const selectedText = currentBlock.getText().slice(start, end)
    const firstWord = selectedText.split(' ')[0]
    return firstWord
  }
  const [phrase, setPhrase] = useState(initialPhrase())
  const [modalOpen, _setModalOpen] = useState(false)

  const setModalOpen = (value) => {
    //clear state when closing
    setPhrase(initialPhrase())
    _setModalOpen(value)
  }

  const url = `https://www.rhymezone.com/r/rhyme.cgi?Word=${phrase}&typeofrhyme=syn&org1=syl&org2=l&org3=y&loc=sel_change`

  return (
    <div className={styles.buttonWrapper}>
      <button onClick={() => setModalOpen(true)} className={styles.menuButton} style={{paddingTop: 4}}>
        <SvgBookIcon />
      </button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalOpen}
        onHide={() => setModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Thesaurus
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter a phrase</p>
          <input
            name="phrase"
            type="text"
            value={phrase}
            onChange={(e) => setPhrase(e.target.value)}
            placeholder="however"
            tabIndex={0}
            className={styles.input}
          />
          </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => open(url)} anchor className="primary" type="primary" disabled={!phrase || phrase.length === 1}>Look up</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ThesaurusButton
