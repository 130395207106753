import React, { useState, useCallback, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import {injectStripe} from 'react-stripe-elements'
import NewCardInfo from '../../customer_onboarding/new_card_info'
import styles from './new_card_info_modal.module.css'
import Button from 'components/general/button'
import Store from 'store'
import organizationsResource from 'resources/organizations'
import { toast } from 'react-toastify'

function _NewCardInfoModal({ show, onClose, stripe, elements, onCompleted }) {
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const { state } = useContext(Store)
  const { currentUser } = state

  const updatePaymentInfo = useCallback(async () => {
    setSubmitting(true)
    setError(null)

    let stripeResponse = null
    try {
      stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('cardNumber'),
        billing_details: {
          name: currentUser.fullName,
          email: currentUser.email,
          address: {
            postal_code: document.querySelector('input[name=cardZip]').value
          }
        }
      })
      if(stripeResponse.error) {
        setError(stripeResponse.error.message)
        setSubmitting(false)
        return
      }
    } catch(error) {
      if(error.toString().indexOf('We could not infer which Element you want to use') > -1) {
        setError('Please add your billing information')
        setSubmitting(false)
        return
      }
      console.error(error)
      return
    }

    try {
      const data = {
        payment_method_id: stripeResponse.paymentMethod.id,
      }
      const result = await organizationsResource.updatePaymentMethod({ data })
      toast.success("Your credit card information has been updated.")
      onClose()
      onCompleted({ organization: result.data })
    } catch(error) {
      console.error(error)
      if(error.response?.data?.payment_error) {
        setError(error.response.data.payment_error)
      } else {
        toast.warn("Uh oh! Something went wrong. Please try again soon.")
      }
    } finally {
      setSubmitting(false)
    }
  }, [setSubmitting, setError, stripe, elements, currentUser])

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      style={{border: '2px solid black'}}
    >
      <Modal.Header closeButton={!submitting}>
      <Modal.Title>Update Credit Card Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Enter your new credit card information below. This payment information will be used for all future charges for your account.</p>
        <NewCardInfo
          errorMessage={error}
          clearStripeErrors={() => setError()}
          styles={styles}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={submitting}  onClick={onClose}>
            Cancel
        </Button>
          <Button disabled={submitting} className="primary" onClick={updatePaymentInfo}>
              Save
          </Button>
      </Modal.Footer>
    </Modal>
  )
}

const NewCardInfoModal = injectStripe(_NewCardInfoModal)

export const useNewCardInfoModal = ({
  onCompleted = ({ organization }) => {},
  onClose = () => {}
}) => {
  const [showModal, setShowModal] = useState(false)
  const openModal = useCallback(() => setShowModal(true), [setShowModal])
  const closeModal = useCallback(() => {
    setShowModal(false)
    onClose()
  }, [setShowModal])

  const ModalComponent = useCallback(() => {
    return <NewCardInfoModal
      show={showModal}
      onClose={closeModal}
      onCompleted={onCompleted}
    />
  }, [showModal, setShowModal])

  return { openModal, ModalComponent }
}

export default NewCardInfoModal
