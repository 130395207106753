import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const { show } = defaultResourceSchema

const adminJobsResource = resourceBuilder.build('/admin/jobs', {
  show,
  payments: {
    method: 'PUT',
    url: '/{id}/payments'
  }
})

export default adminJobsResource
