import React, { useMemo, useRef, useState, useEffect, useContext } from 'react'
import {Helmet} from "react-helmet";
import SignedInBar from 'components/general/signed_in_bar'
import styles from './styles.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import queryString from 'query-string'
import snakeCase from 'snakecase-keys'
import usersResource from 'resources/users'
import invitationsResource from 'resources/invitations'
import { TOU, SIGN_IN } from 'constants/routes'
import { currentUser } from 'utils/local_storage'
import Store from 'store'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { toast } from 'react-toastify';
import Loading from 'components/general/loading.jsx'
import CustomerProfileEditor from './profile_editor.jsx'

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Passwords do not match').required('Password Confirmation is required'),
  tos: yup.boolean().oneOf([true], 'Must accept Terms of Use to proceed')
})

function TeamMemberOnboarding ({location, history}) {
  const { dispatch, state } = useContext(Store)
  const [loading, setLoading] = useState(true)
  const [organization, setOrganization] = useState(null)

  const avatarUploader = useRef(null)

  const [profileAvatarSignedId, setProfileAvatarSignedId] = useState(null)
  const [profileAvatarFileUrl, setProfileAvatarFileUrl] = useState(null)

  const onAvatarUpload = (setFileUrl, handleUpload) => (files) => {
    const image = files[files.length - 1]
    if (!image) {
      if(avatarUploader.current.state.notAcceptedFileType.length) {
        toast.warn("Sorry! That file type is not supported.")
        avatarUploader.current.state.notAcceptedFileType.pop()
      }
      if(avatarUploader.current.state. notAcceptedFileSize.length) {
        toast.warn("Uh oh! This image is too large. Please upload an image less than 100 MB")
        avatarUploader.current.state. notAcceptedFileSize.pop()
      }
      return
    }
    avatarUploader.current.state.files.pop()
    setFileUrl(URL.createObjectURL(image))
    handleUpload([image])
  }

  const onAvatarUploadSuccess = (setSignedId) => (signedIds) => {
    setSignedId(signedIds[0])
  }

  const parsedQuery = useMemo(() => queryString.parse(location.search), [])
  const invitationToken = parsedQuery.token

  useEffect(() => {
    const acceptPlatformInvitation = async () => {
      try {
        const response = await invitationsResource.update({ params: { id: invitationToken, organization_id: parsedQuery.organization_id  }})

        if(response.data.needs_sign_in) {
          history.push({ pathname: SIGN_IN, state: { user: response.data.user }})
        }
        else if(response.data.needs_onboarding) {
          // This is the case where we use this page
          dispatch({type: CURRENT_USER_FETCHED, payload: response.data.user})
          currentUser.set(response.data.user)
          setOrganization(response.data.organization)
        } else {
          history.push({ pathname: "/" })
          toast.success("You've already accepted this invitation. Welcome back!")
        }
      } catch (error) {
        console.error(error)
        toast.warn("Uh oh! It looks like that invitation is no longer valid.")
      }
      finally {
        setLoading(false)
      }
    }
    acceptPlatformInvitation()
  }, [])


  const onSubmit = async (values, { setSubmitting, setFieldError, touched }) => {
    setSubmitting(true)

    const user = snakeCase(values)
    if(values.tos) {
      user.tos_acception_date = new Date()
      delete user.tos
    }

    if(profileAvatarSignedId) {
      user.avatar = profileAvatarSignedId
    }

    try {
      const result = await usersResource.updateCurrent({data: { user }})
      dispatch({type: CURRENT_USER_FETCHED, payload: result.data})
      currentUser.set(result.data)
      history.push({ pathname: "/" })
      toast.success(`You're all set! Welcome to the ${organization.name} team!`)
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! Looks like something went wrong. Please try again soon.")
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <SignedInBar unauthenticatedMode="dark">
      <Helmet>
        {organization && <title>{organization.name} Onboarding | Ideasicle X</title>}
        {!organization && <title>Ideasicle X</title>}
      </Helmet>
      { loading && <Loading />}
      { !loading && <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          password: '',
          passwordConfirmation: '',
          tos: false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          setFieldError,
          validateForm
        }) => {
          const clearStripeErrors = () => {
            setFieldError('stripe', null)
            validateForm()
          }
          return (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col>
                  <CustomerProfileEditor
                    headerMessage={`Welcome to the ${organization.name} team! Fill out your profile details below to engage your Command Center.`}
                    headerMessageLink={true}
                    onAvatarUploadSuccess={onAvatarUploadSuccess}
                    setProfileAvatarSignedId={setProfileAvatarSignedId}
                    avatarUploader={avatarUploader}
                    onAvatarUpload={onAvatarUpload}
                    profileAvatarFileUrl={profileAvatarFileUrl}
                    setProfileAvatarFileUrl={setProfileAvatarFileUrl}
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    email={false}
                  />

                  <div className={styles.box}>
                    <div className="row">
                      <Col xs={12} style={{textAlign: 'center'}}>
                        <div style={{marginBottom: 30}}>
                          <Field type="checkbox" name="tos" id="tos" style={{position: 'relative', top: -2}} />
                          <label htmlFor="tos">
                            I have read and accept the <a href={TOU} target="_blank">Terms of Use</a>.
                          </label>
                        </div>
                        <button type="button" className="btn btn-none" disabled={isSubmitting}>
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={!isValid || !dirty || isSubmitting}>
                          {isSubmitting && <div className="spin-grow light"></div>}
                          Save
                        </button>
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        )}}
      />}
    </SignedInBar>
  )
}

export default TeamMemberOnboarding
