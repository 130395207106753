import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import styles from './styles.module.css'
import { SvgImageIcon, SvgPlusIcon } from '../general/icons'
import Modal from 'react-bootstrap/Modal'
import '../general/modals.scss';
import Button from '../general/button'
import ImageLoader from 'react-load-image';
import Loading from '../general/loading';
import { DirectUploadProvider } from 'react-activestorage-provider'
import ImageUploader from 'react-images-upload'
import onboardingStyles from '../customer_onboarding/styles.module.css'
import classnames from 'classnames'
import ProgressBar from 'components/general/progress_bar'
import { toast } from 'react-toastify';

const ImageButton = ({ modifier, onChange, editorState }, ref) => {
  const [modalOpen, _setModalOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const imageUploader = useRef(null)
  const [imageFileUrl, setImageFileUrl] = useState(null)
  const [blobUrl, setBlobUrl] = useState(null)
  const [handlingPaste, setHandlingPaste] = useState(false)
  const directUploadProviderRef = useRef(null)

  const setModalOpen = (value) => {
    //clear state when closing
    setImageUrl('')
    setImageFileUrl(null)
    setHandlingPaste(false)
    setBlobUrl(null)
    _setModalOpen(value)
  }

  const handlePaste = (files) => {
    setModalOpen(true)
    setHandlingPaste(true)
    setTimeout(() => onImageUpload(files))
  }

  useImperativeHandle(ref, () => ({
    handlePaste
  }))

  const onImageUpload = (files, ...args) => {
    const image = files[files.length - 1]
    if (!image) {
      if(imageUploader.current.state.notAcceptedFileType.length) {
        toast.warn("Sorry! That file type is not supported.")
        imageUploader.current.state.notAcceptedFileType.pop()
      }
      if(imageUploader.current.state. notAcceptedFileSize.length) {
        toast.warn("Uh oh! This image is too large. Please upload an image less than 100 MB")
        imageUploader.current.state. notAcceptedFileSize.pop()
      }
      return
    }
    imageUploader.current.state.files.pop()
    setImageFileUrl(URL.createObjectURL(image))
    directUploadProviderRef.current.handleUpload([image])
  }

  const onImageUploadSuccess = (signedIds) => {
    setBlobUrl("/api/v1/blobs/" + signedIds[0])
  }

  const handleMouseDown = (e) => {
    e.preventDefault()

    setModalOpen(true)
  }

  let parsedUrl = null
  try { parsedUrl = new URL(imageUrl) } catch(e) {}
  const validUrl = parsedUrl && parsedUrl.pathname.length > 1 // ignore "/"

  const handleModalSuccess = () => {
    setTimeout(() => {
      const newEditorState = modifier(editorState, blobUrl || imageUrl)
      onChange(newEditorState)
    })

    setModalOpen(false)
  }

  return (
    <div className={styles.buttonWrapper}>
      <button onMouseDown={handleMouseDown} className={styles.menuButton} style={{paddingTop: 3}}>
        <SvgImageIcon />
      </button>


      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalOpen}
        onHide={() => setModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imageUrl.length === 0 && <>
            { !handlingPaste && <p>Upload from your hard drive</p> }
            <div style={{marginTop: 15, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <DirectUploadProvider ref={directUploadProviderRef} directUploadsPath="/api/v1/direct_uploads" onSuccess={onImageUploadSuccess} render={({ handleUpload, uploads, ready }) => {
                return (
                <div className={classnames(onboardingStyles.upload_wrapper, {[onboardingStyles.upload_wrapper_disabled]: !ready})}
                  onClick={(e) => { ready && imageUploader.current.triggerFileUpload(); e.stopPropagation() }}>
                  <div style={{ visibility: 'hidden', position: 'absolute' }}>
                    <ImageUploader
                      ref={imageUploader}
                      onChange={onImageUpload}
                      singleImage={true}
                      imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                      maxFileSize={1024 * 1024 * 100}
                    />
                  </div>
                  <SvgPlusIcon variant="gray" className={onboardingStyles.upload_button} style={{backgroundColor: '#EDEDED', borderColor: '#EDEDED'}}/>
                  {!imageFileUrl && <div className={onboardingStyles.upload_box}>
                    Image
                  </div>}
                  {imageFileUrl && <img src={imageFileUrl} style={{ maxHeight: '100%', maxWidth: '100%', opacity: ready ? 1 : 0.3 }} />}
                  {uploads.map(upload => {
                    if(upload.state === 'waiting' || upload.state === 'uploading') {
                      return (<ProgressBar width={100} height={8} percentage={upload.progress} className={onboardingStyles.uploadProgressBar} />)
                    }
                  })}
                </div>
              )}} />
            </div>
          </>}
          { !handlingPaste && !imageFileUrl && <>
            <p>Or enter a URL</p>
            <input
              name="imageUrl"
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              placeholder="https://i.imgur.com/l3v4P3s.jpg"
              tabIndex={0}
              className={styles.input}
              disabled={!!imageFileUrl}
            />
            {validUrl &&
              <div style={{marginTop: 15, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <ImageLoader src={imageUrl} >
                  <a href={imageUrl} target="_blank">
                    <img src={imageUrl} style={{maxWidth: 350, maxHeight: 250}} />
                  </a>
                  <div style={{fontSize: 14, margin: 35, marginBottom: 34}}>
                    <span style={{color: 'red'}}>&#10008;</span> Not an image
                  </div>
                  <Loading />
                </ImageLoader>
              </div>
            }
          </>}
        </Modal.Body>
        <Modal.Footer>
          <Button className="none" onClick={() => setModalOpen(false)}>Close</Button>
          <Button className="primary" type="primary" onClick={handleModalSuccess} disabled={!blobUrl && !validUrl}>Add</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default forwardRef(ImageButton)
