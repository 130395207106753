import React, { useState, useContext, useEffect, useMemo } from 'react'
import Store from 'store'
import { Container, Row, Col } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import jobsResource from 'resources/jobs'
import * as routes from 'constants/routes'
import ideaStyles from '../idea/styles.module.css'
import { SvgBackArrow, SvgStarEmptyIndicator, SvgStarFilledIndicator, SvgStarHalfIndicator } from 'components/general/icons'
import classnames from 'classnames'
import jobCreateStyles from '../create/styles.module.css'
import styles from './styles.module.css'
import useAttribute from 'utils/useAttribute'
import { toast } from 'react-toastify';
import Avatar from 'components/general/avatar.jsx'
import CurrencyInput from 'react-currency-input';
import { moneyConverter } from 'components/jobs/create'
import { formatCurrency } from 'utils/formatters'
import Button from 'components/general/button'
import StarRatingComponent from 'react-star-rating-component';
import Discount from 'components/general/discount'
import Loading from 'components/general/loading.jsx'
import Config from 'utils/config'

const renderPct = (amount) => Math.floor(amount * 100) + '%'

export default function EndJob ({ job, refreshJob, history, match, location }) {
  const { dispatch, state } = useContext(Store)
  const [_, forceRender] = useState(undefined)
  const [saving, setSaving] = useState(false)

  const navigateToJob = () => history.push(routes.JOB(job.slug))
  const experts = job && job.expertTeam

  const additionalFeePct = useMemo(() => state.currentUser?.additionalFeePct(), [state.currentUser])

  experts.forEach((expert) => {
    // Employees should have a proposed_payment of 0, but since we don't show employees in the payment
    // list at the bottom of the page, here's a failsafe to ensure that employees get a $0 default
    const defaultPaymentAmount = expert.expert_type === 'employee' ?
      0.0 :
      (expert.proposed_payment || job.per_expert_budget_in_cents) / 100.0
    expert.paymentAmount = useAttribute('payment_amount', defaultPaymentAmount, forceRender, {
      change: (value) => { const newValue = moneyConverter(value); return Math.min(newValue, 50.0 * 1000) }
    })

    expert.ratingParticipation = useAttribute('rating_participation', 0, forceRender)
    expert.ratingQuality = useAttribute('rating_quality', 0, forceRender)
    expert.ratingTeamwork = useAttribute('rating_teamwork', 0, forceRender)
    expert.ratingRecommended = useAttribute('rating_recommended', null, forceRender)
  })

  const isPaying = state.currentUser?.billingFeaturesAllowed()

  const finish = async () => {
    try {
      setSaving(true)
      const payments = experts.map((expert) => {
        return {
          expert_id: expert.id,
          amount_in_cents: expert.paymentAmount.value * 100,
        }
      })
      const ratings = experts.map((expert) => {
        return {
          expert_id: expert.id,
          rating_participation: expert.ratingParticipation.value - 1,
          rating_quality: expert.ratingQuality.value - 1,
          rating_teamwork: expert.ratingTeamwork.value  - 1,
          rating_recommended: expert.ratingRecommended.value,
        }
      })
      const response = await jobsResource.close({ data: { payments, ratings }, params: { id:  job.id }})
      toast.success(isPaying ? 
        "Thanks! Your payment is on its way to the Freelancer team." :
        "Thank you for your approvals. An invoice from Ideasicle X will be forthcoming."
      )
      history.push('/')
    } catch (error) {
      console.error(error)
      toast.warn("Uh oh! Something went wrong. Please try again later.")
    } finally {
      setSaving(false)
    }
  }

  let additionalFeeDollars = 0.0

  return (
    <>
      <div className={ideaStyles.back} onClick={navigateToJob}>
        <SvgBackArrow /> <span>Back to {job.title}</span>
      </div>
      <ContentHeader>
        <Col md={12} lg={12} style={{ textAlign: "left", display: 'flex', alignItems: "center" }}>
          {isPaying ? 
            <span className="job-title">{Config.ratings_enabled && <>Rate and </>}Pay Your Freelancers</span> :
            <span className="job-title">Approve Payments To Freelancers</span>}

        </Col>
      </ContentHeader>
      <Row style={{paddingTop: 34}}>
        <Col xs={12} sm={12} md={6} lg={6}>
          {experts.map((expert) => {
            if(expert.expert_type !== 'employee'){
              return (
                <Col lg={12} xs={12}>
                  <div className={styles.endJobExpertTile} style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{padding: 20}}>
                      <Avatar record={expert} className={styles.endJobExpertAvatar} />
                      <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                        <span className={styles.enbJobExpertName}>{expert.fullName}</span>
                        {Config.ratings_enabled  && <>
                          <div className={styles.ratingName}>Participation</div>
                          <StarRatingComponent
                              name="rating_participation"
                              value={expert.ratingParticipation.value}
                              starCount={10}
                              onStarClick={(nextValue, prevValue, name) => { expert.ratingParticipation.set(nextValue + 1) }}
                              renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
                                {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
                              </span>)}
                              renderStarIconHalf={<span style={{marginRight: 6, display: 'inline-block'}}><SvgStarHalfIndicator style={{marginRight: 6}}/></span>}
                          />
                          <div className={styles.ratingName} style={{marginTop: 17}}>Quality of Ideas</div>
                          <StarRatingComponent
                              name="rating_quality"
                              value={expert.ratingQuality.value}
                              starCount={10}
                              onStarClick={(nextValue, prevValue, name) => { expert.ratingQuality.set(nextValue + 1) }}
                              renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
                                {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
                              </span>)}
                              renderStarIconHalf={<span style={{marginRight: 6, display: 'inline-block'}}><SvgStarHalfIndicator style={{marginRight: 6}}/></span>}
                          />
                          <div className={styles.ratingName} style={{marginTop: 17}}>Teamwork</div>
                          <StarRatingComponent
                              name="rating_teamwork"
                              value={expert.ratingTeamwork.value}
                              starCount={10}
                              onStarClick={(nextValue, prevValue, name) => { expert.ratingTeamwork.set(nextValue + 1) }}
                              renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
                                {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
                              </span>)}
                              renderStarIconHalf={<span style={{marginRight: 6, display: 'inline-block'}}><SvgStarHalfIndicator style={{marginRight: 6}}/></span>}
                          />
                        </>}
                      </div>
                    </div>
                    <div style={{backgroundColor: '#F2F2F2', padding: 20, height: 220}}>
                      <div className={styles.ratingName} style={{marginTop: 17}}>Would you recommend this freelancer?</div>
                      <input
                        className={classnames([jobCreateStyles.hero_radio, styles.hero_radio])}
                        type="radio"
                        name={`${expert.id}-ratingRecommended`}
                        checked={expert.ratingRecommended.value === "true"}
                        value="true"
                        {...expert.ratingRecommended.eventHandlers} /> Yes
                      <input
                        style={{marginLeft: 15}}
                        className={classnames([jobCreateStyles.hero_radio, styles.hero_radio])}
                        type="radio"
                        name={`${expert.id}-ratingRecommended`}
                        checked={expert.ratingRecommended.value === "false"}
                        value="false"
                        {...expert.ratingRecommended.eventHandlers} /> No
                      <CurrencyInput
                        className={styles.endJobFinalBudget}
                        prefix="$"
                        value={expert.paymentAmount.value}
                        onChangeEvent={expert.paymentAmount.eventHandlers.onChange}
                        style={{ display: 'block', marginTop: 30}}
                      />
                    </div>
                  </div>
                </Col>
              )
            }
          })}
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          {experts.map((expert) => {
            if(expert.expert_type === 'employee'){
              return (
                <Col lg={12} xs={12}>
                  <div className={styles.endJobExpertTile} style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{padding: 20}}>
                      <Avatar record={expert} className={styles.endJobExpertAvatar} />
                      <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                        <span className={styles.enbJobExpertName}>{expert.fullName}</span>
                        {Config.ratings_enabled  && <>
                          <div className={styles.ratingName}>Participation</div>
                          <StarRatingComponent
                              name="rating_participation"
                              value={expert.ratingParticipation.value}
                              starCount={10}
                              onStarClick={(nextValue, prevValue, name) => { expert.ratingParticipation.set(nextValue + 1) }}
                              renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
                                {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
                              </span>)}
                              renderStarIconHalf={<span style={{marginRight: 6, display: 'inline-block'}}><SvgStarHalfIndicator style={{marginRight: 6}}/></span>}
                          />
                          <div className={styles.ratingName} style={{marginTop: 17}}>Quality of Ideas</div>
                          <StarRatingComponent
                              name="rating_quality"
                              value={expert.ratingQuality.value}
                              starCount={10}
                              onStarClick={(nextValue, prevValue, name) => { expert.ratingQuality.set(nextValue + 1) }}
                              renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
                                {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
                              </span>)}
                              renderStarIconHalf={<span style={{marginRight: 6, display: 'inline-block'}}><SvgStarHalfIndicator style={{marginRight: 6}}/></span>}
                          />
                          <div className={styles.ratingName} style={{marginTop: 17}}>Teamwork</div>
                          <StarRatingComponent
                              name="rating_teamwork"
                              value={expert.ratingTeamwork.value}
                              starCount={10}
                              onStarClick={(nextValue, prevValue, name) => { expert.ratingTeamwork.set(nextValue + 1) }}
                              renderStarIcon={(index, value) => (<span style={{marginRight: 6, display: 'inline-block'}}>
                                {index >= value ? <SvgStarFilledIndicator /> : <SvgStarEmptyIndicator />}
                              </span>)}
                              renderStarIconHalf={<span style={{marginRight: 6, display: 'inline-block'}}><SvgStarHalfIndicator style={{marginRight: 6}}/></span>}
                          />
                        </>}
                      </div>
                    </div>
                  </div>
                </Col>
              )
            }
          })}
        </Col>
      </Row>
      <Row style={{marginTop: 30}}>
        <Col lg={6} xs={0}></Col>
        <Col lg={5} xs={12}>
          {isPaying ?
            <div className={styles.endJobSummaryHeading}>Payment Summary</div>  :
            <div className={styles.endJobSummaryHeading}>Approved Payment Summary</div>}
          <div className={styles.endJobSummaryRow} style={{borderBottom: 'none', marginBottom: 15}}>
            <span>FREELANCERS</span><span>PAYMENT</span>
          </div>
          {experts.map((expert) => {
            if(expert.expert_type !== 'employee'){
              const feePortion = expert.paymentAmount.value * additionalFeePct.discountedAmount
              additionalFeeDollars += feePortion
              return (
                <div className={styles.endJobSummaryRow}>
                  <span>{expert.fullName}</span><span>{formatCurrency(expert.paymentAmount.value, true)}</span>
                </div>
              )
            }
          })}
          <div className={styles.endJobSummaryRow}>
            <span>Handling Fees (<Discount formatter={renderPct} {...additionalFeePct} />)</span><span>{formatCurrency(additionalFeeDollars, true)}</span>
          </div>
          <div style={{marginTop: 25, float: 'right', marginBottom: 100}}>
            <Button type="primary" onClick={finish} disabled={saving}>
              {isPaying ? "Pay Freelancers" : "Approve Payments"}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}
