import React from 'react'
import styles from './styles.module.css'
import classnames from 'classnames'

export default ({ headline, children, show, onHide }) => {
  if (!show) {
    return (<></>)
  }

  return (
    <div className={`${styles.help_copy} print-hide`}>
      {onHide && <button
        type="button"
        className={classnames('btn', 'btn-none', styles.hide_button)}
        onClick={onHide}>
        HIDE
      </button>}
      <div className={styles.headline}>{headline}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
