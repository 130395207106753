import React, { useState, useRef } from 'react'
import styles from './styles.module.css'
import Modal from 'react-bootstrap/Modal'
import '../general/modals.scss';
import Button from '../general/button'
import Loading from '../general/loading';
import { getCurrentlySelectedBlock } from './extended-rich-utils'
import { Modifier, RichUtils, EditorState } from 'draft-js'
import EditorUtils from 'draft-js-plugins-utils'
import normalizeUrl from 'normalize-url'
import isValidUrl from 'is-valid-http-url'

const LinkButton = ({ linkPlugin, ...linkPluginButtonProps }) => {
  const [modalOpen, _setModalOpen] = useState(false)
  const [url, setUrl] = useState('')
  const [text, setText] = useState('')
  const [showText, setShowText] = useState(true)

  let parsedUrl = null
  try {
    const intermediateUrl = normalizeUrl(url, {defaultProtocol: 'https:'})
    if(isValidUrl(intermediateUrl)) {
      parsedUrl = intermediateUrl
    }
  } catch(e) { }

  const { getEditorState, setEditorState } = linkPluginButtonProps

  const selectionInfo = () => {
    const { content, currentBlock, hasAtomicBlock, target: selectionState } = getCurrentlySelectedBlock(getEditorState())
    const isSingleBlock = selectionState.anchorKey == selectionState.focusKey
    const isTextBlock = isSingleBlock && !hasAtomicBlock

    return {
      content,
      currentBlock,
      selectionState,
      isTextBlock
    }
  }

  const initTextFromSelection = () => {
    const { currentBlock, selectionState, isTextBlock } = selectionInfo()

    // do not show text block if the user has selected across multiple blocks or an image/video/other block
    setShowText(isTextBlock)
    if(isTextBlock) {
      setText(currentBlock.text.slice(selectionState.anchorOffset, selectionState.focusOffset))
    }
  }

  const replaceSelectionText = () => {
    const { content, selectionState } = selectionInfo()

    // If we gave the user the opportunity to edit the text, make a replacement
    if(showText) {
      const replacementText = text || url
      return {
        contentState: Modifier.replaceText(content, selectionState, replacementText),
        selectionState: selectionState.merge({
          anchorOffset: selectionState.getStartOffset(),
          focusOffset: selectionState.getStartOffset() + replacementText.length
        }),
        modified: true,
      }
    } else {
      return {
        contentState: content,
        selectionState,
        modified: false,
      }
    }
  }

  // adapted from draft-js-plugins-utils
  // https://github.com/draft-js-plugins/draft-js-plugins/blob/1da9943359a7e3dd9076daef2b4bea9de0e34eae/draft-js-plugins-utils/src/index.js
  const createLinkAtSelection = (editorState, selectionState) => {
    const contentState = editorState.getCurrentContent().createEntity('LINK', 'MUTABLE', { url: parsedUrl, target: '_blank' });
    const entityKey = contentState.getLastCreatedEntityKey()
    const withLink = RichUtils.toggleLink(
      editorState,
      selectionState,
      entityKey
    );
    return EditorState.forceSelection(
      withLink, selectionState
    )
  }

  const handleModalSuccess = () => {
    const { contentState, selectionState, modified } = replaceSelectionText()
    let editorState = getEditorState()
    if (modified) {
      editorState = EditorState.push(editorState, contentState, 'insert-characters')
      setEditorState(editorState)
    }
    setEditorState(createLinkAtSelection(editorState, selectionState))
    setModalOpen(false)
  }

  const setModalOpen = (value) => {
    if(value) {
      initTextFromSelection()
    } else {
      setText('')
      setShowText(true)
    }
    setUrl('')
    _setModalOpen(value)
  }

  const onClick = () => {
    setModalOpen(true)
  }

  return (
    <>
      <linkPlugin.LinkButton {...linkPluginButtonProps} onOverrideContent={onClick} />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalOpen}
        onHide={() => setModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Link
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter a URL</p>
          <input
            name="url"
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://unsplash.com/t/nature"
            tabIndex={0}
            className={styles.input}
          />
          { showText && <>
            <p>Optionally, enter text to link</p>
            <input
              name="text"
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Check out these photos"
              tabIndex={1}
              className={styles.input}
            />
          </>}
          </Modal.Body>
        <Modal.Footer>
          <Button className="none" onClick={() => setModalOpen(false)}>Close</Button>
          <Button className="primary" type="primary" onClick={handleModalSuccess} disabled={!parsedUrl}>Add</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LinkButton
