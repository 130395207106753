import React, { useMemo, useRef, useState, useEffect, useContext } from 'react'
import styles from '../customer_onboarding/styles.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import { SvgConfettiIcon, SvgPlusIcon, SvgBankIcon, SvgPdfIcon } from 'components/general/icons'
import classnames from 'classnames'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import queryString from 'query-string'
import ImageUploader from 'react-images-upload'
import Uploader from '../jobs/show/uploader/index'
import snakeCase from 'snakecase-keys'
import usersResource from 'resources/users'
import invitationsResource from 'resources/invitations'
import { TOU, PRIVACY_POLICY, EXPERT_AGREEMENT, JOB, SIGN_IN } from 'constants/routes'
import { pathAfterSignIn, currentUser } from 'utils/local_storage'
import Store from 'store'
import { CURRENT_USER_FETCHED } from 'constants/actions'
import { toast } from 'react-toastify';
import { DirectUploadProvider } from 'react-activestorage-provider'
import ProgressBar from 'components/general/progress_bar'
import Dropzone from 'react-dropzone';
import Loading from 'components/general/loading.jsx'
import Tags from './tags.jsx'
import { stripeConnectUrl } from 'utils/stripe_connect'
import ls from 'local-storage'
import { HistoryProvider } from 'components/app/history'
import {FormControlLabel, Switch } from '@material-ui/core';
import Button from '../general/button'
import Icon from '@mdi/react';
import { mdiDownload, mdiArrowExpand, mdiArrowCollapse } from '@mdi/js';

import { withStyles } from '@material-ui/core/styles';

function ExpertProfileEditor ({ user, expertType, onSubmit, editMode }) {
  const { dispatch, state } = useContext(Store)

  const tagEditor = useRef(null)
  const avatarUploader = useRef(null)

  const [profileAvatarSignedId, setProfileAvatarSignedId] = useState(null)
  const [profileAvatarFileUrl, setProfileAvatarFileUrl] = useState(user.avatar_url)

  const [w9SignedId, setW9SignedId] = useState(null)
  const [w9Filename, setw9Filename] = useState('')
  const [w9FileUrl, setW9FileUrl] = useState(user.w9_form)
  const [profileType, setProfileType] = useState(expertType || user.expert_type)

  const w9Required = profileType != 'employee'
  const w9RequiredError = w9Required && !w9SignedId && !w9FileUrl

  const parsedQuery = useMemo(() => queryString.parse(location.search), [])

  const [stripeBank, setStripeBank] = useState(
    editMode && user.stripe_account_id ?
      { account: user.stripe_account_id, bank_name: user.stripe_account_bank, last4: user.stripe_account_last_4 }
    : { account: parsedQuery.stripe_account_id, bank_name: parsedQuery.bank_name, last4: parsedQuery.last4 }
    )

  useEffect(() => {
    ls.on('stripe_connect_response', (response) => {
      setStripeBank(response)
    })

    return function cleanup() {
      ls.off('stripe_connect_response')
    };
  });


  const onW9UploadSuccess = (setSignedId) => (signedIds) => {
    setSignedId(signedIds[0])
  }

  const onAvatarUpload = (setFileUrl, handleUpload) => (files) => {
    const image = files[files.length - 1]
    if (!image) {
      if(avatarUploader.current.state.notAcceptedFileType.length) {
        toast.warn("Sorry! That file type is not supported.")
        avatarUploader.current.state.notAcceptedFileType.pop()
      }
      if(avatarUploader.current.state. notAcceptedFileSize.length) {
        toast.warn("Uh oh! This image is too large. Please upload an image less than 100 MB")
        avatarUploader.current.state. notAcceptedFileSize.pop()
      }
      return
    }
    avatarUploader.current.state.files.pop()
    setFileUrl(URL.createObjectURL(image))
    handleUpload([image])
  }

  const onAvatarUploadSuccess = (setSignedId) => (signedIds) => {
    setSignedId(signedIds[0])
  }

  const stripeConnectOauthUrl = (formValues) => {
    const userData = snakeCase(formValues)
    userData.email = state.currentUser.email
    return stripeConnectUrl(userData, editMode ? 'expert_profile_update' : 'expert_onboarding')
  }

  const profileId = user.profile_id

  const onSubmitWrapper = async (values, { setSubmitting, setFieldError, touched }) => {
    setSubmitting(true)

    const user = snakeCase(values)
    if(values.tos) {
      user.tos_acception_date = new Date()
      delete user.tos
    }

    if(profileAvatarSignedId) {
      user.avatar = profileAvatarSignedId
    }

    user.profile_attributes = {
      bio: user.bio,
      linkedin_url: user.linkedin_url,
      portfolio_url: user.portfolio_url,
      w9_form: w9SignedId,
      profile_type: profileType
    }
    if(editMode) {
      user.profile_attributes.id = profileId
    }
    // Do not overwrite w9 form if resubmitting without a w9 and one already exists
    if(w9FileUrl && !w9SignedId) {
      delete user.profile_attributes.w9_form
    }
    delete user.bio
    delete user.linkedin_url
    delete user.portfolio_url
    user.profile_attributes.keywords = tagEditor.current.output()
    if(stripeBank) {
      user.profile_attributes.stripe_account_id = stripeBank.account
      user.profile_attributes.stripe_account_last_4 = stripeBank.last4
      user.profile_attributes.stripe_account_bank = stripeBank.bank_name
    }

    onSubmit(user, setSubmitting)
  }

  const initialValues = {
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    bio: user.bio || '',
    linkedinUrl: user.linkedin_url || '',
    portfolioUrl: user.portfolio_url || '',
    password: '',
    passwordConfirmation: '',
    tos: false,
    agreement: false,
    w9_form: w9FileUrl || ''
  }

  const validationSchemaRaw = {
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Passwords do not match').required('Password Confirmation is required'),
    bio: yup.string().required('Biography is required'),
    tos: yup.boolean().oneOf([true], 'Must accept Terms of Use to proceed'),
    agreement: yup.boolean().oneOf([true], 'Must accept Freelancer Agreement to proceed'),
    linkedinUrl: yup.string().matches(
      /^(https?:\/\/)?(www.)?linkedin.com\/in\/(.+)$/,
      'Enter a valid LinkedIn profile URL'
    ).notRequired(),
    portfolioUrl: yup.string().matches(
      /^(https?:\/\/)?[a-zA-Z0-9\.]+(\/(.+))?$/,
      'Enter a valid URL',
    ).notRequired(),
    w9_form: w9RequiredError ? yup.string().required("Must upload a W-9 form") : null,
  }

  const onboardingOnlyProperties = ['password', 'passwordConfirmation', 'tos', 'agreement']

  if(editMode) {
    [initialValues, validationSchemaRaw].forEach(obj => {
      onboardingOnlyProperties.forEach(prop => {
        delete obj[prop]
      })
    })
  }

  const validationSchema = yup.object().shape(validationSchemaRaw)

  // handle legacy accounts that dont have bank or last4 but do have an account id
  const bankDescriptor = stripeBank && [
    stripeBank.bank_name,
    stripeBank.last4 && stripeBank.last4.length ? `ending ${stripeBank.last4}` : null
  ].filter(s => s && s.length).join(' - ')

  const requireBank = profileType != 'employee'

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitWrapper}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      render={({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid: _isValid,
        dirty,
        setFieldError,
        setFieldValue,
        validateForm
      }) => {
        const isValid = _isValid && (!!stripeBank || !requireBank)
        console.log("==== Beginning form render ====")
        console.log("Form state", "is valid", isValid, "is touched", dirty)
        console.log("W-9 state", "w9 is required?", w9Required, "w9 requirement error?", w9RequiredError)
        console.log("Form validation", "formik isValid", _isValid, "bank present?", !!stripeBank, "bank required?", requireBank)
        console.log("Form errors", JSON.stringify(errors))
        return (
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col>
                <div className={styles.box}>
                  <div className={classnames(styles.row, 'row')}>
                    <Col md={3} xs={12} style={{marginBottom: 24}}>
                      <h2 className={styles.section_heading}>
                        User Info
                      </h2>
                      <DirectUploadProvider directUploadsPath="/api/v1/direct_uploads" onSuccess={onAvatarUploadSuccess(setProfileAvatarSignedId)} render={({ handleUpload, uploads, ready }) => (
                        <div className={classnames(styles.upload_wrapper, {[styles.upload_wrapper_disabled]: !ready})}
                          onClick={() => { ready && avatarUploader.current.triggerFileUpload() }}>
                          <div style={{ visibility: 'hidden', position: 'absolute' }}>
                            <ImageUploader
                              ref={avatarUploader}
                              onChange={onAvatarUpload(setProfileAvatarFileUrl, handleUpload)}
                              singleImage={true}
                              imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                              maxFileSize={1024 * 1024 * 100}
                            />
                          </div>
                          <SvgPlusIcon className={styles.upload_button}/>
                          {!profileAvatarFileUrl && <div className={styles.upload_box}>
                            Avatar
                          </div>}
                          {profileAvatarFileUrl && <img src={profileAvatarFileUrl} style={{ maxHeight: '100%', maxWidth: '100%', opacity: ready ? 1 : 0.3 }} />}
                          {uploads.map(upload => {
                            if(upload.state === 'waiting' || upload.state === 'uploading') {
                              return (<ProgressBar key={upload.id} width={100} height={8} percentage={upload.progress} className={styles.uploadProgressBar} />)
                            }
                          })}
                        </div>
                      )} />
                    </Col>
                    <Col md={9} xs={12}>
                      <Row>
                        <Col xs={6}>
                          <div style={{position: 'relative'}}>
                            {touched.firstName && errors.firstName && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                              {errors.firstName}
                            </span>}
                            <input
                              name="firstName"
                              type="text"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="First name"
                              className={styles.input}
                              tabIndex={1}
                            />
                          </div>
                          <div style={{position: 'relative'}}>
                            {touched.lastName && errors.lastName && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                              {errors.lastName}
                            </span>}
                            <input
                              name="lastName"
                              type="text"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Last name"
                              className={styles.input}
                              tabIndex={2}
                            />
                          </div>
                        </Col>
                        <Col xs={6}>
                        </Col>
                      </Row>
                    </Col>
                  </div>

                  {!editMode && (
                    <div className={classnames(styles.row, 'row')}>
                      <Col md={3} xs={12}>
                        <h2 className={styles.section_heading}>
                          Password
                        </h2>
                      </Col>
                      <Col md={9} xs={12}>
                        <Row>
                          <Col xs={6}>
                            <div style={{position: 'relative'}}>
                                {touched.password && errors.password && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                                  {errors.password}
                                </span>}
                              <input
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter password"
                                className={styles.input}
                              />
                            </div>
                            <div style={{position: 'relative'}}>
                              {touched.passwordConfirmation && errors.passwordConfirmation && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                                {errors.passwordConfirmation}
                              </span>}
                              <input
                                name="passwordConfirmation"
                                type="password"
                                value={values.passwordConfirmation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Confirm password"
                                className={styles.input}
                              />
                            </div>
                          </Col>
                          <Col xs={6}>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  )}
                </div>
                <div className={styles.box}>
                  <div className={classnames(styles.row, 'row')}>
                    <Col md={3} xs={12}>
                      <h2 className={styles.section_heading}>
                        Freelancer Profile
                      </h2>
                    </Col>
                  </div>
                  <div className={classnames(styles.row, 'row')}>
                    <Col md={3} xs={12}>
                      <h3>
                        Biography
                      </h3>
                    </Col>
                    <Col md={9} xs={12} style={{paddingTop: 10}}>
                      <div style={{ position: 'relative', marginBottom: 34 }}>
                        <textarea
                          name="bio"
                          type="text"
                          value={values.bio}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Tell us all about your professional experience"
                          className={styles.input}
                          style={{ height: 120, marginBottom: 6 }}
                          maxlength="300"
                          rows="3"
                        />
                        {touched.bio && errors.bio && <span style={{display: 'block'}} className={classnames(styles.small, styles.error)}>
                          {errors.bio}
                        </span>}
                        </div>
                    </Col>
                  </div>
                  <div className={classnames(styles.row, 'row')}>
                    <Col md={3} xs={12}>
                      <h3>
                        LinkedIn
                      </h3>
                    </Col>
                    <Col md={9} xs={12} style={{paddingTop: 10}}>
                      <div style={{position: 'relative'}}>
                        {touched.linkedinUrl && errors.linkedinUrl && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                          {errors.linkedinUrl}
                        </span>}
                        <input
                          name="linkedinUrl"
                          type="text"
                          value={values.linkedinUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="https://www.linkedin.com/in/myprofile"
                          className={styles.input}
                        />
                        </div>
                    </Col>
                      <Col md={3} xs={12}>
                        <h3>
                          Portfolio Website
                        </h3>
                      </Col>
                    <Col md={9} xs={12} style={{paddingTop: 10}}>
                      <div style={{position: 'relative'}}>
                        {touched.portfolioUrl && errors.portfolioUrl && <span className={classnames(styles.small, styles.below_input, styles.error)}>
                          {errors.portfolioUrl}
                        </span>}
                        <input
                          name="portfolioUrl"
                          type="text"
                          value={values.portfolioUrl}
                          onChange={(e) => { setFieldValue('portfolioUrl', e.target.value.trim() ); }}
                          onBlur={handleBlur}
                          placeholder="https://www.mywebsite.com/"
                          className={styles.input}
                        />
                        </div>
                    </Col>
                  </div>
                  <div className={classnames(styles.row, 'row')}>
                    <Col md={3} xs={12}>
                      <h3>
                        Skill Tags
                      </h3>
                    </Col>
                    <Col md={9} xs={12} style={{paddingTop: 10}}>
                      <Tags ref={tagEditor} tags={user.tags} />
                    </Col>
                  </div>
                  {w9Required && (
                    <div className={classnames(styles.row, 'row')}>
                      <Col md={3} xs={12}>
                        <h3>
                          W-9 Form
                        </h3>
                      </Col>
                      <Col md={9} xs={12} style={{ paddingTop: 10 }}>
                         <DirectUploadProvider directUploadsPath="/api/v1/direct_uploads" onSuccess={(signedId) => { onW9UploadSuccess(setW9SignedId)(signedId); validateForm() }} render={({ handleUpload, uploads, ready }) => (
                          <Dropzone onDrop={acceptedFiles => {
                            if(ready) {
                              setw9Filename(acceptedFiles[0].name)
                              handleUpload([acceptedFiles[0]])
                            }
                          }} multiple={false} >
                            {({getRootProps, getInputProps, isDragAccept, isDragReject}) => (
                              <>
                                <div className={classnames(styles.uploaderWrapper, styles.uploader, {[styles.uploaderDisabled]: !ready})} {...getRootProps()} style={{marginTop: 10}}>
                                  {w9RequiredError && <span className={classnames(styles.small, styles.below_input, styles.error)} style={{ transform: 'translateY(-44px)' }}>
                                    {errors.w9_form}
                                  </span>}
                                  <input {...getInputProps()} />
                                  <div style={{ display: 'flex', alignItems: "center", letterSpacing: 0.6, paddingBottom: 10, flexDirection: 'column', maxWidth: 68 }}>
                                    <SvgPdfIcon className={styles.uploadIcon} />
                                    <span className={styles.uploadName} style={{paddingTop: 8}}><strong>{ !w9SignedId ? "Upload W9": w9Filename }</strong></span>
                                  </div>
                                </div>
                                {uploads.map(upload => {
                                  if(upload.state === 'waiting' || upload.state === 'uploading') {
                                    return (<ProgressBar width={100} height={8} percentage={upload.progress} className={styles.uploadProgressBar} />)
                                  }
                                })}
                              </>
                            )}
                          </Dropzone>
                        )} />
                        <span style={{ color: "#6C757D", fontSize: 12}}>
                          A W-9 form is required to receive payments from your customers through Ideasicle X. Only Ideasicle X will see the W-9 form. Ideasicle X will provide you with a 1099-MISC form after the calendar year ends reporting your income.&nbsp;
                          <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" style={{ color: 'black' }} target="_blank" onClick={(e)=> { window.open('https://www.irs.gov/pub/irs-pdf/fw9.pdf', "_blank"); e.preventDefault();}}>
                            Click here to open blank W-9 form
                          </a>
                        </span>

                        {w9FileUrl && <div style={{marginTop: 20}}><Button type="primary" onClick={(e)=> { window.open(`${w9FileUrl}`, "_blank"); e.preventDefault()}}>
                          <Icon path={mdiDownload} style={{height: 16, top: 3, position: 'relative'}} /> Download W-9 on file
                        </Button></div>}
                      </Col>
                    </div>
                  )}
                </div>
                <div className={styles.box}>
                  <div className={classnames(styles.row, 'row')}>
                    {requireBank && (
                      <Col lg={5} md={7} xs={12}>
                        <h2 className={styles.section_heading}>
                          Bank Information
                        </h2>
                        <h3 className={styles.subsection_header}>
                          Connect bank information to receive payments
                        </h3>
                        <hr style={{border: 'none', borderTop: '1px solid black'}} />
                        <div className={styles.bankButton}>
                          { stripeBank && <div style={{marginBottom: 22}}>
                            <span style={{fontStyle: 'italic'}}>
                              {bankDescriptor && bankDescriptor.length && <>
                                {bankDescriptor}
                              </>}
                              <div style={{display: 'inline-block', backgroundColor: '#009245', width: 10, height: 10, marginLeft: 12, marginRight: 5, borderRadius: 5}} />
                              <span style={{color: '#009245'}}>Connected</span>
                            </span>
                          </div>}
                          <a className="btn btn-primary btn-slim" style={{display: 'inline-block', textDecoration: 'none', width: 221, height: 36}} href={stripeConnectOauthUrl(values)} target="_blank">
                            <SvgBankIcon style={{position: 'relative', display: 'inline-block', lineHeight: '36px', top: 2, marginRight: 5}}/> {stripeBank ? 'Replace' : 'Connect'} Bank Info
                          </a>
                        </div>
                      </Col>
                    )}
                    <Col xs={12} style={{textAlign: 'center'}}>
                      { !editMode && <div style={{marginBottom: 30}}>
                        <Field type="checkbox" name="tos" id="tos" style={{position: 'relative', top: -2}} />
                        <label htmlFor="tos" style={{ color: touched.tos && errors.tos ? '#E95656' : 'black' }}>
                          I have read and accept the <a href={TOU} target="_blank">Terms of Use</a>.
                        </label>
                        <br />
                        <Field type="checkbox" name="agreement" id="agreement" style={{position: 'relative', top: -2}} />
                        <label htmlFor="agreement" style={{ color: touched.agreement && errors.agreement ? '#E95656' : 'black' }}>
                          I have read and agree to the <a href={EXPERT_AGREEMENT} target="_blank">Ideasicle X Freelancer Agreement</a>.
                        </label>
                      </div>}
                      { editMode && <button type="button" className="btn btn-none" disabled={isSubmitting} onClick={() => HistoryProvider.history.replace('/')}>
                        Cancel
                      </button>}
                      <button type="submit" className="btn btn-primary" disabled={!isValid || !dirty || isSubmitting}>
                        {isSubmitting && <div className="spin-grow light"></div>}
                        Save
                      </button>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )}}
    />
  )
}

export default ExpertProfileEditor
