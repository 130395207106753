import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const { index } = defaultResourceSchema

const notificationSettingsResource = resourceBuilder.build('/notification_settings', {
  index
})

export default notificationSettingsResource
