import React, { useMemo } from 'react'
import styles from './styles.module.css'
import classnames from 'classnames'
import * as routes from 'constants/routes'
import moment from 'moment'
import Dropzone from 'react-dropzone';
import { DirectUploadProvider } from 'react-activestorage-provider'
import ProgressBar from 'components/general/progress_bar'
import jobsResource from 'resources/jobs'


export default function Uploader ({accept, setJob, job, attribute, multi = false, ...props}) {
  const onUploadComplete = async (signedIds) => {
    try {
      const response = await jobsResource.update({ data: {
        job: { [attribute]: signedIds[0] }
      }, params: { id: job.id }})

      setJob(response.data)
    } catch(error) {
      //todo
    }
  }

  const fileUrl = job[`${attribute}_url`]
  const previewUrl = job[`${attribute}_preview_url`]

  const label = fileUrl && !multi ? 'Re-upload' : 'Upload'

  return (
    <>
      {<DirectUploadProvider directUploadsPath="/api/v1/direct_uploads" onSuccess={onUploadComplete} render={({ handleUpload, uploads, ready }) => (
        <Dropzone onDrop={acceptedFiles => ready && handleUpload([acceptedFiles[0]])} multiple={false} accept={accept}>
          {({getRootProps, getInputProps, isDragAccept, isDragReject}) => (
            <>
              <div className={classnames(styles.uploaderWrapper, styles.uploader, {[styles.uploaderDisabled]: !ready})} {...getRootProps()}>
                <input {...getInputProps()} />
                {/* pointerEvents none prevents the SVG element from ignoring the first click event */}
                <props.icon className={styles.uploadIcon} style={{ paddingTop: 11 }} />
                <span className={styles.uploadName}>{label} {props.name}</span>
                <div style={{display: "inline-block"}}>
                  {uploads.map(upload => {
                    if(upload.state === 'waiting' || upload.state === 'uploading') {
                      return (<ProgressBar width={100} height={8} percentage={upload.progress} className={styles.uploadProgressBar} />)
                    }
                  })}
                </div>
              </div>

            </>
          )}
        </Dropzone>
      )} />}
    </>
  )
}
