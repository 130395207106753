import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const jobsResource = resourceBuilder.build('/jobs', {
  ...defaultResourceSchema,
  finalizeTeam: {
    url: '/{id}/lock',
    method: 'post',
  },
  start: {
    url: '/{id}/activate',
    method: 'post',
  },
  close: {
    url: '/{id}/close',
    method: 'post'
  },
  inviteMore: {
    url: '/{id}/invite_more',
    method: 'post'
  },
  pdf: {
    url: '/{id}/pdf'
  },
})

export default jobsResource
