import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify';
import classnames from 'classnames'
import moment from 'moment'

import Avatar from 'components/general/avatar'
import expertsResource from 'resources/experts';
import adminExpertsResource from 'resources/admin_experts'
import ideaStyles from '../../jobs/idea/styles.module.css'
import { ExistingExpertShortProfile } from '../../jobs/create/expert_invite_step'
import Loading from '../loading'
import { SvgBackArrow } from '../icons'
import { onlyValidEmails } from '../master_command_center/add_command_centers_tile'
import styles from './styles.module.css'
import ArchiveModal from './ArchiveModal';

const DEFAULT_FORM_VALUES = () => [{email : ""}]

const ManageOriginalExperts = ({ history }) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [originalExperts, setOriginalExperts] = useState([])
  const [selectedExpert, setSelectedExpert] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES())
  const expertProfileWrapperRef = useRef()
  const isFormValid = onlyValidEmails(formValues.map(v => v.email)).length > 0

  const fetchExperts = useCallback(async () => {
    try {
      const response = await adminExpertsResource.index()
      setOriginalExperts(response.data.experts)
     } catch(error) {
      console.error(error)
      toast.warn("Uh oh! Something went wrong. Please try again shortly.")
     } finally {
      setLoading(false)
     }
  }, [])

  useEffect(() => {
   fetchExperts()
  },[fetchExperts])


  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSaving(true)
      const experts = onlyValidEmails(formValues.map(v => v.email)).map(email => ({ email }))
      const response = await expertsResource.create({ data: { experts } })
      setFormValues(DEFAULT_FORM_VALUES())
      toast.success("Your invitation was sent.")
      // Unnecessary: list does not update since non-onboarded experts are not included
      //setTimeout(() => setOriginalExperts(response.data.experts))
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! Something went wrong. Please try again.")
    } finally {
      setSaving(false)
    }
  }

  const navigateToDashboard = () => history.push('/')

  const handleOpenArchiveModal = (expert) => {
    setSelectedExpert(expert);
    setOpenArchiveModal(true);
  }

  const handleDeactivate = async (event) => {
    event.preventDefault();
    try {
      await adminExpertsResource.deactivate({ params: { id: selectedExpert.id } });
      await fetchExperts();
      setSelectedExpert(null);
      setOpenArchiveModal(false);
    } catch (err) {
      console.error(err)
      toast.warn("Uh oh! Something went wrong. Please try again shortly.")
    }
  }

  return (
    <>
      <Container>
        {loading && <Loading /> }
        {!loading && (
          <>
            <div className={ideaStyles.back} onClick={navigateToDashboard}>
            <SvgBackArrow /> <span>Back to Dashboard</span>
            </div>
            <Row className={styles.hero}>
            <Col md={12} xs={12}>
              <div>
              <h1>Manage Ideasicle Experts</h1>
              </div>
            </Col>
            </Row>
            <Row style={{ paddingTop: 25 }} ref={expertProfileWrapperRef}>
              <Col sm={12} md={6} lg={6}>
                <div>
                  {selectedExpert ? (
                    <ExistingExpertShortProfile expert={selectedExpert} layout="wide">
                    </ExistingExpertShortProfile>
                  ):(
                    <div style={{ height: 356, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16, border: '1px solid' }}>
                      <span>{'Select an Ideasicle Expert to view their profile information.'}</span>
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div style={{ border: '1px solid black', padding: '12px 10px' }}>
                  <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h1 className={styles.header} style={{ paddingBottom: 16 }}>Invite Ideasicle Experts</h1>
                    <div style={{ backgroundColor: '#f5f5f5', width: '100%', paddingTop: 8, paddingBottom: 24 }}>
                      <div className={styles.inputFields}>
                        {formValues.map((element, index) => (
                          <InviteMemberInput
                            key={index}
                            index={index}
                            value={element.email || ""}
                            handleChange={(e)=>handleChange(index, e)}
                          />
                        ))}
                      </div>
                      <div className={styles.buttonSection}>
                        <button className="btn btn-primary btn-slim" style={{ margin: '20px 0px' }} disabled={saving || !isFormValid} type="submit">Invite</button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              <Col>
                <table className={styles.expertsTable}>
                  <thead>
                    <tr>
                      <th>IDEASICLE EXPERT</th>
                      <th>EMAIL</th>
                      <th>INVITED DATE</th>
                      <th>JOINED DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {originalExperts.map((expert, i) => (
                      <tr key={expert.id} onClick={() => { setSelectedExpert(expert); expertProfileWrapperRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                        <td><Avatar record={expert} className={styles.avatar} /> {expert.fullName}</td>
                        <td>{expert.email}</td>
                        <td>{moment(expert.created_at).format("MM.DD.YY")}</td>
                        <td>{moment(expert.tos_acception_date).format("MM.DD.YY")}</td>
                        <td><button className={classnames('btn btn-danger', styles.removeBtn)} onClick={() => handleOpenArchiveModal(expert)}>Remove From Talent Pool</button></td>
                      </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </>
        )}
      </Container>
      {openArchiveModal && (
        <ArchiveModal
          onClose={() => setOpenArchiveModal(false)}
          onConfirm={handleDeactivate}
        />
      )}
    </>
  );

};

const InviteMemberInput = ({ index, value, handleChange }) =>{
  return(
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: !index ? 0 : 34 }}>
    <input value={value} type="text" placeholder={'john.doe@example.com'} name="email" onChange={handleChange} className={styles.inviteInput}></input>
   </div>
  )
}
export default ManageOriginalExperts;
