import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const { show, create, destroy } = defaultResourceSchema;

const jobAccessLinksResource = resourceBuilder.build('/job_access_links', {
  show,
  create,
  destroy
})

export default jobAccessLinksResource
