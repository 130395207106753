import React, { useContext } from 'react';
import Store from 'store';
import * as routes from 'constants/routes'
import { pathAfterSignIn } from './local_storage'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

// closely related: auth code in axios.js
const useAuthenticationRequired = () => {
  const location = useLocation()
  const history = useHistory()
  const requiresAuth = routes.pathRequiresAuth(location.pathname)
  const { state, dispatch } = useContext(Store)
  const { currentUser } = state

  const userExists = currentUser && !currentUser.empty()

  if(requiresAuth && !userExists) {
    console.error("Authenticated path requires user")
    toast.warn("You need to login to see that")
    pathAfterSignIn.set()
    history.push({
      pathname: routes.SIGN_IN,
    })

    return false
  }

  return true
}

export default useAuthenticationRequired
