/*
Buttons:
* Nested lists https://stackoverflow.com/questions/37604015/handling-tab-for-lists-in-draft-js
               https://draftjs.org/docs/advanced-topics-nested-lists

* Paste doesn't preserve colors, alignments etc.
*/
import React, { Component } from 'react'
import DraftPluginsEditor, { createEditorStateWithText } from 'draft-js-plugins-editor'
import { EditorState, convertFromRaw, convertToRaw, createWithContent } from 'draft-js'
import RichUtils, { ALIGNMENT_DATA_KEY } from './extended-rich-utils'
// https://www.npmjs.com/package/draft-js-export-html
import stateToHTML from './stateToHTML'
import { customStyleFn } from './customStyles'
// NOTE: Draft.css is overriding our alignment styles AND I don't think we really need it.
// Let's leave it commented out for now.
// import 'draft-js/dist/Draft.css'
import styles from './styles.module.css'

import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin'
import createLinkPlugin from 'draft-js-anchor-plugin'
import createVideoPlugin from 'draft-js-video-plugin'
import createImagePlugin from 'draft-js-image-plugin';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
  AlignBlockLeftButton,
  AlignBlockCenterButton,
  AlignBlockRightButton,
} from 'draft-js-buttons'
import { SvgFontBackgrounColorPicker, SvgColorGrabberIcon } from '../general/icons'
import VideoButton from './video'
import ImageButton from './image'
import ThesaurusButton from './thesaurus'
import LinkButton from './link'
import { videoTheme } from '../jobs/idea/rich_content.jsx'

import ColorPicker, { colorPickerPlugin } from './draft-js-color-picker'
import { styles as customStyles } from './customStyles.js'
import toolbarButtonStyles from './styles.module.css'
import FontSizeSelect from './FontSizeSelect'

import 'draft-js-static-toolbar-plugin/lib/plugin.css'
import classnames from 'classnames';

const staticToolbarPlugin = createToolbarPlugin()
const linkPlugin = createLinkPlugin()
const videoPlugin = createVideoPlugin({ theme: videoTheme })
const imagePlugin = createImagePlugin();

const plugins = [
  staticToolbarPlugin,
  linkPlugin,
  videoPlugin,
  imagePlugin,
]

const { Toolbar } = staticToolbarPlugin

// NOTE: Albeit Draft.js can be declared using hooks,
// it doesn't seem to be working with Draft.js plugins editor.
export default class Editor extends Component {
  constructor(props) {
    super(props)

    const setEditorState = (dbgLabel) => (editorState) => this.onChange(editorState, null, dbgLabel)
    const generatePicker = (style, label) => colorPickerPlugin(style, setEditorState(label), () => this.state.editorState)

    this.fgColorPicker = generatePicker(customStyles.color, 'fgColorPicker')
    this.bgColorPicker = generatePicker(customStyles.backgroundColor, 'bgColorPicker')

    this.imageUploaderRef = React.createRef();

    if(this.props.initialState) {
      this.state = {
        editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.initialState)))
      }
    } else {
      this.state = {
       editorState: createEditorStateWithText('')
      }
    }

  }

  getHtml = () => {
    return stateToHTML(this.state.editorState)
  }

  getSerializedEditorState = () => {
    return JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    )
  }

  onChange = (editorState, _, dbgLabel) => {
    if (!editorState) throw new Error('editorState must not be empty')
    this.setState({editorState})
  }

  focus = () => {
    this.editor.focus()
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState, null, 'handleKeyCommand')
      return 'handled'
    }
    return 'not-handled'
  }

  // It seems that we can only return 1 CSS class.
  // Returning an array or space-separated string doesn't work.
  //
  // Text alignment needs align-(left|center|right) that are defined in styles.module.css.
  blockStyleFn = (contentBlock) => {
    const type = contentBlock.getType()
    const textAlignStyle = contentBlock.getData().get(ALIGNMENT_DATA_KEY)
    if (textAlignStyle) {
      return styles[`align-${textAlignStyle.toLowerCase()}`]
    } else if (type === 'unstyled') {
      return styles.unstyledBlock
    }
  }

  handlePastedFiles = (files) => {
    this.imageUploaderRef.current.handlePaste(files)
  }

  render() {

    return (
      <div>
        <div id="editor" className={styles.editor} onClick={this.focus}>
          <div className={styles.draftsWrapper}>
            <DraftPluginsEditor
              editorState={this.state.editorState}
              onChange={(editorState, thing) => this.onChange(editorState, thing, 'DraftPluginsEditor')}
              handleKeyCommand={this.handleKeyCommand}
              handlePastedFiles={this.handlePastedFiles}
              blockStyleFn={this.blockStyleFn}
              plugins={plugins}
              customStyleFn={customStyleFn}
              ref={(element) => { this.editor = element }}
            />
          </div>
          <div className={styles.toolbar}>
            <Toolbar>
              {
                (externalProps) => {
                  const setAlignment = ({ alignment }) => {
                    const editorState = externalProps.getEditorState()
                    const newEditorState = RichUtils.toggleAlignment(editorState, alignment)
                    externalProps.setEditorState(newEditorState, null, 'setAlignment')
                  }
                  externalProps.setAlignment = setAlignment
                  return (

                  <div onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>

                    <abbr title="Select font size">
                      <FontSizeSelect onSetShowingMenu={this.props.onInteracting} editorState={this.state.editorState} setEditorState={(editorState) => this.onChange(editorState, null, 'FontSizeSelect')} {...externalProps} />
                    </abbr>

                    <abbr title="Bold text" className={styles.draftJsButtonWrapper}>
                      <BoldButton {...externalProps} />
                    </abbr>

                    <abbr title="Italic text" className={styles.draftJsButtonWrapper}>
                      <ItalicButton {...externalProps} />
                    </abbr>

                    <abbr title="Underlined text" className={styles.draftJsButtonWrapper}>
                      <UnderlineButton {...externalProps} />
                    </abbr>

                    {/* <abbr title="Select background color">
                      <ColorPicker
                        toggleColor={color => this.bgColorPicker.addColor(color)}
                        defaultColor={{r: '255', g: '255', b: '255', a: '255'}}
                        color={this.bgColorPicker.currentColor(customStyles.backgroundColor, this.state.editorState)}>
                          {currentColor => (
                            <span style={{fontWeight: 'bold', backgroundColor: currentColor}}>
                              <SvgFontBackgrounColorPicker />
                            </span>
                          )}
                      </ColorPicker>
                    </abbr> */}

                    {/* <abbr title="Select font color">
                      <ColorPicker
                        toggleColor={color => this.fgColorPicker.addColor(color)}
                        defaultColor={{r: '0', g: '0', b: '0', a: '255'}}
                        color={this.fgColorPicker.currentColor(customStyles.color, this.state.editorState)}>
                          {currentColor => (
                            <span style={{fontWeight: 'bold', color: currentColor}}>
                              <SvgColorGrabberIcon />
                            </span>
                          )}
                      </ColorPicker>
                    </abbr> */}

                    <abbr title="Align text left" className={classnames(styles.draftJsButtonWrapper, styles.alignmentWrapper)}>
                      <AlignBlockLeftButton {...externalProps} />
                    </abbr>

                    <abbr title="Align text center" className={classnames(styles.draftJsButtonWrapper, styles.alignmentWrapper)}>
                      <AlignBlockCenterButton {...externalProps} />
                    </abbr>


                    <abbr title="Align text right" className={classnames(styles.draftJsButtonWrapper, styles.alignmentWrapper)}>
                      <AlignBlockRightButton {...externalProps} />
                    </abbr>

                    <abbr title="Bulleted list" className={styles.draftJsButtonWrapper}>
                      <UnorderedListButton {...externalProps} />
                    </abbr>

                    <abbr title="Numbered list" className={styles.draftJsButtonWrapper}>
                      <OrderedListButton {...externalProps} />
                    </abbr>

                    <abbr title="Insert link" className={styles.draftJsButtonWrapper}>
                      <LinkButton {...externalProps} linkPlugin={linkPlugin} />
                    </abbr>

                    <abbr title="Insert image">
                      <ImageButton ref={this.imageUploaderRef} editorState={this.state.editorState} modifier={imagePlugin.addImage} onChange={(editorState) => this.onChange(editorState, null, 'ImageButton')} {...externalProps} />
                    </abbr>

                    <abbr title="Insert video">
                      <VideoButton editorState={this.state.editorState} modifier={videoPlugin.addVideo} onChange={(editorState) => this.onChange(editorState, null, 'VideoButton')} {...externalProps} />
                    </abbr>

                    <abbr title="Thesaurus">
                      <ThesaurusButton editorState={this.state.editorState}  {...externalProps} />
                    </abbr>
                  </div>
                )}
              }
            </Toolbar>
          </div>

        </div>

        {this.props.debug && <>
          <h2>Editor state</h2>
          <pre style={{backgroundColor: 'AliceBlue', padding: '10px'}}>{JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()), null, 2)}</pre>

          <button onClick={() => {
            this.setState({ memoStateForDebug: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()), null, 2) })
          }}>
            Memorize state
          </button>
          <button onClick={() => { this.setState({editorState: createEditorStateWithText('')}) }}>
            Clear state
          </button>

          {this.state.memoStateForDebug && <>
            <h2>Memorized state</h2>
            <pre style={{backgroundColor: 'AliceBlue', padding: '10px'}}>{this.state.memoStateForDebug}</pre>
            <button onClick={() => {
                this.setState({editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(this.state.memoStateForDebug))) })
            }}>
              Restore memorized state
            </button>
          </>}

          <h2>Output HTML</h2>
          <pre style={{backgroundColor: 'AliceBlue', padding: '10px'}}>{stateToHTML(this.state.editorState)}</pre>

          <h2>Exported HTML</h2>
          <div style={{backgroundColor: 'AliceBlue', padding: '10px'}} dangerouslySetInnerHTML={{__html: stateToHTML(this.state.editorState)}} />
        </>}
      </div>
    )
  }
}
