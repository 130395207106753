import { setAuthTokenFromHeader, requestHeaders, clearToken } from './auth'
import autoTypeCast from 'auto-type-cast'
import { HistoryProvider } from '../components/app/history'
import * as routes from '../constants/routes'
import { pathAfterSignIn } from './local_storage'
import axios from 'axios'

const JWT_EXPIRED = 'Signature has expired'
const JWT_FAILS_VERIFICATION = 'Signature verification raised'
const REQUIRE_CONFIRMATION_ERROR = "You have to confirm your email address before continuing."
const NO_USER = 'nil user'
const NOT_SIGNED_IN = "You need to sign in or sign up before continuing."

const configureAxios = (axiosInstance) => {

  // Use authorization header from JWT on all requests.
  axiosInstance.interceptors.request.use((request) => {
    const authHeaders = requestHeaders()
    request.headers = {
      ...authHeaders,
      ...request.headers,
    }
    return request
  })

  // If ever given a psuedo-redirect, follow it
  axiosInstance.interceptors.response.use((response) => {
    if(response.headers['x-redirect-location']) {
      window.location = response.headers['x-redirect-location']
    }
    return response
  })

  // If ever given an authorization header, set the JWT in localStorage.
  axiosInstance.interceptors.response.use((response) => {
    setAuthTokenFromHeader(response.headers.authorization)
    return response
  })

  // If JWT expired, redirect to sign in. Keep track of the path so
  // we can redirect to the current location after sign in.
  axiosInstance.interceptors.response.use(null, (intercepted) => {
    // "optional" request that should not cause a redirect
    if(intercepted?.config?.skipAuthRedirect) {
      return Promise.reject(intercepted)
    }
    if(axios.isCancel(intercepted)) {
      intercepted.isCancel = true
      return Promise.reject(intercepted)
    }

    if (intercepted.message === 'Network Error') {
      return Promise.reject(intercepted)
    }

    const { response } = intercepted
    const expiredToken = response.data && (response.data.error === JWT_EXPIRED)
    const jwtSignatureFailed = response.data && (response.data.error === JWT_FAILS_VERIFICATION)
    const invalidConfirmationPeriod = response.data && (response.data.error === REQUIRE_CONFIRMATION_ERROR)
    const noSuchUser = response.data && (response.data.error === NO_USER)
    const notSignedIn = response.data && (response.data.error === NOT_SIGNED_IN)
    const invalidAuth = expiredToken || invalidConfirmationPeriod || jwtSignatureFailed || noSuchUser || notSignedIn
    const requiresAuth = routes.pathRequiresAuth(HistoryProvider.history.location.pathname)

    if (response.status === 401 && requiresAuth && invalidAuth) {
      if (expiredToken || jwtSignatureFailed) { clearToken() }

      pathAfterSignIn.set()
      HistoryProvider.history.push({
        pathname: routes.SIGN_IN,
        state: {
          requiresConfirmation: invalidConfirmationPeriod,
          forcedSignOut: true
        }
      })
    }
    return Promise.reject(intercepted)
  })

  // Cast all response data to model classes.
  axiosInstance.interceptors.response.use((response) => {
    if (response && response.data) {
      autoTypeCast(response.data)
    }
    return response
  })
}

export default configureAxios
