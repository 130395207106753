import React, { useCallback, useMemo, useContext } from 'react'
import Config from 'utils/config'
import { MASTER_COMMAND_CENTER } from 'constants/routes'
import { Link } from 'react-router-dom'
import Store from 'store'


const useMasterCommandCenterLink = () => {
  const { state } = useContext(Store)
  const { currentUser } = state
  const isMasterCommandCenter = currentUser && currentUser['is_master_command_center?']

  // If you already have MCC, go right to MCC page
  // If you are in a trial, you are not ready to upgrade to MCC. Go right to MCC page to
  //   so that we can explain that with appropriate copy
  // If you are neither, go you are ready to upgrade. Go to marketing site to
  //   explain benefits of MCC
  const linkDirectToMcc = isMasterCommandCenter || currentUser?.subscription_metadata?.isTrial()
  const link = linkDirectToMcc ? MASTER_COMMAND_CENTER : Config.masterCommandCenter.offerUrl

    // when master command center, we are linking internally with react router
  const reactRouterLinkProps = useMemo(() => linkDirectToMcc ?
    { to: link } : null
  , [linkDirectToMcc])

  // when not a master command center, we are linking externally
  const anchorTagProps = useMemo(() => !linkDirectToMcc ?
    { href: link } : null
  , [linkDirectToMcc])

  const MccPageLinkComponent = useCallback(({ children, props }) =>
    (<Link {...reactRouterLinkProps} {...props}>{children}</Link>)
  , [reactRouterLinkProps])

  const ExternalLinkComponent = useCallback(({ children, props }) =>
    (<a {...anchorTagProps} {...props}>{children}</a>)
  , [anchorTagProps])

  const LinkComponent = linkDirectToMcc ? MccPageLinkComponent : ExternalLinkComponent

  return {
    link,
    // a bunch of silliness that stems from <Link> component not being able to
    // handle external links. Instead, we return props for a <Link /> and for <a />
    // these are used by the nav bar
    reactRouterLinkProps,
    anchorTagProps,
    // and also just return an appropriate link component
    LinkComponent,
    // finally, if you somehow reach the MCC page without hitting the marketing site
    // we need to override the default and send you to the marketing site
    ExternalLinkComponent,
  }
}
export default useMasterCommandCenterLink
