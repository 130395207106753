/* Starting point was https://github.com/kevinnguyen125/draft-js-color-picker */
import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import presetColors from '../presetColors'
import styles from '../styles.module.css'

export default class PickerComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayColorPicker: false,
      color: this.props.defaultColor
    }
  }

  handleButtonMouseDown = (e) => {
    e.preventDefault()
    this.setState({displayColorPicker: !this.state.displayColorPicker})
  }

  handleClose = () => {
    this.setState({displayColorPicker: false})
  }

  handleChange = (color) => {
    const { r, g, b, a } = color.rgb
    this.props.toggleColor(`rgba(${r},${g},${b},${a})`)
  }

  renderColor () {
    if (this.props.color) return this.props.color

    const { r, g, b, a } = this.state.color
    return `rgba(${r}, ${g}, ${b}, ${a})`
  }

  render() {
    const pickerStyles = {
      popover: {
        position: 'absolute',
        zIndex: '2',
        marginTop: 4,
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      formatButton: {
        minWidth: 0,
        minHeight: 0,
        width: '2.5em',
        // height: '3em',
        padding: 0
      },
    }

    const picker = (
      <div style={pickerStyles.popover} onMouseDown={(e) => { e.preventDefault() }}>
        <div
          style={pickerStyles.cover}
          onClick={this.handleClose}
        />
        <SketchPicker
          color={this.props.color || this.state.color}
          // colors={presetColors}
          triangle="hide"
          onChange={this.handleChange}
        />
      </div>
    )

    return (
      <div className={styles.buttonWrapper}>
        <button
          className={styles.menuButton}
          style={pickerStyles.formatButton}
          onMouseDown={this.handleButtonMouseDown}
        >
          {this.props.children(this.renderColor())}
        </button>
        { this.state.displayColorPicker ? picker : null }
      </div>
    )
  }
}
