import * as routes from '../constants/routes'
import { setUserContext as setUserInErrorContext } from 'utils/error_tracking'
import { registerModels } from '../models'
import autoTypeCast from 'auto-type-cast'

const PATH_AFTER_SIGN_IN_KEY = 'pathAfterSignIn'

class LocalStorage {
  get() {
    return JSON.parse(localStorage.getItem(this.key))
  }

  set(data) {
    localStorage.setItem(this.key, JSON.stringify(data))
  }

  clear() {
    localStorage.removeItem(this.key)
  }
}

class PathAfterSignIn extends LocalStorage {
  get key() {
    return PATH_AFTER_SIGN_IN_KEY
  }

  get(defaultPath = '/') {
    return super.get() || defaultPath
  }

  set(path = null) {
    path = path || window.location.pathname
    if (!path.startsWith(routes.SIGN_IN)) {
      localStorage.setItem(PATH_AFTER_SIGN_IN_KEY, JSON.stringify(path))
    }
  }
}

const setStonlyUserData = (user) => {
  if(!user) return
  const stonlyUserData = {
    'is-a-customer': user.role === 'customer',
    'is-a-freelancer': user.role === 'expert',
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('stonlyUserData', stonlyUserData)
  }
  if(window && window.stonlyTrack) {
    try {
      stonlyTrack('identify', user.id, stonlyUserData);
    } catch(e) {
      console.error("Error in stonlyTrack", e)
    }
  }
}

class CurrentUser extends LocalStorage {
  get key() {
    return 'currentUser'
  }

  get() {
    let user = super.get()
    setUserInErrorContext(user)
    setStonlyUserData(user)
    // autoTypeCast registry is not yet initialized by this time,
    // force it to be so
    registerModels()
    // Fix bad sessions with no __type on this object;
    // TODO: Remove after 2022-09-08
    if(user && user.subscription_metadata) {
      user.subscription_metadata.__type = 'SubscriptionMetadata'
    }
    if(!user) {
      user = { __type: 'User' }
    }
    autoTypeCast(user)

    return user
  }

  set(userData) {
    setUserInErrorContext(userData)
    setStonlyUserData(userData)
    if(process.env.NODE_ENV==='development') {
      window.currentUser = userData
    }
    return super.set(userData)
  }
}


export const pathAfterSignIn = new PathAfterSignIn()
export const currentUser = new CurrentUser()
