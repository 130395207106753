import React from 'react'
import onboardingStyles from './styles.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import {CardExpiryElement, CardCvcElement, CardNumberElement} from 'react-stripe-elements'
import classnames from 'classnames'
import { mergeStyleModules } from 'utils/classNames'

function NewCardInfo ({
  errorMessage,
  clearStripeErrors = () => {},
  styles = {},
}) {

  styles = mergeStyleModules(onboardingStyles, styles)

  return (<>
    {errorMessage && <span className={classnames(styles.small, styles.error)}>
      {errorMessage}
    </span>}
    <Row style={{marginTop: 30}}>
      <Col xs={12}>

        <CardNumberElement className={classnames(styles.input)} onChange={clearStripeErrors} />
        <div style={{width: '100%'}}>
          <Row>
            <Col md={6} xs={12}>
              <CardExpiryElement className={classnames(styles.input, styles.input_half)} onChange={clearStripeErrors} />
            </Col>
            <Col md={6} xs={12}>
              <input
                name="cardZip"
                type="text"
                placeholder="ZIP / Postal code"
                className={classnames(styles.input, styles.input_half)}
                onChange={clearStripeErrors}
              />
            </Col>
          </Row>
        </div>
        <div style={{width: '100%'}}>
          <Row>
            <Col xs={6}>
              <CardCvcElement className={classnames(styles.input, styles.input_half)} onChange={clearStripeErrors} />
            </Col>
            <Col xs={6}>
              <span className={styles.small}>3 or 4 digits usually found on the signature strip</span>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </>)
}

export default NewCardInfo
