import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'
import { clearToken } from '../utils/auth'
import { currentUser } from 'utils/local_storage'

const sessionsResource = resourceBuilder.build('/users', {
  signIn: {
    method: 'post',
    url: '/sign_in',
    call: (email, password, registrationToken) => {
      const data = { api_user: { email: email } }
      if(password) { data.api_user.password = password }
      if(registrationToken) { data.api_user.registration_token = registrationToken }

      return {
        data,
        headers: { authorization: null }
      }
    },
  },
  signOut: {
    method: 'delete',
    url: '/sign_out',
    then: (response) => {
      clearToken()
      currentUser.clear()
      return response
    },
  },
  forgotPassword: {
    method: 'post',
    url: '/password',
    call: (email) => {
      const data = { api_user: { email: email } }
      return { data }
    },
  },
  resetPassword: {
    method: 'put',
    url: '/password',
    call: (password, passwordConfirmation, resetToken) => {
      const data = {
        api_user: {
          password: password,
          password_confirmation: passwordConfirmation,
          reset_password_token: resetToken,
        }
      }
      return { data }
    },
  },
})

export default sessionsResource
