import React, {} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './styles.module.css'
import classnames from 'classnames'

export default function ContentHeader (props) {

  return (
    <div className={classnames(styles.inner_wrapper)} style={props.style}>
      {props.text && <span className={styles.text}>{props.text}</span>}
      {props.text && (props.subtext || props.subtextLabel) && <div className={styles.separator} />}
      {props.subtext && <span className={styles.subtext}>{props.subtext}</span>}
    </div>
  )
}
