import React from 'react'
import styles from './styles.module.css'

export default function ProgressBar ({ percentage, width, height, ...wrapperProps }) {
  const barWidth = (width - 2) * percentage / 100.0 || 0;
  const barHeight = Math.min(height - 2, barWidth)

  return (
    <div {...wrapperProps} style={{ width, height }}>
      <div className={styles.container} style={{borderRadius: height / 2.0}}>
        <div className={styles.bar} style={{width: barWidth, marginLeft: 1, marginRight: 1, height: barHeight, borderRadius: (height - 2) / 2.0}} />
      </div>
      { !percentage &&
        <span style={{marginLeft: 1, fontSize: 10, display: "inline-block"}}>Preparing to upload...</span>
      }
    </div>
  )
}
