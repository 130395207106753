
const Config = {
  messaging: false,
  idea_flags: true,
  // NOTE: if modifying code in this area, be sure to keep this in
  // sync w/ code in SubscriptionsController#update which applies
  // the same discount on the server side via IdeasicleStripeCoupons
  defaultCouponCode: { plan: 'price_annual_v2', code: "omfwas" },
  onboardingPlanMarketingCopy: {
    price_annual_v2: {
      instead: "With the Annual Plan pay for a full year in advance and get one month of Ideasicle X free. That's 12 months of full access to a Command Center for the price of 11 ($1,089 total per year). You can add more Command Centers later. Your account will automatically renew after each year unless you cancel your account."
    },
    price_trial_v3: {
      post: 'Add your credit card below in case you want to invite outside freelancers. Your card will not be billed otherwise during this free trial period.'
    },
    price_monthly_v2: {
      instead: "With the Monthly Plan pay $99 per month for full access to one Ideasicle X Command Center. You can add more Command Centers to your account later. You will be automatically billed each month unless you cancel your account."
    },
    price_annual_v3: {
      instead: ({plan, formattedAmount}) => {
        return [
          `Pay for a full year in advance and get 2 months of Ideasicle X free.`,
          `${formattedAmount} per year for one Command Center. `,
          `Unlimited idea projects.`,
          `Account automatically renews after each year unless you cancel your account. `,
          `Add more Command Centers for colleagues later.`,
        ]
      }
    },
    price_monthly_v3: {
      instead: ({plan, formattedAmount}) => {
        return [
          `Pay ${formattedAmount} per month for one Command Center. Cancel at any time.`,
          `Unlimited idea projects per month.`,
          `Account automatically billed each month unless you cancel your account.`,
          `Add more Command Centers to your account later. `,
        ]
      }
    },
  },
  manageTeamEnabled: false, //off temporarily until we rework it
   // limit the plans in the list if this array has any elements
  defaultPlans: [ ],
  // limit the plans in the list if the coupon code is on the query string
  couponPlans: {
    'PROXY-33690D4B2BAB': [ 'price_annual_v3' ],
  },
  jobBuilderV3: true,
  // keep in sync with expert_serializer.rb
  ratings_enabled: false,
  masterCommandCenter: {
    changeOwner: false,
    viewArchivedProjects: false,
    offerUrl: {
      production: 'https://www.ideasiclex.com/master-command-center',
      staging: 'https://www.ideasiclex.com/master-command-center-staging',
      development: '/master-command-center?upgrade=true' // skip the external webpage in this case
    }[process.env.RAILS_ENV],
  },
  jobDirectAccessUsers: {
    ideaDirectorsMax: 1,
    observersMax: 3,
  }
}

if (process.env.RAILS_ENV === 'development') {
  console.log("Config", Config)
}

export default Config
