import React, {} from 'react'
import styles from './styles.module.css'
import classnames from 'classnames'
import * as routes from 'constants/routes'
import { SvgFlagIcon, SvgFlagActiveIcon } from 'components/general/icons'
import Interweave, { Matcher } from 'interweave'
import { UrlMatcher } from 'interweave-autolink';
import { EmojiMatcher } from 'interweave-emoji';
import { useEmojiData } from 'interweave-emoji';
import DefaultVideoComponent from 'draft-js-video-plugin/lib/video/components/DefaultVideoComponent.js'
import getVideoId from 'get-video-id'
import { stripHexcode } from 'emojibase';

function emojiPath(hexcode) {
  return `https://cdn.jsdelivr.net/emojione/assets/4.5/png/32/${stripHexcode(
    hexcode,
  ).toLowerCase()}.png`;
}

// adapted from draft-js-video-plugin/src/theme.js
const videoTheme = {
  iframeContainer: styles.videoIframeContainer,
  iframe: styles.videoIframe,
  invalidVideoSrc: styles.videoInvalidVideoSrc
}


export default function RichContent (props) {
  const [emojis, source, manager] = useEmojiData({ compact: false });

  const transform = (node, children) => {
    if(node.tagName.toLowerCase() == 'draft-js-video-plugin-video') {
      if(props.videoAsImage) {
        // https://www.youtube.com/watch?v=2XJ1y-TYLVc
        // to
        // https://img.youtube.com/vi/2XJ1y-TYLVc/0.jpg
        const videoId = getVideoId(node.getAttribute('src'))
        const src = `https://img.youtube.com/vi/${videoId.id}/0.jpg`
        return <img src={src} />
      }
      return <DefaultVideoComponent blockProps={{src: node.getAttribute('src')}} theme={videoTheme} />
    }
  }

  return (
    <div className={styles.content}>
      <Interweave
        emojiSource={source}
        matchers={[ new UrlMatcher('url'), new EmojiMatcher('emoji', {
          convertEmoticon: true,
          convertShortcode: true,
        })]}
        emojiPath={emojiPath}
        transform={transform}
        {...props}
      />
    </div>
  )
}
export { videoTheme }
