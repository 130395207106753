import listify from 'listify'

// using rails-like syntax; server-side would call this to_sentence
// https://apidock.com/rails/Array/to_sentence
const toSentence = (array, finalWord) => {
  const options = {}
  if(finalWord) {
    options.finalWord = finalWord
  }

  return listify(array, options)
}

const intervalAdverb = (interval) => {
  if(interval === 'year') {
    return 'annually'
  }

  // e.g. monthly
  return interval + 'ly'
}

export {
  intervalAdverb,
  toSentence
}
