import React, { createContext, useReducer, useEffect } from 'react'
import * as actions from 'constants/actions'
import { currentUser } from 'utils/local_storage'
import usersResource from 'resources/users'

const initialState = {
  notifications: [],
  currentUser: currentUser.get()
}

const Store = createContext(initialState)

function reducer(state, action) {
  if (process.env.RAILS_ENV === 'development') {
    console.log(`Dispatching ${action.type}`, action.payload)
  }

  switch (action.type) {
    case actions.CURRENT_USER_FETCHED:
      return {
        ...state, currentUser: action.payload
      }
    case actions.NOTIFICATIONS_FETCHED:
      return {
        ...state, notifications: action.payload
      }
    default:
      throw new Error(`Type ${action.type} isn't supported yet.`)
  }
}

export function StoreProvider({ children }) {
  const [ state, dispatch ] = useReducer(reducer, initialState)
  const value = { state, dispatch }

  useEffect(() => {
    // "optional" request to refresh a user. should not cause a redirect
    // and does not matter if it fails. if the user is really logged out,
    // they'll be redirected when they request a required peice of data later
    const refreshUser = async () => {
      if(!state.currentUser?.id) {
        return
      }
      try {
        const response = await usersResource.showCurrent({ skipAuthRedirect: true })
        const currentRole = state.currentUser.role
        dispatch({type: actions.CURRENT_USER_FETCHED, payload: response.data})
        currentUser.set(response.data)
        if(response.data.role !== currentRole) {
          // If a user's role changed, it may mean they were kicked out of their organizatoin
          // or some developer intervention took place on the backend. The nav bar, menu options,
          // and dashboard need to refresh to reflect their current state (and some pages are probably throwing errors).
          // Simplest option here is to refresh the page
          window.location.reload()
        }
      } catch(e) { }
    }
    refreshUser()
  }, [])

  return (
    <Store.Provider value={value}>
      <div id={`user-role-${state?.currentUser?.role ? state.currentUser.role : 'none'}`} />
      {children}
    </Store.Provider>
  )
}

export default Store
