import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import sessionsResource from '../../../resources/sessions'
import * as routes from '../../../constants/routes'
import styles from '../shared/styles.module.css'
import {Helmet} from "react-helmet";
import UnauthenticatedLayout from '../shared/unauthenticated_layout.jsx'
import { toast } from 'react-toastify';
import Button from 'components/general/button'

const validationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Email is required'),
})

const ForgotPassword = ({ history, match }) => (<>
  <Helmet>
    <title>Forgot Password | Ideasicle X</title>
  </Helmet>
  <UnauthenticatedLayout>
    <Formik
    initialValues={{
      email: '',
    }}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting, setFieldError }) => {
      try {
        await sessionsResource.forgotPassword(values.email)
        toast.success("If an account with that email exists in our system, we've sent a reset password link")
        history.push(routes.SIGN_IN)
      } catch (error) {
        if(error.response && error.response.data && error.response.data.error) {
          setFieldError('general', error.response.data.error)
        } else {
          toast.warn("Uh oh! Something went wrong. Please try again soon")
          console.error(error)
        }
      } finally {
        setSubmitting(false)
      }
    }}
    render={({
      values,
      errors,
      touched,
      isSubmitting,
      handleBlur,
      handleChange,
      handleSubmit,
      }) => (
      <Form
        onSubmit={handleSubmit}
        className={styles.signInForm}
        data-testid="forgot-password-form"
        >

        <h2 className={styles.signInHeader}>Reset password</h2>

        <div className="help" data-testid="error">
          {errors.general ? errors.general : ''}
        </div>

        <div className="help">
          {false && touched.email && errors.email ? errors.email : ''}
        </div>

        <input
          name="email"
          type="text"
          data-testid="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Email"
          className={styles.input}
        />

        <div className={styles.signInButtonContainer}>
          <p className={styles.signInButton}>
            <Button
              style={{borderColor: 'black', background: 'none'}}
              disabled={isSubmitting}
              type="submit">
              Send password
            </Button>
          </p>
        </div>
      </Form>
    )}
  />
  </UnauthenticatedLayout>
</>)


export default ForgotPassword
