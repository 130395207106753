import { registerClass } from 'auto-type-cast'
import Comment from './comment'
import Idea from './idea'
import Job from './job'
import Organization from './organization'
import SubscriptionMetadata from './subscription_metadata'
import User from './user'

const modelClasses = [
  Comment,
  Idea,
  Job,
  Organization,
  SubscriptionMetadata,
  User,
]

const registerModels = () => {
  modelClasses.forEach(registerClass)
}

export {
  registerModels,
}
