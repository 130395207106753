import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { googleAnalyticsTrackingID } from '../../constants/services'

/* Prevent jest failure. */
try {
  ReactGA.initialize(googleAnalyticsTrackingID)
} catch(error) {
  console.log(error)
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({page, ...options})
    ReactGA.pageview(page)
  }

  const HOC = (props) => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ])

    return <WrappedComponent {...props} />
  }

  return HOC
}

export default withTracker
