import React, {} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './styles.module.css'
import classnames from 'classnames'

export default function ContentHeader (props) {

  return (
    <div className={classnames(props.className, styles.inner_wrapper, {[styles.bottomBorder]: !props.noBorder})} style={props.style}>
      {props.text && <span className="hero-text">{props.text}</span>}
      {props.text && (props.subtext || props.subtextLabel) && <div className={styles.hero_text_separator} />}
      {props.subtext && <span className="hero-text">{props.subtext}</span>}
      {props.subtextLabel && <span className={styles.subtext_label}>{props.subtextLabel}</span>}
      <div className={styles.children}>
        {props.children}
      </div>
    </div>
  )
}
